import { useRecoilCallback, useRecoilTransaction_UNSTABLE, useRecoilValue } from 'recoil'
import { produce } from 'immer'

import { accountResponseState_INTERNAL, customFieldOptionsLookup, customFieldOptionsState } from 'main/recoil/runbook'
import { FieldOptionModelType } from 'main/data-access/models'
import { FieldOption } from 'main/services/queries/types'
import { accountResponseDataHelpers } from './active-account-response-data-helpers'

/* -------------------------------------------------------------------------- */
/*                                     Get                                    */
/* -------------------------------------------------------------------------- */

export const useGetFieldOption: FieldOptionModelType['useGet'] = (id: number) => {
  const lookup = useRecoilValue(customFieldOptionsLookup)
  return lookup?.[id]
}

export const useGetFieldOptionCallback: FieldOptionModelType['useGetCallback'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async (id: number) => {
        const lookup = await snapshot.getPromise(customFieldOptionsLookup)
        return lookup?.[id]
      },
    []
  )
}

/* -------------------------------------------------------------------------- */
/*                                  Get All                                   */
/* -------------------------------------------------------------------------- */

export const useGetFieldOptions: FieldOptionModelType['useGetAll'] = () => {
  return useRecoilValue(customFieldOptionsState)
}

export const useGetFieldOptionsCallback: FieldOptionModelType['useGetAllCallback'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getPromise(customFieldOptionsState),
    []
  )
}

/* -------------------------------------------------------------------------- */
/*                                  Lookup                                    */
/* -------------------------------------------------------------------------- */

export const useGetFieldOptionLookup: FieldOptionModelType['useGetLookup'] = () => {
  return useRecoilValue(customFieldOptionsLookup)
}

export const useGetFieldOptionLookupCallback: FieldOptionModelType['useGetLookupCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getPromise(customFieldOptionsLookup),
    []
  )

/* -------------------------------------------------------------------------- */
/*                                  On Action                                 */
/* -------------------------------------------------------------------------- */

export const useFieldOptionActionHandler: FieldOptionModelType['useOnAction'] = () => {
  return useRecoilTransaction_UNSTABLE(({ set, get }) => (option: FieldOption) => {
    if (!get(accountResponseState_INTERNAL)) return

    set(accountResponseState_INTERNAL, prev =>
      produce(prev, draft => {
        const cfIndex = draft.meta.custom_fields.findIndex(c => c.id === option.custom_field_id)
        if (cfIndex !== -1) {
          draft.meta.custom_fields[cfIndex].field_options.push(option)
          accountResponseDataHelpers.extend(draft)
        }
      })
    )
  })
}
