import { FieldOption } from 'main/services/queries/types'
import {
  useFieldOptionActionHandler,
  useGetFieldOption,
  useGetFieldOptionCallback,
  useGetFieldOptionLookup,
  useGetFieldOptionLookupCallback,
  useGetFieldOptions,
  useGetFieldOptionsCallback
} from '../hooks'
import { CollectionModelInterfaceBase } from './types'

export type FieldOptionModelType = CollectionModelInterfaceBase<FieldOption> & {
  // NOTE: This is a custom implementation of useOnAction because the API response doesn't conform to the standard useOnAction signature.
  // if you are in this file with a view to creating a 'useOnAction' cb in a model, please don't copy this implementation without checking
  // that the standard ActiveModelInterfaceWithAction is more appropriate
  useOnAction: (action: 'create') => (fieldOption: FieldOption) => void
}

export const FieldOptionModel: FieldOptionModelType = {
  useGet: useGetFieldOption,
  useGetCallback: useGetFieldOptionCallback,

  useGetAll: useGetFieldOptions,
  useGetAllCallback: useGetFieldOptionsCallback,

  useGetLookup: useGetFieldOptionLookup,
  useGetLookupCallback: useGetFieldOptionLookupCallback,

  useOnAction: useFieldOptionActionHandler
}
