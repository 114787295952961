import { ReactNode, useCallback, useEffect } from 'react'

import { Box, useNotify } from '@cutover/react-ui'
import { usePostAppEvents } from '../apps-api'
import { useComponentProps } from '../apps-state'
import { AppComponentNodeProps, ContentNode } from '../apps-types'
import { useLanguage } from 'main/services/hooks'
import { useAppsFieldValuesCallback, useAppsFieldValuesWithContextState } from 'main/recoil/data-access'

type TriggerNodeProps = AppComponentNodeProps & {
  children: ReactNode
  type: string
  content: ContentNode[]
  action?: string
  on?: 'click' | 'change' | 'load'
  href?: string
  copyToClipboard?: string
  meta: object
}

export const TriggerNode = ({ appId, resourceId, id, ...props }: TriggerNodeProps) => {
  const {
    action,
    children,
    href,
    on = 'click',
    copyToClipboard,
    type,
    content,
    meta
  } = useComponentProps(appId, resourceId, id, props) as unknown as TriggerNodeProps

  const node = { type, content, id, meta, action }
  const postAppEvents = usePostAppEvents()
  const context = `${resourceId}-${appId}`

  // RunbookViewModel.useAction('notify') cannot be used here for two reasons:
  // 1. We need to maintain compatibility with the Angular runbook page for now.
  // 2. Notifications are disabled at the runbook settings level. This is the only place where we display a toaster for incidents.
  const notify = useNotify()

  const { t } = useLanguage('apps')

  if (on === 'load' && href) {
    window.location.href = href
  }

  const getfieldValues = useAppsFieldValuesCallback()

  const openInNewTab = (href: string) => {
    window.open(href, '_blank', 'noreferrer')
  }

  const copy = (textContent: string) => {
    navigator.clipboard.writeText(textContent)
    notify.success(t('copyToClipboardToaster'))
  }

  const handleTriggerEvent = (e: React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()

    // Skip change events from Select/MultiSelect input value
    // @ts-ignore
    if (e.target.id.includes('downshift')) {
      return
    }

    if (href) {
      openInNewTab(href)
    } else if (copyToClipboard) {
      copy(copyToClipboard)
    } else if (e.type === 'change') {
      const value = (e.target as HTMLInputElement).value
      appEventsRequester([{ ...node, value }])
    } else {
      appEventsRequester([node])
    }
  }

  const appEventsRequester = async (events: ContentNode[]) => {
    const values = await getfieldValues()
    const payload = {
      app_id: appId,
      runbook_id: resourceId,
      events,
      state: values[context]
    }
    postAppEvents(payload)
  }

  const handlerNames = {
    click: 'onClick',
    change: 'onChange'
  }

  const handler = {
    [handlerNames[on as 'click' | 'change']]: (
      e: React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>
    ) => {
      handleTriggerEvent(e)
    }
  }

  const hasMatchingNodeId = (node: ContentNode, eventId: string): boolean => {
    if (node.content) {
      return node.content.some((innerNode: ContentNode) => {
        if (innerNode.id === eventId) {
          return true
        } else {
          return hasMatchingNodeId(innerNode, eventId)
        }
      })
    }
    return false
  }

  const handleSelectedItem = useCallback((e: CustomEvent) => {
    if (e.detail.node.id && hasMatchingNodeId(node, e.detail.node.id)) {
      appEventsRequester([{ ...node, value: e.detail.value }])
    }
  }, [])

  useEffect(() => {
    if (on === 'change') {
      // @ts-ignore
      window.addEventListener('selecteditem', handleSelectedItem)
      return () => {
        // @ts-ignore
        window.removeEventListener('selecteditem', handleSelectedItem)
      }
    }
  }, [])

  return (
    <Box
      width={'100%'}
      tabIndex={0}
      css={`
        display: inline-block;
      `}
      {...handler}
    >
      {children}
    </Box>
  )
}

type UseTriggerNodeProps = {
  appId: string
  resourceId: string
  context: string
}

/**
 * Use for building triggers that are internal to the apps components and not based on the external api.
 */
export const useTriggerNode = ({ appId, resourceId }: UseTriggerNodeProps) => {
  const postAppEvents = usePostAppEvents()
  const context = `${resourceId}-${appId}`
  const appState = useAppsFieldValuesWithContextState(context)

  return async (node?: ContentNode) => {
    try {
      if (!node) {
        throw new Error('No node provided to trigger')
      }

      const payload = {
        app_id: appId,
        runbook_id: resourceId,
        events: [node],
        state: appState
      }

      return await postAppEvents(payload)
    } catch (e) {
      console.error('Error triggering node', e)
      throw e
    }
  }
}
