import styled from 'styled-components/macro'

import { Box, BoxProps } from '../../../../layout'
import { overlayStyles, themeEdgeSize } from '../../../../theme'

type SelectOptionsListProps = BoxProps & {
  isOpen?: boolean
  /** @deprecated Should not be needed if selected with accessibility roles */
  'data-testid'?: string
}

// TODO: what is our standard select drop height?
export const SelectOptionsListBox = styled(Box).attrs((props: SelectOptionsListProps) => ({
  tag: 'li',
  flex: false,
  background: 'bg',
  height: props.height || {
    max: '152px'
  },
  overflow: 'auto',
  'data-testid': props['data-testid']
}))<BoxProps & { isOpen?: boolean }>`
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  border-radius: 0 0 8px 8px;
  // angular css overrides padding with 0
  padding-left: ${themeEdgeSize('xsmall')} !important;
  padding-right: ${themeEdgeSize('xsmall')} !important;
  width: 100%;
  z-index: 10000;

  ${props => props.isOpen && overlayStyles()}
  padding: 0;
`

export const SelectOptionListItem = styled(Box).attrs(() => ({
  tag: 'li',
  flex: false,
  width: '100%'
}))`
  list-style: none;
`

export const SelectOption = styled(Box).attrs(() => ({
  role: 'option',
  flex: false,
  width: '100%'
}))`
  list-style: none;
`
