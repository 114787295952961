import { memo } from 'react'

import { CommentsForm } from './comments-form'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'

export const CommentsPanel = memo(() => {
  const [{ taskId, taskInternalId }, { closeRightPanel }] = useRightPanelTypeState('runbook-comments')

  return <CommentsForm key={taskId} onClose={closeRightPanel} taskId={taskId} taskInternalId={taskInternalId} />
})
