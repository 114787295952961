import * as yup from 'yup'

export type RunbookTypesSchema = yup.InferType<ReturnType<typeof buildRunbookTypeSchema>>
export const buildRunbookTypeSchema = () => {
  return yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    global: yup.string().oneOf(['true', 'false']), // Long standing issue with radioboxes not handling bools
    account_id: yup // again, long standing issue with radioboxes not handling bools
      .string()
      .nullable()
      .when('global', {
        is: (global?: String) => global === 'true',
        then: schema => schema.notRequired(),
        otherwise: schema => schema.required('')
      }),
    approval_flow_id: yup.number().nullable(),
    enable_rto: yup.boolean(),
    incident: yup.boolean(),
    dynamic: yup.boolean(),
    disabled: yup.boolean(),
    restrict_create_to_templates: yup.boolean(),
    ai_create_enabled: yup.boolean(),
    snippet_ai_enabled: yup.boolean(),
    icon_name: yup.string(),
    icon_color: yup.string(),
    key: yup.string(),
    disable_notifications: yup.boolean()
  })
}
