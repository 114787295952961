import { useDefaultLayout } from '@cutover/react-ui'
import { DataSourcesHeader } from './data-sources-header/data-sources-header'
import { DataSourcesEditPanel } from './right-panels/data-sources-edit-panel'
import { Layout } from 'main/components/layout'
import { useHasPermissionsOrNavigateHome } from 'main/services/hooks'

export const DataSourcesLayout = () => {
  const userCanViewPage = useHasPermissionsOrNavigateHome('data_sources')

  useDefaultLayout({
    filterPanel: false,
    subHeader: false,
    rightNav: false
  })

  return userCanViewPage ? (
    <Layout header={<DataSourcesHeader />} filter={null} rightPanels={<DataSourcesEditPanel />} />
  ) : null
}
