import { forwardRef, memo, useCallback } from 'react'
import { format } from 'date-fns'
import anchorMe from 'anchorme'

import { Avatar, Box, Button, FavoriteStar, SanitizedString, Text, themeColor, Tooltip } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { ActiveRunbookModel, ActiveRunbookVersionModel, CommentModel, CurrentUserModel } from 'main/data-access'
import { RunbookComment } from 'main/services/api/data-providers/runbook-types'
import { toggleCommentFeatured } from 'main/services/queries/use-runbook-comments'

const CommentItemComponent = forwardRef<HTMLElement, { comment: RunbookComment; taskId?: number }>(
  ({ comment, taskId }, ref) => {
    const {
      id,
      content,
      created_at: createdAt,
      featured,
      author,
      task,
      runbook_version_id: commentRunbookVersionId
    } = comment
    const { name, first_name, last_name, id: authorId, color } = author
    const runbookId = ActiveRunbookModel.useId()
    const runbookVersionId = ActiveRunbookVersionModel.useId()
    // TODO: If the runbook is current check for permissions should come from the backend
    const { is_current: isCurrent } = ActiveRunbookVersionModel.useGet()
    const canCreateComment = CommentModel.useCan('create') && isCurrent
    const processRunbookCommentFeaturedResponse = CommentModel.useOnAction('toggle_featured')
    const { openRightPanel } = useSetActiveRightPanelState()

    const toggleFavorite = useCallback(async () => {
      if (canCreateComment) {
        const response = await toggleCommentFeatured({ runbookId, runbookVersionId, commentId: id })
        if (response) {
          processRunbookCommentFeaturedResponse(response)
        }
      }
    }, [canCreateComment, runbookId, runbookVersionId, id, processRunbookCommentFeaturedResponse])

    return (
      <CommentItemInner
        ref={ref}
        isFaded={commentRunbookVersionId !== runbookVersionId}
        authorId={authorId}
        firstName={first_name}
        lastName={last_name}
        color={color}
        name={name}
        featured={featured}
        toggleFavorite={toggleFavorite}
        canCreateComment={canCreateComment}
        created={createdAt}
        taskId={taskId}
        taskName={task?.name}
        taskInternalId={task?.internal_id}
        openRightPanel={openRightPanel}
        content={content}
      />
    )
  }
)

export const CommentItem = memo(CommentItemComponent) as typeof CommentItemComponent

/* -------------------- Comment display while submitting -------------------- */

export const CommentItemSubmitting = memo(
  ({
    content,
    taskId,
    taskInternalId,
    taskName
  }: {
    content: string
    taskId?: number
    taskInternalId?: number
    taskName?: string
  }) => {
    const { id: authorId, first_name: firstName, last_name: lastName, color } = CurrentUserModel.useGet()

    return (
      <CommentItemInner
        isFaded
        isSubmitting
        authorId={authorId}
        firstName={firstName}
        lastName={lastName}
        color={color}
        taskId={taskId}
        taskName={taskName}
        taskInternalId={taskInternalId}
        content={content}
      />
    )
  }
)

/* --------------------------- Shared comment code -------------------------- */

const CommentItemInnerComponent = forwardRef<
  HTMLElement,
  {
    authorId: number
    firstName: string
    lastName: string
    color: string
    name?: string
    canCreateComment?: boolean
    content: string
    isFaded: boolean
    isLast?: boolean
    isSubmitting?: boolean
    featured?: boolean
    toggleFavorite?: () => Promise<void>
    created?: number
    taskId?: number | undefined
    taskName?: string
    taskInternalId?: number
    openRightPanel?: any
  }
>(
  (
    {
      isFaded,
      isSubmitting,
      authorId,
      firstName,
      lastName,
      name,
      featured,
      toggleFavorite,
      canCreateComment,
      created,
      taskId,
      taskInternalId,
      taskName,
      color,
      openRightPanel,
      content
    },
    ref
  ) => {
    const { t } = useLanguage('runbook', { keyPrefix: 'commentsPanel' })

    return (
      <Box
        ref={ref}
        data-testid="runbook-comment-item"
        className={isFaded ? 'different-version comment-item' : 'comment-item'}
        direction="row"
        fill="horizontal"
        css={`
          min-height: 58px;
          pointer-events: ${isSubmitting && 'none'};
          opacity: ${isFaded ? 0.5 : 1};
        `}
        gap="6px"
      >
        {/* Avatar column */}
        <Box width={{ width: '24px', min: '24px' }} margin={{ top: '6px' }}>
          <Avatar subject={{ id: authorId, first_name: firstName, last_name: lastName, color }} size="small" />
        </Box>
        {/* rest column */}
        <Box width="100%">
          <Box align="center" direction="row" justify="between">
            <Text truncate={isSubmitting ? undefined : 'tip'}>{name ?? `${firstName} ${lastName}`}</Text>
            <Box direction="row" flex={false} align="center">
              {!isSubmitting && (
                <>
                  <FavoriteStar
                    isFavorite={!!featured}
                    toggleFavorite={toggleFavorite}
                    isDisabled={!canCreateComment}
                  />

                  <Text color="text-light" size="small">
                    {created && format(created * 1000, 'd MMM HH:mm')}
                  </Text>
                </>
              )}
            </Box>
          </Box>
          <Box>
            <Text
              as="pre"
              css={`
                font-family: inherit;
                color: ${themeColor('text-light')};
                margin: 0;
                font-size: 13px;
                position: relative;
                top: -4px;
              `}
            >
              {taskInternalId && (
                <Tooltip content={taskName} placement="top" isDisabled={isSubmitting}>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => openRightPanel({ type: 'task-edit', taskId })}
                    plain
                  >
                    {t('taskLink', { taskInternalId })}
                  </Button>
                </Tooltip>
              )}

              <SanitizedString
                input={anchorMe({
                  input: content,
                  options: {
                    attributes: {
                      target: '_blank',
                      'data-testid': `task-${taskInternalId}-comment-link`
                    }
                  }
                })}
              />
            </Text>
          </Box>
        </Box>
      </Box>
    )
  }
)

const CommentItemInner = memo(CommentItemInnerComponent) as typeof CommentItemInnerComponent
