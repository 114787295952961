import { memo, useEffect } from 'react'
import { useMatch } from 'react-router-dom'
import { useIsCentralTeamsMatch, useIsWorkspaceDatasourcesMatch } from 'single-spa/route-matchers'

import { useDefaultLayout, useFilterPanelLayout } from '@cutover/react-ui'
import {
  DashboardRunbookListPanel,
  FolderEditPanel,
  RunbookCopiesPanel,
  RunbookEditPanel,
  RunbookLinkedPanel,
  RunbooksBulkEditPanel,
  RunbooksDashboardSchedulePanel,
  RunbooksTimelineSummaryPanel
} from './right-panels'
import { CentralTeamEditPanel } from './pages'
import { WorkspaceFilter } from './workspace-filter'
import { WorkspaceHeader } from './workspace-header'
import { WorkspaceSubHeader } from './workspace-sub-header'
import { Layout } from 'main/components/layout'
import { CreateRunbookConnector } from 'main/connectors/create-runbook-connector'
import { useAccount, useAccountDashboards } from 'main/services/api/data-providers/account/account-data'
import { useWorkspaceMeta } from 'main/services/api/data-providers/workspace'
import { LayoutLoading } from '../layout/layout-loading'
import {
  useActiveRightPanelValueType,
  useCloseRightPanelOnUnmount,
  useWorkspaceRightPanelOpenByDefault
} from 'main/components/layout/right-panel'
import { WorkspaceDataSourcesSubHeader } from './pages/workspace-datasources/workspace-datasources-subheader'
import { RunbookTemplatesWithDatasourcesPanel } from './right-panels/runbook-templates-with-datasources-panel'

export const WorkspaceLayout = memo(() => {
  const { account, isLoading } = useAccount()
  const { runbooksMeta, centralTeamsMeta } = useWorkspaceMeta()

  const { dashboardLookup } = useAccountDashboards()
  const dashboardMatch = useMatch({ path: '/app/:accountSlug/runbooks/dashboard/:dashboardId' })
  const dashboardId = dashboardMatch?.params?.dashboardId
  const dashboard = dashboardId ? dashboardLookup?.[parseInt(dashboardId)] : undefined
  const isAnalyticsDashboard = dashboard?.key === 'analytics'
  const isCentralTeams = useIsCentralTeamsMatch()
  const isDataSources = useIsWorkspaceDatasourcesMatch()
  const isRightPanelOpen = useWorkspaceRightPanelOpenByDefault()

  useDefaultLayout({
    filterPanel: true,
    subHeader: true,
    rightNav: false,
    isRightPanelOpen
  })

  useCloseRightPanelOnUnmount()

  const { disableFilterPanel, enableFilterPanel } = useFilterPanelLayout()

  useEffect(() => {
    if (isCentralTeams || isAnalyticsDashboard || isDataSources) {
      disableFilterPanel()
    } else {
      enableFilterPanel()
    }
  }, [isAnalyticsDashboard, isCentralTeams, isDataSources])

  if (!account || isLoading) {
    return <LayoutLoading rightNav={false} filterPanel subHeader />
  } else if (account) {
    return (
      <>
        <Layout
          header={<WorkspaceHeader account={account} />}
          subHeader={
            isDataSources ? (
              <WorkspaceDataSourcesSubHeader />
            ) : (
              <WorkspaceSubHeader
                runbooksMeta={runbooksMeta}
                centralTeamsMeta={centralTeamsMeta}
                isAnalyticsDashboard={isAnalyticsDashboard}
              />
            )
          }
          filter={isCentralTeams || isAnalyticsDashboard ? null : <WorkspaceFilter />}
          rightPanels={<RightPanels />}
        />
        {/* TODO: CreateRunbookConnector will gone once create runbook flow becomes fully   See ticket https://cutover.atlassian.net/browse/CFE-266 */}
        <CreateRunbookConnector />
      </>
    )
  } else {
    return <></>
  }
})

const RightPanels = memo(() => {
  const panel = useActiveRightPanelValueType()

  switch (panel) {
    case 'runbooks-bulk-edit':
      return <RunbooksBulkEditPanel />
    case 'runbook-edit':
      return <RunbookEditPanel />
    case 'runbooks-timeline-summary':
      return <RunbooksTimelineSummaryPanel />
    case 'dashboard-runbook-list':
      return <DashboardRunbookListPanel />
    case 'runbooks-dashboard-schedule':
      return <RunbooksDashboardSchedulePanel />
    case 'runbook-copies':
      return <RunbookCopiesPanel />
    case 'runbook-templates-with-datasources':
      return <RunbookTemplatesWithDatasourcesPanel />
    case 'folder-edit':
      return <FolderEditPanel />
    case 'runbook-linked':
      return <RunbookLinkedPanel />
    case 'central-team-edit':
      return <CentralTeamEditPanel />
    default:
      return null
  }
})
