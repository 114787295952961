import { useMutation } from 'react-query'

import { ActivityVM } from './use-activities'
import { apiClient } from 'main/services/api/api-client'

type UpdateActivityResponse = {
  activity: ActivityVM
}

type UpdateActivityPayload = {
  activity: ActivityVM
}

const getUrlForActivity = (activity: ActivityVM) => `activities/${activity.id}`

export function useUpdateActivityMutation() {
  return useMutation<UpdateActivityResponse, Error, UpdateActivityPayload>(
    'activity-update',
    async (activityPayload: UpdateActivityPayload) => {
      const { data } = await apiClient.put<UpdateActivityPayload, UpdateActivityResponse>({
        url: getUrlForActivity(activityPayload.activity),
        data: activityPayload
      })

      return data as UpdateActivityResponse
    }
  )
}
