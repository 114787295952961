import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useUpdate } from 'react-use'
import { findLast, last, pullAllWith } from 'lodash'
import * as uuid from 'uuid'
import { Virtuoso } from 'react-virtuoso'

import { Box, IconButton, Pill, SpinnerIcon, Text, TextArea, themeColor } from '@cutover/react-ui'
import { FormEditPanel } from 'main/components/shared/form'
import { useRunbookCommentCreate } from 'main/services/queries/use-runbook-comments'
import { useLanguage } from 'main/services/hooks'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import {
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  CommentModel,
  CurrentUserModel,
  RunbookViewModel,
  TaskModel
} from 'main/data-access'
import { CommentItem, CommentItemSubmitting } from './comment-item'
import { TaskListTask } from 'main/services/queries/types'
import { RunbookComment } from 'main/services/api/data-providers/runbook-types'

type CommentsFormProps = {
  onClose?: () => void
  taskId?: number
  taskInternalId?: number
}

type CommentQueueMessage = {
  message: string
  sending: boolean
  ident: string
  taskId?: number
}

export const CommentsForm = ({ onClose, taskId, taskInternalId }: CommentsFormProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'commentsPanel' })
  const notify = RunbookViewModel.useAction('notify')
  const update = useUpdate()
  const { openRightPanel } = useSetActiveRightPanelState()
  const [showSubmitHelp, setShowSubmitHelp] = useState(false)

  const currentUserId = CurrentUserModel.useId()
  const runbookId = ActiveRunbookModel.useId()
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const { is_current: isCurrent } = ActiveRunbookVersionModel.useGet()
  const canCreateComment = CommentModel.useCan('create') && isCurrent

  const { comments, isLoadingInitialComments, task } = usePanelComments({ taskId })
  const [initialTopMostIndex, setInitialTopMostIndex] = useState(() => comments?.length || null)
  const createComment = useRunbookCommentCreate({ runbookId, runbookVersionId, taskId })
  const isSubmittingComment = createComment.isLoading
  const processRunbookCommentCreateResponse = CommentModel.useOnAction('create')

  const messageQueue = useRef<{ message: string; sending: boolean; ident: string; taskId?: number }[]>([])
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const [scrollContentElement, setScrollContentRef] = useState<HTMLElement | null>(null)

  const itemCount = (comments?.length ?? 0) + messageQueue.current.length
  const commentItems: (RunbookComment | CommentQueueMessage)[] = !messageQueue.current.length
    ? comments
    : [...comments, ...messageQueue.current]

  /* -------------------------------- Handlers -------------------------------- */

  const handleSettled = (_data: any, _error: any, args: any) => {
    pullAllWith(messageQueue.current, [{ ident: args.ident }], (a, b) => a.ident === b.ident)
    const unsent = findLast(messageQueue.current, m => !m.sending)
    if (unsent) postComment(unsent)
  }

  const postComment = ({ message, sending, ident, taskId }: CommentQueueMessage) => {
    if (!message.trim() || sending) return
    if (!messageQueue.current) return

    const queued = messageQueue.current && findLast(messageQueue.current, m => m.ident === ident && !m.sending)
    if (queued) queued.sending = true

    // intentionally not mutateAsync
    createComment.mutate(
      { comment: message.trim(), taskId, ident },
      {
        onSuccess: (data, _args) => {
          if (data) processRunbookCommentCreateResponse(data)
        },
        onError: () => notify.error(t('createError')),
        onSettled: handleSettled
      }
    )
  }

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const val = event.currentTarget.value
    if (val.trim().length > 0) setShowSubmitHelp(true)
    else setShowSubmitHelp(false)
  }

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.code === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      const val = event.currentTarget.value.trim()
      if (inputRef.current) inputRef.current.value = ''
      setShowSubmitHelp(false)

      if (!val) return
      const isBusy = createComment.isLoading
      const newMessage = { message: val, taskId, sending: false, ident: uuid.v4() }

      if (isBusy) {
        messageQueue.current.push(newMessage)
        update()
      } else {
        postComment(newMessage)
      }
    }
  }

  /* ------------------------------ Side effects ------------------------------ */

  useEffect(() => {
    if (!isLoadingInitialComments) {
      setInitialTopMostIndex((comments.length && comments.length - 1) ?? 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingInitialComments])

  const renderItem = useCallback(
    (
      index: number,
      _: any,
      context: {
        comments: (RunbookComment | CommentQueueMessage)[]
        taskInternalId?: number
        taskName?: string
      }
    ) => {
      const { comments, taskInternalId, taskName } = context
      const comment = comments[index]

      if (isLoadedComment(comment)) {
        return <CommentItem comment={comment} taskId={comment.task_id ?? undefined} />
      } else if (isQueueComment(comment)) {
        return (
          <CommentItemSubmitting
            content={comment.message}
            taskId={comment.taskId}
            taskInternalId={taskInternalId}
            taskName={taskName}
          />
        )
      }
    },
    []
  )

  return (
    <FormEditPanel
      title={t('title')}
      onClose={onClose}
      scrollContentRef={setScrollContentRef}
      formElementWrapper={false}
      loading={isLoadingInitialComments}
      onBack={taskId ? () => openRightPanel({ type: 'task-edit', taskId }) : undefined}
      headerItems={
        taskId
          ? [
              <Pill
                color="text-light"
                label={t('taskPillLabel', { taskInternalId })}
                suffix={
                  <IconButton
                    label={t('clearTask')}
                    disableTooltip
                    data-testid={'task-comments-pill-clear'}
                    icon="close"
                    size="small"
                    onClick={() => openRightPanel({ type: 'runbook-comments' })}
                  />
                }
              />
            ]
          : undefined
      }
      // NOTE: Needs style followup, or at least some info about why there are a bunch of wacky styles injected in the components below. This is all
      // a bit of a hack to get the textarea input in the footer to mirror the spacing of the footer with the action button by default, due to
      // 1. the textarea needing to expand the footer's height
      // 2. the textarea at time of noting this is non-standard in its inner form component structure
      // 3. there is built in spacing to take care of the 95% of cases where we want to have it automatically standardize the panel layout
      //    but where in this case having spacing on the content comment items, the bottom of the body of the panel, plus the top of the
      //    footer all result in too much space between the last comment and the top of the input.
      footer={
        canCreateComment && (
          <Box
            css={`
              position: relative;
              margin-bottom: -8px;
              > div {
                padding-top: 0;
              }
            `}
          >
            <TextArea
              ref={inputRef}
              plain
              placeholder={taskId ? t('addTaskComment') : t('addRunbookComment')}
              alwaysShowPlaceholder
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              css={`
                background: ${themeColor('bg-1')} !important;
                border-radius: 20px;
                padding: 8px 16px 0 16px !important;
                min-height: 40px !important;
                margin-bottom: 8px !important;
              `}
            />
            {showSubmitHelp && (
              <Text
                size="10px"
                color="text-light"
                css={`
                  text-align: right;
                  position: absolute;
                  bottom: -6px;
                  right: 0;
                `}
              >
                {t('commentInputHint')}
              </Text>
            )}
            {/* TODO: When the textare layout is addressed, Should be using the built in input end component layout here */}
            {isSubmittingComment && (
              <SpinnerIcon color="text-light" css="position: absolute; top: 8px; right: 9px; z-index: 1" />
            )}
          </Box>
        )
      }
    >
      {scrollContentElement && (
        <Virtuoso
          data-testid="virtuoso-item-list"
          followOutput={isAtBottom => {
            if (isAtBottom) return 'smooth'
            return last(comments)?.author.id === currentUserId
          }}
          computeItemKey={index => {
            const item = commentItems[index]
            if (isQueueComment(item)) return item.ident
            if (isLoadedComment(item)) return item.id
            return ''
          }}
          atBottomThreshold={60}
          // Unable to find a solution to use this prop with RTL specs
          // https://github.com/petyosi/react-virtuoso/issues/26#issuecomment-2672061550
          initialTopMostItemIndex={process.env.NODE_ENV === 'test' ? undefined : initialTopMostIndex ?? 0}
          increaseViewportBy={150}
          customScrollParent={scrollContentElement}
          context={{
            comments: commentItems,
            taskInternalId,
            taskName: task?.name
          }}
          itemContent={renderItem}
          totalCount={itemCount}
        />
      )}
    </FormEditPanel>
  )
}

/* --------------------- Comments loaded into the panel --------------------- */

export const usePanelComments = ({ taskId }: { taskId?: number }) => {
  const task: TaskListTask | undefined = TaskModel.useGet(taskId ?? 0)
  const taskInternalId = task?.internal_id
  const { state: allCommentsLoadingState, contents: allCommentContents } = CommentModel.useGetAllLoadable()
  const { state: taskCommentsLoadingState, contents: taskCommentContents } = CommentModel.useGetAllLoadableBy({
    taskInternalId: taskInternalId ?? 0
  })
  const isTaskComments = taskId !== undefined
  const isLoadingInitialComments = isTaskComments
    ? taskCommentsLoadingState === 'loading'
    : allCommentsLoadingState === 'loading'

  const taskComments = isTaskComments && taskCommentsLoadingState === 'hasValue' ? taskCommentContents : []
  const runbookComments = !isTaskComments && allCommentsLoadingState === 'hasValue' ? allCommentContents : []
  const panelComments = isTaskComments ? taskComments : runbookComments

  return {
    comments: panelComments,
    isLoadingInitialComments,
    task
  }
}

function isQueueComment(comment: RunbookComment | CommentQueueMessage | undefined): comment is CommentQueueMessage {
  if (!comment) return false
  return (comment as CommentQueueMessage).ident !== undefined
}

function isLoadedComment(comment: RunbookComment | CommentQueueMessage | undefined): comment is RunbookComment {
  if (!comment) return false
  return (comment as RunbookComment).id !== undefined
}
