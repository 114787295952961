import { Simplify } from 'type-fest'

import {
  useGetAllComments,
  useGetAllCommentsBy,
  useGetAllCommentsByCallback,
  useGetAllCommentsCallback,
  useGetAllCommentsLoadable,
  useGetAllCommentsLoadableBy,
  useGetAllCommentsLoadableByCallback,
  useGetAllCommentsLoadableCallback,
  useGetComment,
  useGetCommentCallback,
  useGetCommentsLookup,
  useGetCommentsLookupCallback,
  useGetCommentsPermission,
  useOnActionComment,
  useReloadComments,
  useReloadCommentsSync
} from '../hooks'
import { RunbookCommentsPermissionsResponse } from 'main/services/api/data-providers/runbook-types'
import { RunbookComment } from 'main/services/api/data-providers/runbook-types/runbook-shared-types'
import {
  CollectionModelInterfaceBase,
  ModelCollectionAccessScope,
  ModelMultiAccessBy,
  CollectionModelInterfaceWithActions as WithActions,
  CollectionModelInterfaceWithCollectionAccessBy as WithCollectionAccessBy,
  CollectionModelInterfaceWithMultiLoadableAccess as WithMultiLoadableAccess,
  CollectionModelInterfaceWithMultiLoadableAccessBy as WithMultiLoadableAccessBy,
  CollectionModelInterfaceWithPermissions as WithPermissions,
  CollectionModelInterfaceWithReload as WithReload
} from './types'

type CommentCollectionAccessScope = ModelCollectionAccessScope<'featured'>
type CommentCollectionMultiAccessBy = ModelMultiAccessBy<{ taskInternalId: number }>

export type CommentModelType = Simplify<
  CollectionModelInterfaceBase<RunbookComment, CommentCollectionAccessScope> &
    WithReload &
    WithPermissions<RunbookCommentsPermissionsResponse> &
    WithCollectionAccessBy<RunbookComment, CommentCollectionMultiAccessBy, CommentCollectionAccessScope> &
    WithMultiLoadableAccess<RunbookComment, CommentCollectionAccessScope> &
    WithMultiLoadableAccessBy<RunbookComment, CommentCollectionMultiAccessBy, CommentCollectionAccessScope> &
    Omit<WithActions<'Comment'>, 'usePermission' | 'usePermissionCallback'>
>

export const CommentModel: CommentModelType = {
  useCan: useGetCommentsPermission,

  useGet: useGetComment,
  useGetCallback: useGetCommentCallback,

  useGetAll: useGetAllComments,
  useGetAllCallback: useGetAllCommentsCallback,

  useGetAllBy: useGetAllCommentsBy,
  useGetAllByCallback: useGetAllCommentsByCallback,

  useGetAllLoadable: useGetAllCommentsLoadable,
  useGetAllLoadableCallback: useGetAllCommentsLoadableCallback,

  useGetAllLoadableBy: useGetAllCommentsLoadableBy,
  useGetAllLoadableByCallback: useGetAllCommentsLoadableByCallback,

  useGetLookup: useGetCommentsLookup,
  useGetLookupCallback: useGetCommentsLookupCallback,

  useOnAction: useOnActionComment,

  useReload: useReloadComments,
  useReloadSync: useReloadCommentsSync
}
