import { SyntheticEvent, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { isEqual } from 'lodash'
import { useHotkeys } from 'react-hotkeys-hook'

import {
  Box,
  Button,
  CheckboxToggle,
  IconButton,
  Menu,
  MenuListItem,
  MenuListItemProps,
  Pill,
  Text
} from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { ActiveRunbookModel, ActiveRunbookVersionModel, RunbookViewModel, TaskModel } from 'main/data-access'
import {
  useRightPanelTypeValue,
  useSetActiveRightPanelTypeState,
  useToggleRightPanel
} from 'main/components/layout/right-panel'
import { offsetToString } from 'main/services/timezone-util'
import { SortTasks } from 'main/recoil/runbook'

const SORT_OPTIONS: SortTasks[] = [
  { option: 'start_latest_planned', direction: 'asc' },
  { option: 'name', direction: 'asc' },
  { option: 'name', direction: 'desc' },
  { option: 'end_display', direction: 'asc' }
]

export const TaskListHeader = ({
  canCreateRootTask,
  activeTimezone
}: {
  canCreateRootTask: boolean
  activeTimezone?: string | null
}) => {
  const { t } = useLanguage('tasks')
  const notify = RunbookViewModel.useAction('notify')

  const toggleTasksBulkEditPanel = useToggleRightPanel('tasks-bulk-edit')

  const { id: runbookId, template_type: templateType } = ActiveRunbookModel.useGet()
  const { incident: isIncident } = ActiveRunbookModel.useRunbookType()
  const { is_current: isCurrentVersion } = ActiveRunbookVersionModel.useGet()
  const { closeRightPanel: closeTasksBulkEdit } = useSetActiveRightPanelTypeState('tasks-bulk-edit')
  const { taskIds: editingTaskIds } = useRightPanelTypeValue('tasks-bulk-edit')
  const { can: canBulkEdit } = RunbookViewModel.usePermission('initiate:bulkEditActions')
  const getBulkEditPermission = RunbookViewModel.usePermissionCallback('initiate:bulkEditActions')
  const { can: canBulkSkip } = RunbookViewModel.usePermission('initiate:bulkProgressionActions')
  const canCreateSnippet = ActiveRunbookModel.useCan('create_snippet')
  const openModal = RunbookViewModel.useAction('modal:open')
  const selectedIds = RunbookViewModel.useGet('selectedIds')
  const { start_planned: startPlanned } = ActiveRunbookVersionModel.useGet()
  const tasksLookup = TaskModel.useGetLookup()
  const toggleAllSelectedIds = RunbookViewModel.useAction('selectedIds:toggleAll')
  const copyIdsAdd = RunbookViewModel.useAction('copyIds:add')
  const copyIdsRemove = RunbookViewModel.useAction('copyIds:remove')

  const handleSkipTasks = TaskModel.useAction('bulk_skip')

  const filteredTaskIds = TaskModel.useGetIds({ scope: 'filtered' })
  const toggleTaskCreate = RunbookViewModel.useAction('taskCreate:toggle')
  const { predecessor: fromPredecessorId } = RunbookViewModel.useGet('taskCreate')

  const sortValue = RunbookViewModel.useGet('sort')
  const sortButtonLabel = `sortTasksMenu.${sortValue.option}${
    sortValue.option === 'name' ? `_${sortValue.direction}` : ''
  }`
  const setSortValue = RunbookViewModel.useAction('sort:set')

  const disableCreateButton = fromPredecessorId === 0
  const isSelectMode = !!selectedIds.length
  const allTasksSelected = selectedIds.length === filteredTaskIds.length

  // Note: technically this can be wrong since a runbook can overlap a DST boundary.
  // Should it be greater of 'now' and the runbook start time? Angular uses version.start_planned
  const timezoneOffset =
    activeTimezone && startPlanned ? offsetToString(activeTimezone, new Date(startPlanned * 1000)) : ''

  const handleSkip = useCallback(() => {
    handleSkipTasks(selectedIds)
  }, [selectedIds, handleSkipTasks])

  const handleClickBulkEdit = useCallback(() => {
    toggleTasksBulkEditPanel({ taskIds: selectedIds })
  }, [toggleTasksBulkEditPanel, selectedIds])

  const handleClickBulkDelete = useCallback(() => {
    copyIdsRemove()
    openModal({ type: 'tasks-delete', id: selectedIds })
  }, [selectedIds])

  const handleCopyTasks = useCallback(
    async (e: React.MouseEvent | KeyboardEvent) => {
      const selection = window.getSelection()
      if (selection && selection.toString().length > 0) {
        // User has highlighted text, do not handle the hotkey, as they are trying to copy text
        return
      }

      e.preventDefault()

      const bulkEditPermissions = await getBulkEditPermission()

      if (bulkEditPermissions.error === 'NO_SELECTED_IDS')
        notify.warning(t('clipboard.noTasksSelected'), { title: t('clipboard.noTasksSelectedTitle') })

      if (bulkEditPermissions.can) {
        copyIdsAdd(selectedIds)
        notify.success(t('clipboard.copy', { count: selectedIds.length }), {
          title: t('clipboard.copyTitle', { count: selectedIds.length })
        })
      } else {
        notify.warning(t('clipboard.unableToCopy'), { title: t('clipboard.unableToCopyTitle') })
      }
    },
    [selectedIds]
  )

  useHotkeys('meta+c', handleCopyTasks, { preventDefault: false }, [handleCopyTasks, selectedIds])
  useHotkeys('meta+e', handleClickBulkEdit, { enabled: isSelectMode && canBulkEdit, preventDefault: true }, [
    handleClickBulkEdit,
    selectedIds
  ])

  const handleChangeSelectAll = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      toggleAllSelectedIds()
    },
    [toggleAllSelectedIds]
  )

  const handleClickCreateTask = () => {
    toggleTaskCreate({ predecessor: 0 })
  }

  const sortMenuItems: MenuListItemProps[] = SORT_OPTIONS.map(value => {
    const { option, direction } = value
    return {
      label: t(`sortTasksMenu.${option}${option === 'name' ? `_${direction}` : ''}`),
      selected: isEqual(sortValue, value),
      onClick: () => setSortValue({ newSortValue: value })
    }
  })

  useEffect(() => {
    if (editingTaskIds) {
      if (selectedIds.length === 0) {
        closeTasksBulkEdit()
      }
      if (!isEqual(selectedIds, editingTaskIds)) {
        toggleTasksBulkEditPanel({ taskIds: selectedIds })
      }
    }
  }, [selectedIds, editingTaskIds])

  return (
    <Box direction="row" align="center" gap="8px" height={{ min: '64px' }} data-testid="task-list-header">
      <Box
        direction="row"
        css={`
          min-width: ${activeTimezone && !isSelectMode ? '128px' : '128px'};
          align-items: center;
        `}
      >
        <CheckboxToggle
          label={t('bulkActionTooltip.select', { postProcess: 'replaceShortcut' })}
          checkedLabel={t('bulkActionTooltip.deselect', { postProcess: 'replaceShortcut' })}
          isChecked={isSelectMode && allTasksSelected}
          isIndeterminate={isSelectMode && !allTasksSelected}
          onClick={handleChangeSelectAll}
          data-testid="task-select-all"
          isDisabled={filteredTaskIds.length === 0}
          hasTooltip
        />

        {activeTimezone && !isSelectMode && (
          <>
            <Pill data-testid="active-timezone-label" label={timezoneOffset} color="primary"></Pill>
            <Pill
              data-testid="local-timezone-label"
              label={t('list.header.local')}
              color="primary"
              css="margin-left: 6px;"
            ></Pill>
          </>
        )}

        {isSelectMode && (
          <Box direction="row" gap="xxsmall">
            {canBulkSkip && (
              <IconButton
                tertiary
                label={t('bulkActionTooltip.skip', { postProcess: 'replaceShortcut' })}
                data-testid="bulk-skip-task-button"
                icon="skip"
                onClick={handleSkip}
                tipPlacement="top"
              />
            )}
            {canBulkEdit && (
              <>
                <IconButton
                  tertiary
                  label={t('bulkActionTooltip.edit', { postProcess: 'replaceShortcut' })}
                  icon="edit"
                  tipPlacement="top"
                  onClick={handleClickBulkEdit}
                />
                <IconButton
                  tertiary
                  label={t('bulkActionTooltip.copy', { postProcess: 'replaceShortcut' })}
                  icon="copy"
                  tipPlacement="top"
                  onClick={handleCopyTasks}
                />
              </>
            )}
            {isCurrentVersion && canCreateSnippet && (
              <IconButton
                tertiary
                label={t('bulkActionTooltip.createSnippet')}
                icon="snippet"
                tipPlacement="top"
                onClick={() => {
                  // TODO: check this is the best way to convert ids to internalIds
                  const selectedInternalIds = selectedIds.map(id => tasksLookup[id].internal_id)
                  openModal({
                    type: 'tasks-create-as-snippet',
                    data: { taskInternalIds: selectedInternalIds, runbookId }
                  })
                }}
              />
            )}
            {canBulkEdit && (
              <IconButton
                tertiary
                label={t('bulkActionTooltip.delete')}
                icon="trash-o"
                tipPlacement="top"
                onClick={handleClickBulkDelete}
              />
            )}
            {selectedIds.length > 0 && (
              <SingleLineText data-testid="task-select-all-label">{`${selectedIds.length} ${t(
                'list.header.selected'
              )}`}</SingleLineText>
            )}
          </Box>
        )}
      </Box>

      {canCreateRootTask && !isSelectMode && (
        <Box>
          <Button
            data-testid="create-task-no-predecessors-button"
            tertiary
            disableTooltip
            disabled={disableCreateButton}
            icon="add"
            onClick={handleClickCreateTask}
            label={t('createTask')}
          />
        </Box>
      )}
      {isIncident && templateType === 'off' && (
        <Box direction="row" css={{ position: 'absolute', right: '2rem' }}>
          <Menu
            align="start"
            menuStyle={{ width: 'fit-content' }}
            data-testid="sort-tasks-menu"
            trigger={
              <Button
                data-testid="sort-tasks-menu-button"
                tertiary
                label={`Sort: ${t(sortButtonLabel)}`}
                icon="switch-vertical"
              />
            }
          >
            {sortMenuItems.map(item => (
              <MenuListItem key={item.label} selected={item.selected} label={item.label} onClick={item.onClick} />
            ))}
          </Menu>
        </Box>
      )}
    </Box>
  )
}

const SingleLineText = styled(Text).attrs(() => ({
  alignSelf: 'center',
  color: 'text-light'
}))`
  overflow: hidden;
  white-space: nowrap;
`
