import { capitalize, unescape } from 'lodash'

import { Text } from '@cutover/react-ui'
import { RunbookListRunbook } from 'main/services/queries/types'
import { useRunbooksMetaProjects } from 'main/services/api/data-providers/workspace'
import { Bullet } from './bullet'

export const ProjectName = ({ runbook: { project_parent_id, project_name } }: { runbook: RunbookListRunbook }) => {
  const { projectLookup } = useRunbooksMetaProjects()

  return (
    <>
      <Bullet />
      <Text size="small" data-testid={project_name}>
        {project_parent_id
          ? `${unescape(projectLookup?.[project_parent_id]?.name)} / ${project_name}`
          : capitalize(project_name)}
      </Text>
    </>
  )
}
