import { useCallback, useEffect } from 'react'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'

import { getRunbookId, getRunbookVersionId } from 'main/recoil/shared/nav-utils'
import { apiClient } from 'main/services/api'
import { useWebsockets } from 'main/services/hooks'
import { WebsocketKeys } from '../api/websocket-providers'
import { QueryKeys } from './query-keys'

export type ActivityTrackableType =
  | 'User'
  | 'RunbookTeam'
  | 'Comment'
  | 'Task'
  | 'Runbook'
  | 'CustomField'
  | 'IncidentSeverity'
  | 'IncidentStatus'
  | 'Stream'

export type ActivityConfig = {
  key: string
  icon: string
  text: string
  group: string
}

export type ActivitiesResponse = {
  activities: ActivityVM[]
  meta: {
    activity_config: ActivityConfig[]
    pagination: { has_more: boolean; total_size: number }
    permissions: { update: number[] }
  }
}

export type ActivityVM = {
  id: number
  key: string
  activist: Activist
  created_at: Date
  display?: {
    inline_message?: string
    message?: string
    comment_id?: number
    reason?: string
    attachments?: Attachment[]
  }
  changes?: ActivityChanges[]
  trackables: Trackable[]
  featured: boolean
  recipient_type: Recipients
  recipient_id: number
  new?: boolean
  text?: string
  grouped?: boolean
  parent?: boolean
}

export type Attachment = {
  url: string
  name: string
  type: string
}

export type ActivityChanges = {
  field: string
  value: string
}

export type Activist = {
  type: ActivistType
  properties: ActivityProperties
}

export type ActivistType = 'User' | 'Runbook'

type IncidentResponse = {
  activity: ActivityVM
}

export type Recipients = 'Incident' | 'Runbook'

export type TrackableProperties = {
  id?: number
  key?: string
  content?: string
  color?: string
  createdAt?: number
  featured?: boolean
  runId?: number
  runbookVersionId?: number
  taskId?: number
  label?: string
  name?: string
  internal_id?: number
}

export type ActivityProperties = {
  id: number
  name: string
}

export type Trackable = {
  type: ActivityTrackableType
  properties?: TrackableProperties
}

const RUNBOOK_CHANNEL_NAME = 'RunbookChannel'

export const useActivities = (
  runbookId: number,
  getNextPageParam: (activityResponse: ActivitiesResponse) => ActivityVM['id']
) => {
  const client = useQueryClient()
  const websockets = useWebsockets()
  const activityFeedIdentifier = WebsocketKeys.runbookActivityFeed(runbookId)

  const activityFeedRunbookChannel = websockets.findExistingSubscription(
    RUNBOOK_CHANNEL_NAME,
    runbookId,
    activityFeedIdentifier
  )

  const cacheKey = [QueryKeys.Activities, QueryKeys.Runbooks, String(runbookId)]

  const updateCache = useCallback(
    (data: IncidentResponse) => {
      if (!data.activity) return
      let hasExistingActivity = false
      const existingData = client.getQueryData<{ pages: ActivitiesResponse[] } | undefined>(cacheKey)

      if (existingData) {
        hasExistingActivity = existingData.pages.some(page =>
          page.activities.some(activity => activity.id === data.activity.id)
        )
      }

      if (!hasExistingActivity) {
        client.setQueryData<{ pages: ActivitiesResponse[] } | undefined>(cacheKey, prev => {
          if (!prev) return
          const [firstPage, ...restPages] = prev.pages
          return {
            ...prev,
            pages: [
              { ...firstPage, activities: [{ ...data.activity, new: true }, ...firstPage.activities] },
              ...restPages
            ]
          }
        })
      } else {
        client.setQueryData<{ pages: ActivitiesResponse[] } | undefined>(cacheKey, prev => {
          if (!prev) return
          return {
            ...prev,
            pages: prev.pages.map(page => ({
              ...page,
              activities: page.activities.map(activity => {
                if (activity.id === data.activity.id) return data.activity
                return activity
              })
            }))
          }
        })
      }
    },
    [client, runbookId]
  )

  useEffect(() => {
    if (!runbookId || activityFeedRunbookChannel) return

    websockets.subscribe(RUNBOOK_CHANNEL_NAME, runbookId, activityFeedIdentifier, {
      received: updateCache
    })

    return () => {
      websockets.unsubscribe(RUNBOOK_CHANNEL_NAME, runbookId, activityFeedIdentifier)
    }
  }, [runbookId])

  const getActivities = async (lastId: string) => {
    const idQuery = lastId ? `last_id=${lastId}` : ''
    const recipientQuery = `&recipient_type=Runbook&recipient_id=${runbookId}`

    const { data } = await apiClient.get<ActivitiesResponse[]>({
      url: `activities?${idQuery}${recipientQuery}`
    })

    return data
  }

  return useInfiniteQuery<ActivitiesResponse, Error, ActivitiesResponse>(
    cacheKey,
    // @ts-ignore see here https://github.com/gocutover/core/pull/1064#discussion_r697596903
    // also see this ticket to resolve https://cutover.atlassian.net/browse/OR-364
    ({ pageParam }) => getActivities(pageParam),
    {
      getNextPageParam,
      cacheTime: 0,
      staleTime: 0
    }
  )
}

export function useDeleteCommentActivityMutation() {
  const runbookId = getRunbookId()
  const runbookVersionId = getRunbookVersionId()

  return useMutation<unknown, Error, { commentId: number; reason: string }>(
    ['activity-delete-request'],
    async ({ commentId, reason }) => {
      await apiClient.patch({
        url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/comments/${commentId}`,
        data: { reason }
      })
    }
  )
}
