import { useCallback, useEffect } from 'react'
import { saveAs } from 'file-saver'
import { Anchor } from 'grommet'
import { useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { Box, Message, Text, useInitialMount } from '@cutover/react-ui'
import { ApiResponse, downloadRunbookCSVTemplate, useImportTasksCsv } from './import-tasks-csv'
import { CheckboxField, FileInputField, FormModal, TimezoneSelectField } from 'main/components/shared/form'
import { useLanguage } from 'main/services/hooks'
import { getClientIANATimezone } from 'main/services/timezone-util'
import { ActiveRunbookModel, ActiveRunbookVersionModel, RunbookViewModel } from 'main/data-access'
import { TaskCSVImportModalType } from 'main/recoil/runbook'

const validationSchema = yup.object().shape({
  file: yup.mixed().required('You must choose a file'),
  timezone: yup.string().required(),
  overwrite: yup.bool(),
  useCentralTeams: yup.bool()
})

export type CsvImportForm = yup.InferType<typeof validationSchema>

type CsvImportFormProps = {
  open: boolean
  closeModal: () => void
  planningMode: boolean
  resourceType: string
}

export const CsvImportModal = ({ open, planningMode, resourceType, closeModal }: CsvImportFormProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'csvImportModal' })
  const notify = RunbookViewModel.useAction('notify')
  const timezone = getClientIANATimezone()
  const runbookId = ActiveRunbookModel.useId()
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const importTasksCsvMutation = useImportTasksCsv()

  const updateModal = RunbookViewModel.useAction('modal:update')
  const { active } = RunbookViewModel.useGet('modal')
  const modalData = (active as TaskCSVImportModalType)?.data

  const onSubmit = useCallback(
    async (data: CsvImportForm): Promise<{ formData: FormData } | undefined> => {
      const formData = new FormData()
      if (data) {
        formData.append('file', data.file as File)
        formData.append('timezone', data.timezone)
        formData.append('overwrite', JSON.stringify(data.overwrite))
        formData.append('use_central_teams', JSON.stringify(data.useCentralTeams))

        updateModal({ status: 'submitting' })
        return { formData }
      }
    },
    [runbookId, runbookVersionId, updateModal]
  )

  // The modal should be closed when success received from the websocket UserChannel
  // with request method runbook_version_import_success.
  useEffect(() => {
    if (modalData?.status === 'success') {
      notify.success(t('successMessage'))
      closeModal()
    }
  }, [modalData?.status])

  const clickSubmit = async (data: CsvImportForm) => {
    const result = await onSubmit(data)
    if (!result) return

    const { formData } = result
    return await importTasksCsvMutation.mutateAsync({ formData, runbookId, runbookVersionId })
  }

  const downloadTemplate = async () => {
    try {
      const csvTemplate: ApiResponse = await downloadRunbookCSVTemplate()
      const file = new Blob([csvTemplate.data], { type: 'text/csv' })
      const fileName = 'RunbookTemplate.csv'
      saveAs(file, fileName)
      notify.success(t('templateDownloadSuccess'))
    } catch (error) {
      notify.error(t('templateDownloadError'))
    }
  }

  return (
    <FormModal<CsvImportForm>
      title={t('title')}
      confirmText={t('confirmText')}
      loadingText={t('loadingText')}
      confirmIcon="save"
      open={open}
      onClose={closeModal}
      onSubmit={clickSubmit}
      // Here the submitting status comes from the modal data which is waiting for the success or error message from the websocket.
      isModalSubmitting={modalData?.status === 'submitting' || modalData?.status === 'success'}
      reValidateMode="onSubmit"
      schema={validationSchema}
      preventAutoClose
      customErrors={modalData?.status === 'error' ? modalData.context : []}
      description={
        <Text>
          {t('description')} <Anchor href={t('learnMoreLink')} label={t('learnMoreText')} />
          <br />
          <br />
          <Anchor
            tabIndex={0}
            onKeyDown={e => {
              if (e.code === 'Enter') {
                downloadTemplate()
              }
            }}
            onClick={downloadTemplate}
          >
            {t('downloadTemplate')}
          </Anchor>
        </Text>
      }
      defaultValues={{
        file: undefined,
        timezone: timezone,
        overwrite: false,
        useCentralTeams: false
      }}
    >
      <CsvImportFormFields showCheckbox={planningMode} resourceType={resourceType} />
    </FormModal>
  )
}
type CsvImportFormFieldsProps = {
  showCheckbox: boolean
  resourceType: string
  timezone?: any
}

const CsvImportFormFields = ({ showCheckbox, resourceType }: CsvImportFormFieldsProps) => {
  const isFirstMount = useInitialMount()
  const { t } = useLanguage('runbook', { keyPrefix: 'csvImportModal' })
  const { watch, reset } = useFormContext()

  const updateModalStatus = RunbookViewModel.useAction('modal:update')
  const activeModal = RunbookViewModel.useGet('modal').active
  const status = (activeModal as TaskCSVImportModalType)?.data?.status

  const isSnippet = resourceType === 'Snippet'
  const fileInputValue = watch('file')

  useEffect(() => {
    if (isFirstMount) return
    reset({ file: fileInputValue })
    updateModalStatus({ context: undefined, status: 'idle' })
  }, [fileInputValue])

  return (
    <>
      <Box gap="medium">
        {isSnippet && <Message message={t('snippetInfo')} type="info" />}
        <FileInputField<CsvImportForm> name="file" disabled={status === 'submitting'} accept="text/csv" inlineError />
        <TimezoneSelectField<CsvImportForm>
          name="timezone"
          css="width: 100%"
          label={t('timezoneLabel', { resourceType: resourceType.toLowerCase() })}
          disabled={status === 'submitting'}
          customFirstOption={null}
          required
        />
      </Box>
      {showCheckbox && (
        <CheckboxField<CsvImportForm> name="overwrite" disabled={status === 'submitting'} label={t('overwriteLabel')} />
      )}
      {showCheckbox && (
        <CheckboxField<CsvImportForm>
          name="useCentralTeams"
          disabled={status === 'submitting'}
          label={t('useCentralTeamsLabel')}
          helpText={t('useCentralTeamsLabelHelp')}
        />
      )}
    </>
  )
}
