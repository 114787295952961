import { ReactElement, RefObject } from 'react'
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso'

import { Text } from '../../typography/text/text'

export type InfiniteListProps<TItem> = {
  height?: string | number
  items: TItem[] | undefined
  initialIndex?: number
  hasNextPage?: boolean | undefined
  fetchNextPage?: () => Promise<any>
  isFetchingNextPage?: boolean
  renderItem: (index: number, item: TItem) => ReactElement | null
  renderLoader?: () => ReactElement | null
  initialItemCount?: number
  atBottomStateChange?: (atBottom: boolean) => void
  increaseViewportBy?: number
  setRef?: RefObject<VirtuosoHandle>
  defaultItemHeight?: number
  fixedItemHeight?: number
  role?: string
  tabIndex?: number
  customScrollParent?: HTMLElement
}

// Here is documentation for properties https://virtuoso.dev/virtuoso-api-reference/#virtuoso-properties

export function InfiniteList<TItem>({
  height = '100%',
  items,
  initialIndex,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  renderItem,
  renderLoader,
  initialItemCount,
  setRef,
  role,
  tabIndex,
  customScrollParent = undefined,
  ...virtuosoProps
}: InfiniteListProps<TItem>) {
  return (
    <Virtuoso
      customScrollParent={customScrollParent}
      ref={setRef}
      role={role}
      tabIndex={tabIndex}
      style={{ height }}
      data={items}
      /*
        https://github.com/petyosi/react-virtuoso/issues/26
        There is an issue with using react-virtuoso and react-testing-library together.
        The list of items is not rendered during the test, initialItemCount prop is used to fix this issue.
        Please don't set it as `initialItemCount={items.length}` for development or production mode,
        as it causes a bug with rendering the next page.
      */
      initialItemCount={items && items.length > 0 ? (initialItemCount ? initialItemCount : 1) : 0}
      initialTopMostItemIndex={initialIndex ?? 0}
      endReached={() => {
        if (hasNextPage && !isFetchingNextPage && fetchNextPage) {
          fetchNextPage()
        }
      }}
      itemContent={renderItem}
      components={{
        Footer: () =>
          isFetchingNextPage ? (
            renderLoader ? (
              renderLoader()
            ) : (
              <Text
                textAlign="center"
                color="text-light"
                css={`
                  display: flex;
                  justify-content: center;
                `}
              >
                Loading....
              </Text>
            )
          ) : null
      }}
      {...virtuosoProps}
    />
  )
}
