import { Editor, Node, Range, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'
import { capitalize } from 'lodash'

import { Format } from '../text-editor-types'

export const isMarkActive = (editor: Editor, format: Format, options?: any) => {
  let result = false
  const { selection } = editor

  try {
    if (!!selection) {
      const marks = Editor.marks(editor)

      if (format === 'status') {
        if (options) {
          // testing if a specific status is active
          result = marks ? marks.status === options.statusType : false
        } else {
          // testing if ANY status is active
          result = marks ? Boolean(marks['status']) : false
        }
      } else {
        result = marks ? marks[format] === true : false
      }
    }
  } catch (e) {
    // Catch any errors in the Editor.node call and log them to the console but return false to avoid breaking the UI
    // See https://cutover.atlassian.net/browse/CFE-2008
    console.error(e)
  }

  return result
}

export const removeMarksAtSelection = (editor: Editor) => {
  const appliedMarks = Editor.marks(editor)
  const appliedMarksArray = appliedMarks ? Object.keys(appliedMarks) : []
  for (var i = 0; i < appliedMarksArray.length; i++) {
    Editor.removeMark(editor, appliedMarksArray[i])
  }
}

// If a range is selected, only look inside that, otherwise, remove all marks
export const removeMarksInRange = (editor: Editor) => {
  const { selection } = editor
  const allTextNodes = Node.texts(editor)

  Editor.withoutNormalizing(editor, () => {
    for (const [node, path] of allTextNodes) {
      if (
        !selection ||
        Range.isCollapsed(selection) ||
        (Range.isExpanded(selection) && Range.includes(selection, path))
      ) {
        let appliedMarks = []
        for (const property in node) {
          if (property !== 'text') {
            appliedMarks.push(property)
          }
        }
        Transforms.unsetNodes(editor, appliedMarks, { at: path })
      }
    }
  })

  ReactEditor.focus(editor)
}

export const toggleMark = (editor: Editor, format: Format, options?: any) => {
  const isActive = isMarkActive(editor, format)

  if (format === 'status') {
    // Statuses behave slightly differently to regular marks
    // If toggle same one, removes whole mark, if toggle to diff one, switches
    const marks = Editor.marks(editor)

    if (isActive) {
      if (marks?.status === options?.statusType) {
        Transforms.unsetNodes(editor, 'status', { at: editor.selection?.anchor.path })
      } else {
        Transforms.setNodes(editor, { status: options?.statusType }, { at: editor.selection?.anchor.path })
      }
    } else {
      addStatus(editor, options?.statusType)
    }
  } else {
    if (isActive) {
      Editor.removeMark(editor, format)
    } else {
      Editor.addMark(editor, format, true)
    }
  }

  ReactEditor.focus(editor)
}

const addStatus = (editor: Editor, status?: string) => {
  if (!status) {
    status = 'idea'
  }
  Editor.addMark(editor, 'status', status)
  const isPointSelected = editor.selection && Range.isCollapsed(editor.selection)
  if (isPointSelected) {
    const defaultStatusText = capitalize(status)
    editor.insertText(defaultStatusText)
    Transforms.insertNodes(editor, [{ text: ' ' }])
  }
}
