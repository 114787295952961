import { LoadableType } from '@cutover/utility-types'
import { GetAccountResponseType } from 'main/services/api/data-providers/account/use-get-account-data'
import { RunbookGetResponse } from 'main/services/api/data-providers/runbook-types'
import { GetRunbookVersionResponse } from 'main/services/queries/use-runbook-versions'
import { TaskListResponseType } from 'main/services/queries/use-tasks'
import { useLoadableGlobalState, useResetGlobalState, useSetGlobalState } from '../hooks/recoil/global-state-setup'
import {
  GetAccountResponseAndComputedPropertiesState,
  RunbookViewStateType,
  TaskListResponseAndComputedPropertiesState
} from 'main/recoil/runbook'

export type ResponseNameToArgsRecord = {
  account: GetAccountResponseType
  runbookVersion: GetRunbookVersionResponse
  runbook: RunbookGetResponse
  tasks: TaskListResponseType
  runbookView: RunbookGetResponse
}

export type ResponseNameToReturnRecord = {
  account: GetAccountResponseAndComputedPropertiesState
  runbookVersion: GetRunbookVersionResponse
  runbook: RunbookGetResponse
  tasks: TaskListResponseAndComputedPropertiesState
  runbookView: RunbookViewStateType
}

export type GlobalStateSetupModel = {
  useSet: <TRequestType extends keyof ResponseNameToArgsRecord>(
    requestType: TRequestType
  ) => (response: ResponseNameToArgsRecord[TRequestType]) => void
  useReset: <TRequestType extends keyof ResponseNameToArgsRecord>(requestType: TRequestType) => () => void
  useLoadable: <TRequestType extends keyof ResponseNameToArgsRecord>(
    requestType: TRequestType
  ) => LoadableType<ResponseNameToReturnRecord[TRequestType]>
}

export const GlobalStateSetupModel: GlobalStateSetupModel = {
  useSet: useSetGlobalState,
  useReset: useResetGlobalState,
  useLoadable: useLoadableGlobalState
}
