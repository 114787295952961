import { Dispatch, SetStateAction, useCallback } from 'react'
import dagre from '@dagrejs/dagre'
import { extend, isEmpty, isEqual } from 'lodash'
import { saveAs } from 'file-saver'
import * as d3 from 'd3'

import { formatTimeDifference, inViewport, prepareNode } from './node-map-utils'
import { buildCriticalPath } from 'main/services/tasks/critical-path'
import { StreamListStream, TaskListTask, TaskType } from 'main/services/queries/types'
import { GraphNode, InfoDialogPosition, NodeData, PaintShadowEdge, ViewportDimensions } from './node-map-types'
import { paintMeta } from './paint-helpers/paint-meta'
import { paintShadowEdge } from './paint-helpers/paint-shadow-edge'
import { paintEdge } from './paint-helpers/paint-edge'
import { paintShadowNode } from './paint-helpers/paint-shadow-node'
import { paintMiniNode } from './paint-helpers/paint-mini-node'
import { BASE_UNIT, INFO_DIALOG_WIDTH, nodeMapState } from './node-map-state'
import { useNodeMapPaintEntryPoint } from './paint-helpers/use-node-map-paint-entry-point'
import { RunbookFilterType } from 'main/services/tasks/filtering'
import { RightPanel } from 'main/components/layout/right-panel'
import { ActiveRunbookModel, RunbookViewModel } from 'main/data-access'

type NodeMapControllerProps = {
  taskLookup: Record<string, TaskListTask>
  filteredTaskIds: number[]
  streamLookup: Record<number, StreamListStream>
  taskTypeLookup: Record<number, TaskType>
  filterState: RunbookFilterType
  dimensions: ViewportDimensions
  setInfoDialog: (id: number | undefined) => void
  positionDialog: (position: InfoDialogPosition) => void
  selectedDependency: number | null
  setSelectedDependency: Dispatch<SetStateAction<number | null>>
  rightPanelValue: RightPanel | null
  filterMode: 'default' | 'highlight'
  selectedEdges: string[] | null
}

const INITIAL_LOAD_DELAY = 100

export const useNodeMapController = () => {
  const paintEntryPoint = useNodeMapPaintEntryPoint()
  const { name: runbookName } = ActiveRunbookModel.useGet()
  const getNodeMapVersion = RunbookViewModel.useGetCallback('nodeMap')

  let canvas: d3.Selection<HTMLCanvasElement, unknown, HTMLElement, any>,
    miniCanvas: d3.Selection<HTMLCanvasElement, unknown, HTMLElement, any>,
    shadowCanvas: HTMLCanvasElement,
    graph: dagre.graphlib.Graph<{}>,
    enterSel: string[] = [],
    exitSel: string[] = []

  let state = nodeMapState()

  const controller = useCallback(
    ({
      taskLookup,
      filteredTaskIds,
      streamLookup,
      taskTypeLookup,
      filterState,
      dimensions,
      setInfoDialog,
      positionDialog,
      selectedDependency,
      setSelectedDependency,
      rightPanelValue,
      filterMode,
      selectedEdges
    }: NodeMapControllerProps) => {
      const tasks = filterMode === 'default' ? filteredTaskIds.map(id => taskLookup[id]) : Object.values(taskLookup)
      const { taskIds: criticalPath } = buildCriticalPath(tasks, { from: null, to: 0, float: 0 })

      /* -------------------- Setup canvas and drawing context -------------------- */

      let zoom = d3
        .zoom<HTMLCanvasElement, unknown>()
        .scaleExtent([0.01, 100])
        .on('zoom', panZoom)
        .on('start', panZoomStart)
        .on('end', panZoomEnd)

      if (!canvas) {
        canvas = d3
          .select('#map')
          .append('canvas')
          .attr('id', 'maincanvas')
          .attr('data-testid', 'maincanvas')
          .attr('tabindex', '0')
          .on('mousedown', onMouseDown)
          .on('mousemove', onMouseMove)
          .on('mouseleave', onMouseLeave)
          .call(zoom)
      }

      if (!miniCanvas) {
        miniCanvas = d3
          .select('#map')
          .append('canvas')
          .style('position', 'absolute')
          .style('top', state.margin + 'px')
          .style('left', state.margin + 'px')
          .style('cursor', 'crosshair')
          .style('display', 'none')
          .style('box-shadow', '0px 0px 1px rgba(0,0,0,0.2)')
          .style('background-color', 'rgb(255,255,255)')
          .style('z-index', '1')
          .on('click', mouseClickMini)
      }

      if (!shadowCanvas) {
        shadowCanvas = document.createElement('canvas')
      }

      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      const ctx = canvas.node()!.getContext('2d')!
      const miniCtx = miniCanvas.node()!.getContext('2d')!
      const shadowCtx = shadowCanvas.getContext('2d', { willReadFrequently: true })!
      /* eslint-enable @typescript-eslint/no-non-null-assertion */

      const layoutWrapperElement = document.getElementById('layout-main-wrapper')

      /* ----------------------------- Initialization ----------------------------- */

      initialize()

      async function initialize() {
        const { graphVersionFlag, dataVersionFlag } = await getNodeMapVersion()
        const structureChanged = graphVersionFlag > state.graphVersionFlag
        const dataChanged = dataVersionFlag > state.dataVersionFlag
        state.graphVersionFlag = graphVersionFlag
        state.dataVersionFlag = dataVersionFlag

        if (!state.initialized) {
          layoutWrapperElement?.addEventListener('resize', onResize)
          layoutWrapperElement?.addEventListener('keydown', onKeyDown)
          layoutWrapperElement?.addEventListener('mouseup', onMouseUp)
          d3.selectAll(canvas).style('opacity', 0)
        }

        const selectedTaskId: number | null = rightPanelValue?.type === 'task-edit' ? rightPanelValue.taskId : null
        state.selectedTaskId = selectedTaskId

        const filterChanged = !isEqual(state.filterState, filterState)
        state.filterState = filterState
        state.filterMode = filterMode

        const tasksChanged = !isEqual(state.tasks, tasks) && !isEmpty(state.tasks)
        state.tasks = tasks

        const dimensionsChanged = !isEqual(state.dimensions, dimensions)
        state.dimensions = dimensions

        const selectedEdgesChanged = !isEqual(state.selectedEdges, selectedEdges)
        state.selectedEdges = selectedEdges

        if (dataChanged || selectedEdgesChanged) {
          updateData()
          render(false)
        } else if (!state.initialized || structureChanged || filterChanged || tasksChanged || dimensionsChanged) {
          setViewport()
          buildGraph()
          zoomToFit(false)
          closeInfoDialog()
          render((filterChanged && filterMode !== 'highlight') || tasksChanged)
        }

        if (!state.initialized) {
          // prevent flicker on initial load
          setTimeout(() => {
            d3.selectAll(canvas).style('opacity', 1)
          }, INITIAL_LOAD_DELAY)
          state.initialized = true
        }

        if (!dataChanged) drawSelectedTask()
      }

      function updateData() {
        _graphNodes().forEach(nodeId => {
          const node = graph.node(nodeId)
          extend(
            node,
            prepareNode({
              task: taskLookup[nodeId],
              filteredTaskIds,
              filterMode: state.filterMode,
              streamLookup,
              taskTypeLookup,
              criticalPath,
              isUpdate: true
            })
          )
        })
      }

      function buildGraph() {
        // Store any existing coordinates before initializing the new graph
        const existingCoordMap: { [x: string]: [number, number] } = {}
        let existingNodes: string[] = []
        enterSel = []
        exitSel = []

        if (graph) {
          _graphNodes().forEach(nodeId => {
            const existingNode = graph.node(nodeId)
            existingCoordMap[nodeId] = [existingNode.x, existingNode.y]
          })
        }

        graph = new dagre.graphlib.Graph()
        graph.setGraph({
          nodesep: BASE_UNIT * 6,
          ranksep: BASE_UNIT * 3,
          marginx: state.margin,
          marginy: state.margin,
          rankdir: 'TB',
          ranker: 'network-simplex'
        })

        graph.setDefaultEdgeLabel(() => ({}))

        const currentTaskIds = tasks.map(task => task.id)
        tasks.forEach(task => {
          const nodeData = prepareNode({
            task,
            filteredTaskIds,
            filterMode: state.filterMode,
            streamLookup,
            taskTypeLookup,
            criticalPath
          })

          let predsNotShownCount = 0
          task.predecessor_ids.forEach(predId => {
            if (currentTaskIds.includes(predId)) {
              graph.setEdge(predId.toString(), task.id.toString(), {
                id: `${predId}:${task.id}`,
                opacity: state.display.edgeDefaultOpacity,
                color: state.display.edgeDefaultColor
              })
            } else {
              predsNotShownCount++
            }
          })
          nodeData.pidsExt = predsNotShownCount

          let succsNotShownCount = 0
          task.successor_ids.forEach(succId => {
            if (!currentTaskIds.includes(succId)) {
              succsNotShownCount++
            }
          })
          nodeData.sidsExt = succsNotShownCount

          graph.setNode(task.id.toString(), nodeData)
        })

        // Build graph structure (the most expensive part of the process)
        dagre.layout(graph)

        // Set dimensions based on graph size
        const graphObj = graph.graph()
        state.graphWidth = graphObj.width ?? 0
        state.graphHeight = graphObj.height ?? 0
        const graphRatio = state.graphWidth / state.graphHeight

        // Set minicanvas size
        state.miniCanvasWidth = Math.round(Math.sqrt(state.miniCanvasArea * graphRatio))
        state.miniCanvasHeight = Math.round(state.miniCanvasWidth / graphRatio)
        state.miniCanvasScale = state.miniCanvasWidth / state.graphWidth

        miniCanvas
          .attr('width', state.miniCanvasWidth * state.devicePixelRatio)
          .attr('height', state.miniCanvasHeight * state.devicePixelRatio)
          .style('width', state.miniCanvasWidth + 'px')
          .style('height', state.miniCanvasHeight + 'px')

        // Store target positions separately so they're animatable
        _graphNodes().forEach(nodeId => {
          const existingNodeCoords = existingCoordMap[nodeId]
          const node = graph.node(nodeId) as GraphNode
          node.tx = node.x
          node.ty = node.y
          node.px = existingNodeCoords ? existingNodeCoords[0] : null
          node.py = existingNodeCoords ? existingNodeCoords[1] : null
          if (!existingNodes.includes(nodeId)) {
            enterSel.push(nodeId)
          }
        })
      }

      /* ------------------------- Keyboard event bindings ------------------------ */

      function onKeyDown(e: KeyboardEvent) {
        switch (e.code) {
          case 'ArrowUp':
            zoom.translateBy(canvas, 0, 50)
            break
          case 'ArrowDown':
            zoom.translateBy(canvas, 0, -50)
            break
          case 'ArrowRight':
            zoom.translateBy(canvas, -50, 0)
            break
          case 'ArrowLeft':
            zoom.translateBy(canvas, 50, 0)
            break
          case 'KeyZ':
            zoom.scaleTo(canvas, state.transform.scale * 1.3)
            break
          case 'KeyX':
            zoom.scaleTo(canvas, state.transform.scale * 0.7)
            break
          case 'KeyC':
            zoomToFit(true)
            break
          case 'KeyV':
            if (state.activeObject?.id) {
              panTo(state.activeObject.id, true)
            }
            break
          case 'KeyD':
            // TODO: consider displaying a confirmation dialog as this can be triggered inadvertently.
            downloadCanvas()
            break
          case 'Escape':
            closeInfoDialog()
            break
        }
      }

      /* ----------------------- Canvas navigation functions ---------------------- */

      function zoomToFit(animate: boolean) {
        const { width: viewportWidth, height: viewportHeight } = state.viewport
        const { graphWidth, graphHeight } = state

        // Calculate the scaling factor and translation values to fit the graph within the viewport
        const scale = Math.min(viewportWidth / graphWidth, viewportHeight / graphHeight)
        const translateX = (viewportWidth - graphWidth * scale) / 2
        const translateY = (viewportHeight - graphHeight * scale) / 2

        // Update the fitScale in state
        state.transform.fitScale = scale

        // Create the transformation
        const transform = d3.zoomIdentity.translate(translateX, translateY).scale(scale)

        // Apply the transformation, with optional animation
        const transition = canvas.transition().duration(animate ? state.animationDuration : 0)
        transition.call(zoom.transform, transform)
      }

      function panTo(id: number | string, openInfo: boolean) {
        const node = graph.node(id.toString())
        if (!node) return

        closeInfoDialog()

        const newTx = state.viewport.width / 2 - node.x
        const newTy = state.viewport.height / 2 - node.y

        const transform = d3.zoomIdentity.translate(newTx, newTy)
        canvas.transition().duration(state.animationDuration).call(zoom.transform, transform)

        if (openInfo) {
          setTimeout(() => {
            showInfoDialog(node as NodeData, 'node', node.x, node.y)
          })
        }
      }

      /* ---------------------------- D3 zoom handlers ---------------------------- */

      function panZoomStart(event: d3.D3ZoomEvent<HTMLCanvasElement, unknown>) {
        if (event.sourceEvent) {
          state.mouseDownCoords = { x: event.sourceEvent.x, y: event.sourceEvent.y }
        }
      }

      function panZoom(event: d3.D3ZoomEvent<HTMLCanvasElement, unknown>) {
        const { sourceEvent } = event
        const { activeObject, transform } = state

        if (sourceEvent && sourceEvent.shiftKey) {
          return
        }

        const t = event.transform

        if (transform.scale === t.k) {
          // scale hasn't changed, so this is a pan
          canvas.style('cursor', 'url(/img/closedhand.cur), default')
        }

        setInternalZoomData(t.x, t.y, t.k)

        if (activeObject) {
          const node = graph.node(activeObject.id.toString())
          if (node) {
            moveInfoDialog(node.x, node.y)
          }
        }

        draw(state.isAnimating)
        drawMiniCanvas()
      }

      function panZoomEnd(event: d3.D3ZoomEvent<HTMLCanvasElement, unknown>) {
        const { mouseDownCoords } = state
        const { sourceEvent } = event

        if (
          mouseDownCoords &&
          sourceEvent &&
          sourceEvent.type !== 'wheel' &&
          mouseDownCoords.x === sourceEvent.x &&
          mouseDownCoords.y === sourceEvent.y
        ) {
          closeInfoDialog()
          canvas.style('cursor', 'url(/img/openhand.cur), default')
          return
        }

        canvas.style('cursor', 'url(/img/openhand.cur), default')
        drawShadow()
      }

      function setInternalZoomData(x: number, y: number, k: number) {
        const { viewport, transform, devicePixelRatio } = state

        // Prevent miniCanvas from displaying if there are no nodes
        if (_graphNodes().length === 0) {
          miniCanvas.style('display', 'none')
          return
        }

        // Toggle miniCanvas display based on zoom level
        miniCanvas.style('display', k <= transform.fitScale ? 'none' : 'block')

        // Adjust for device pixel ratio
        const adjustedK = k * devicePixelRatio
        const adjustedX = x * devicePixelRatio
        const adjustedY = y * devicePixelRatio

        // Polyfill for resetTransform (modern browsers already support it)
        ctx.resetTransform =
          ctx.resetTransform ||
          function () {
            // @ts-ignore
            this.setTransform(1, 0, 0, 1, 0, 0)
          }
        shadowCtx.resetTransform =
          shadowCtx.resetTransform ||
          function () {
            // @ts-ignore
            this.setTransform(1, 0, 0, 1, 0, 0)
          }

        // Reset and apply transformations to both contexts
        ;[ctx, shadowCtx].forEach(context => {
          context.resetTransform()
          context.translate(adjustedX, adjustedY)
          context.scale(adjustedK, adjustedK)
        })

        // Update state transformations
        Object.assign(transform, { scale: k, offsetX: x, offsetY: y })

        // Calculate viewport dimensions and offsets
        const internalWidth = (viewport.width * devicePixelRatio) / adjustedK
        const internalHeight = (viewport.height * devicePixelRatio) / adjustedK
        const internalOffsetX = -adjustedX / adjustedK
        const internalOffsetY = -adjustedY / adjustedK

        // Update viewport state
        Object.assign(viewport, {
          internalWidth,
          internalHeight,
          internalOffsetX,
          internalOffsetY,
          edges: [
            [internalOffsetX, internalOffsetY, internalOffsetX + internalWidth, internalOffsetY],
            [
              internalOffsetX + internalWidth,
              internalOffsetY,
              internalOffsetX + internalWidth,
              internalOffsetY + internalHeight
            ],
            [
              internalOffsetX + internalWidth,
              internalOffsetY + internalHeight,
              internalOffsetX,
              internalOffsetY + internalHeight
            ],
            [internalOffsetX, internalOffsetY + internalHeight, internalOffsetX, internalOffsetY]
          ]
        })
      }

      /* ---------------------------- Helper functions ---------------------------- */

      function pickElement(x: number, y: number) {
        const col = shadowCtx.getImageData(x, y, 1, 1).data
        const rgbString = col[3] === 255 ? `rgb(${col[0]},${col[1]},${col[2]})` : 'rgb(0,0,0)'
        return state.colorToObjMap[rgbString]
      }

      function setViewport() {
        const { left, top, width, height, ratio } = dimensions
        const { viewport, devicePixelRatio } = state

        viewport.width = width
        viewport.height = height
        viewport.ratio = ratio
        viewport.offsetX = left
        viewport.offsetY = top

        canvas
          .attr('width', viewport.width * devicePixelRatio)
          .attr('height', viewport.height * devicePixelRatio)
          .style('width', viewport.width + 'px')
          .style('height', viewport.height + 'px')

        shadowCanvas.width = viewport.width * devicePixelRatio
        shadowCanvas.height = viewport.height * devicePixelRatio
        shadowCanvas.style.width = viewport.width + 'px'
        shadowCanvas.style.height = viewport.height + 'px'
      }

      // Downloads current drawing state of canvas, generating a png at any zoom or pan location.
      function downloadCanvas() {
        const { width, height } = dimensions

        ctx.fillStyle = 'white'
        ctx.fillRect(0, 0, width, height)
        canvas.node()?.toBlob((blob: Blob | null) => {
          if (blob) saveAs(blob, runbookName + '.png')
        })
      }

      /* -------------------------- Mouse event handlers -------------------------- */

      function mouseClickMini(event: MouseEvent) {
        const { x, y } = event
        const { miniCanvasScale, margin, viewport } = state

        const miniCanvasOffset = {
          x: x - viewport.offsetX - margin,
          y: y - viewport.offsetY - margin
        }
        const centerX = miniCanvasOffset.x / miniCanvasScale
        const centerY = miniCanvasOffset.y / miniCanvasScale
        zoom.translateTo(canvas, centerX, centerY)
        closeInfoDialog()
      }

      function onMouseLeave() {
        if (!state.activeObject) {
          closeInfoDialog()
        }
        state.hoverObject = null
        draw()
      }

      function onMouseDown(event: MouseEvent) {
        const { x, y } = event
        const { viewport, devicePixelRatio } = state

        state.mouseDownCoords = { x, y }

        const currentObject = pickElement(
          (x - viewport.offsetX) * devicePixelRatio,
          (y - viewport.offsetY) * devicePixelRatio
        )

        if (currentObject) {
          if (state.activeObject && state.activeObject.id === currentObject.id) {
            closeInfoDialog()
          } else if (currentObject.type === 'node') {
            state.activeObject = currentObject
            canvas.on('.zoom', null)
          }
        } else {
          setSelectedDependency(null)
        }
      }

      function onMouseMove(event: MouseEvent) {
        const { x, y } = event
        const { viewport, devicePixelRatio } = state

        // No need for this at tiny scales
        if (state.transform.scale < 0.25) {
          return false
        }

        const hoveringOver = pickElement(
          (x - viewport.offsetX) * devicePixelRatio,
          (y - viewport.offsetY) * devicePixelRatio
        )

        if (hoveringOver && hoveringOver !== state.hoverObject) {
          //changing hovered elem
          if (hoveringOver.type === 'node') {
            canvas.style('cursor', 'pointer')
            state.hoverObject = hoveringOver
            draw()
          }
        } else if (!hoveringOver && state.hoverObject) {
          //removing hovered elem
          canvas.style('cursor', 'url(/img/openhand.cur), default')
          state.hoverObject = null
          draw()
        }
      }

      function onMouseUp(event: MouseEvent) {
        const { x, y } = event
        const { viewport, devicePixelRatio } = state

        const currentObject = pickElement(
          (x - viewport.offsetX) * devicePixelRatio,
          (y - viewport.offsetY) * devicePixelRatio
        )

        // We have an activeObject, and the currentObject is the same, this is a 'click' on an obj
        if (currentObject && currentObject.type === 'node') {
          const node = graph.node(currentObject.id.toString())
          showInfoDialog(node as NodeData, 'node', node.x, node.y)
          showCriticalPathToSelected(node as NodeData)
          draw()
        }

        canvas.call(zoom) // rebind d3 zoom
      }

      /* --------------------------- View Modifications --------------------------- */

      function showCriticalPathToSelected(node: NodeData) {
        if (!node) return

        _graphNodes().forEach(nodeId => {
          const currentNode = graph.node(nodeId) as NodeData
          currentNode.isCriticalTemp = false
        })

        const { taskIds: cpIds } = buildCriticalPath(tasks, { from: null, to: node.internalId ?? 0, float: 0 })

        cpIds.forEach(id => {
          const cpNode = graph.node(id.toString()) as NodeData
          if (cpNode) {
            cpNode.isCriticalTemp = true
          }
        })
      }

      /* -------------------------- Info dialog functions ------------------------- */

      if (selectedDependency) {
        if (tasks.find(t => t.id === selectedDependency)) {
          const node = graph.node(selectedDependency.toString())
          panTo(selectedDependency, true)
          showCriticalPathToSelected(node as NodeData)
        }
      }

      function showInfoDialog(object: NodeData, type: string, x: number, y: number) {
        if (type !== 'node') {
          return
        }
        state.activeObject = { type: 'node', id: object.id }
        setInfoDialog(object.id)
        moveInfoDialog(x, y)
      }

      function moveInfoDialog(x: number, y: number) {
        const { transform, viewport, units } = state

        const topPosition = transform.offsetY + y * transform.scale
        const avaialbleLeftSpace = transform.offsetX + x * transform.scale + units.base * transform.scale - 6
        const availableRightSpace = viewport.width - avaialbleLeftSpace

        let leftPosition = avaialbleLeftSpace
        const placement = availableRightSpace < INFO_DIALOG_WIDTH ? 'left' : 'right'
        if (placement === 'left') {
          leftPosition -= INFO_DIALOG_WIDTH + transform.scale * 12
        }

        // If node moves off canvas, close the dialog, else position the dialog
        if (
          topPosition + 16 < 0 || // top
          availableRightSpace + 14 * transform.scale < 0 || // right
          topPosition > viewport.height || // bottom
          avaialbleLeftSpace + 6 < 0 // left
        ) {
          closeInfoDialog()
        } else {
          positionDialog({
            x: Math.round(leftPosition) + viewport.offsetX,
            y: Math.round(topPosition) + viewport.offsetY,
            placement
          })
        }
      }

      function closeInfoDialog() {
        setInfoDialog(undefined)

        if (!graph) {
          return false
        }
        const nodes = _graphNodes()
        for (let i = 0; i < nodes.length; i++) {
          ;(graph.node(nodes[i]) as NodeData).isCriticalTemp = false
        }
        state.activeObject = null
        state.mouseDownCoords = null
        draw()
      }

      /* ----------------------------- Draw functions ----------------------------- */

      function drawSelectedTask() {
        if (state.selectedTaskId) {
          const { selectedTaskId } = state
          panTo(selectedTaskId, false)
          state.selectedObject = { type: 'node', id: selectedTaskId }
          state.activeObject = { type: 'node', id: selectedTaskId }
        } else {
          state.selectedObject = null
        }
      }

      function render(animate: boolean) {
        if (!animate) {
          draw()
          drawMiniCanvas()
          drawShadow()
        } else {
          const timer = d3.timer((elapsed: number) => {
            state.isAnimating = true
            // ease(elapsed / animationDuration) compute how far through the animation we are (0 to 1)
            const t = Math.min(1, d3.easeCubic(elapsed / state.animationDuration))

            // update point positions (interpolate between source and target)
            _graphNodes().forEach(nodeId => {
              const node = graph.node(nodeId) as NodeData
              const { tx, ty, px, py } = node

              if (enterSel.includes(nodeId)) {
                node.scale = t
              } else if (exitSel.includes(nodeId)) {
                node.scale = 1 - t
              } else if (tx && ty && px && py) {
                node.x = (tx - px) * t + px
                node.y = (ty - py) * t + py
              }
            })

            // update what is drawn on screen
            draw(true)

            // if this animation is over
            if (t === 1) {
              state.isAnimating = false
              draw()
              drawMiniCanvas()
              drawShadow()
              timer.stop()
            }
          })
        }
      }

      function drawMiniCanvas() {
        const { miniCanvasScale, miniCanvasWidth, devicePixelRatio, graphWidth, transform, viewport } = state

        if (!miniCtx.resetTransform) {
          miniCtx.resetTransform = function () {
            this.setTransform(1, 0, 0, 1, 0, 0)
          }
        }
        miniCtx.resetTransform()
        miniCtx.scale(miniCanvasScale * devicePixelRatio, miniCanvasScale * devicePixelRatio)
        clear('mini')

        _graphNodes().forEach(nodeId => {
          const node = graph.node(nodeId) as NodeData
          paintMiniNode({ ctx: miniCtx, node, state })
        })

        const boundingWidth = (viewport.width / (graphWidth * transform.scale)) * (miniCanvasWidth / miniCanvasScale)
        const boundingHeight = boundingWidth / viewport.ratio
        const boundingXOffset = -transform.offsetX / transform.scale
        const boundingYOffset = -transform.offsetY / transform.scale

        // Draw bounding box
        miniCtx.fillStyle = 'rgba(0,0,0,0.05)'
        miniCtx.beginPath()
        miniCtx.fillRect(boundingXOffset, boundingYOffset, boundingWidth, boundingHeight)

        paintMeta({ ctx: miniCtx, state })
      }

      function draw(hideEdges: boolean = false) {
        const { selectedEdges, hoverObject, activeObject, transform, selectedTaskId } = state

        if (!graph) return false

        clear('main')
        let neighbors: string[] = []

        if (hoverObject && hoverObject.type === 'node' && graph.hasNode(hoverObject.id.toString())) {
          neighbors = graph.neighbors(hoverObject.id.toString()) as unknown as string[]
        }

        if (selectedTaskId && graph.hasNode(selectedTaskId.toString())) {
          neighbors = neighbors.concat(graph.neighbors(selectedTaskId.toString()) as unknown as string[])
        }

        if (activeObject) {
          neighbors = neighbors.concat(graph.neighbors(activeObject.id.toString()) as unknown as string[])
        }

        ctx.globalCompositeOperation = 'source-over'
        if (ctx.setLineDash) ctx.setLineDash([])

        const nodesDrawn = []
        state.units.baseLineWidth = transform.scale < 1 ? 1 + (1 - transform.scale) * 2 : 1

        _graphNodes().forEach(nodeId => {
          const node = graph.node(nodeId) as NodeData

          if (!inViewport(node.x, node.y, state)) return
          nodesDrawn.push(nodeId)

          paintEntryPoint({
            ctx,
            node,
            related: neighbors,
            state,
            filterMode: state.filterMode,
            isFiltered: !isEmpty(state.filterState)
          })
        })

        ctx.globalCompositeOperation = 'destination-over'
        const defaultWidth = 1 / transform.scale
        ctx.lineJoin = 'round'

        if (transform.scale > 0.4 && !hideEdges) {
          const edges = graph.edges()

          edges.forEach(edgeId => {
            const edge = graph.edge(edgeId)
            const sourceNode = graph.node(edgeId.v) as NodeData
            const targetNode = graph.node(edgeId.w) as NodeData
            let opacity = 1
            let color

            if (targetNode.start > sourceNode.end) {
              edge.float = formatTimeDifference(targetNode.start - sourceNode.end)?.label
            }

            // Edge style
            if (ctx.setLineDash) {
              if (sourceNode.stage !== 'complete' && targetNode.start > sourceNode.end) {
                ctx.setLineDash([3, 3])
              } else {
                ctx.setLineDash([])
              }
            }

            const isCritical = sourceNode.isCritical && targetNode.isCritical
            const isCriticalTemp = sourceNode.isCriticalTemp && targetNode.isCriticalTemp
            const hasHighlightFilter = state.filterMode === 'highlight' && !isEmpty(state.filterState)
            const isHighlighted = sourceNode.highlight && targetNode.highlight
            const hasActiveObject =
              activeObject && (sourceNode.id === activeObject.id || targetNode.id === activeObject.id)
            const isSelected =
              selectedTaskId && (selectedTaskId === Number(edgeId.v) || selectedTaskId === Number(edgeId.w))
            const isHoverObject =
              hoverObject && (Number(edgeId.v) === hoverObject.id || Number(edgeId.w) === hoverObject.id)

            // Edge width
            if (selectedEdges?.includes(edge.id)) {
              ctx.lineWidth = defaultWidth * 3
            } else if (isCritical || isCriticalTemp) {
              ctx.lineWidth = defaultWidth * 4
            } else {
              ctx.lineWidth = defaultWidth
            }

            // Edge color
            if (selectedEdges?.includes(edge.id)) {
              color = '33,183,198'
            } else if (isCritical || isCriticalTemp) {
              color = '255,173,153'
            } else {
              color = state.display.edgeDefaultColor
            }

            // Edge opacity
            if (
              activeObject ||
              selectedTaskId ||
              hasHighlightFilter ||
              sourceNode.completionType === 'complete_abandoned' ||
              targetNode.completionType === 'complete_abandoned'
            ) {
              opacity = 0.2
            }

            if (isHighlighted || isCriticalTemp || hasActiveObject || isSelected || isHoverObject) {
              opacity = 1
            }

            ctx.strokeStyle = `rgba(${color}, ${opacity})`

            paintEdge({ ctx, edge, state })
          })
        }
      }

      function drawShadow() {
        clear('shadow')
        state.colorCurrentIndex = 1
        const nodesDrawn = _graphNodes().filter(nodeId => {
          const node = graph.node(nodeId) as NodeData
          return inViewport(node.x, node.y, state)
        })

        nodesDrawn.forEach(nodeId => {
          const node = graph.node(nodeId) as NodeData
          paintShadowNode({ ctx: shadowCtx, node, state })
        })

        shadowCtx.globalCompositeOperation = 'destination-over'

        const edges = graph.edges()
        edges.forEach(edgeId => {
          const edge = graph.edge(edgeId)
          const sourceNode = edgeId.v
          const targetNode = edgeId.w

          if (!nodesDrawn.includes(sourceNode) && !nodesDrawn.includes(targetNode)) {
            return
          }

          paintShadowEdge({ ctx: shadowCtx, edge, edgeId: edgeId as unknown as PaintShadowEdge['edgeId'], state })
        })
      }

      function clear(context: string = '') {
        const { transform, viewport, miniCanvasWidth, miniCanvasHeight, miniCanvasScale } = state
        const { offsetX, offsetY, scale } = transform
        const { width, height } = viewport

        switch (context) {
          case 'main':
            ctx.clearRect((-1 * offsetX) / scale, -offsetY / scale, width / scale, height / scale)
            break

          case 'shadow':
            shadowCtx.clearRect((-1 * offsetX) / scale, -offsetY / scale, width / scale, height / scale)
            break

          case 'mini':
            miniCtx.clearRect(0, 0, miniCanvasWidth / miniCanvasScale, miniCanvasHeight / miniCanvasScale)
            break

          default:
            ctx.clearRect(-offsetX / scale, -offsetY / scale, width / scale, height / scale)
            shadowCtx.clearRect(-offsetX / scale, -offsetY / scale, width / scale, height / scale)
            miniCtx.clearRect(0, 0, miniCanvasWidth / miniCanvasScale, miniCanvasHeight / miniCanvasScale)
            miniCanvas.style('display', 'none')
        }
      }

      function onResize() {
        setViewport()
        zoomToFit(false)
      }

      function _graphNodes() {
        return graph.nodes() ?? []
      }

      // Cleanup
      return () => {
        canvas.on('mousemove', null)
        canvas.on('keydown', null)
        canvas.on('mousedown', null)
        miniCanvas.on('click', null)
        layoutWrapperElement?.removeEventListener('resize', onResize)
        layoutWrapperElement?.removeEventListener('keydown', onKeyDown)
        layoutWrapperElement?.removeEventListener('mouseup', onMouseUp)

        zoom.on('zoom', null)
        zoom.on('start', null)
        zoom.on('end', null)
      }
    },
    []
  )

  return controller
}
