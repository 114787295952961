import { useCallback } from 'react'

export type CentralTeamsInitialIdStore = {
  set: (id: number) => void
  get: () => number | null
  clear: () => void
  has: () => boolean
}

export function useCentralTeamsInitialTeamIdStore(): CentralTeamsInitialIdStore {
  return {
    set: useCallback((id: number) => sessionStorage.setItem('team_initial_id', String(id)), []),
    get: useCallback(() => {
      const storedValue = sessionStorage.getItem('team_initial_id')
      return storedValue ? Number(storedValue) : null
    }, []),
    clear: useCallback(() => sessionStorage.removeItem('team_initial_id'), []),
    has: useCallback(() => !!sessionStorage.getItem('team_initial_id'), [])
  }
}
