import { forwardRef, ReactNode } from 'react'
import { Heading } from 'grommet'
import styled from 'styled-components/macro'

import { IconButton } from '../../button/button/button'
import { themeColor } from '../../theme/grommet-utils'
import { Box } from '../box/box'
import { LoadingPanel } from '../loading-panel/loading-panel'

const PANEL_HEADER_HEIGHT = 64
const PANEL_FOOTER_HEIGHT = 72

export const RIGHT_PANEL_SCROLL_CONTAINER_ID = 'right-panel-scroll-container'
export const RIGHT_PANEL_FOOTER_ID = 'right-panel-footer'

type RightPanelProps = {
  children?: ReactNode
  title: string | ReactNode
  onBack?: () => void
  headerItems?: ReactNode[]
  onClose?: () => void
  loading?: boolean
  footer?: ReactNode
  scrollContentRef?: any
}

export const RightPanel = forwardRef<HTMLDivElement, RightPanelProps>((props, ref) => {
  return (
    <PanelContainer ref={ref} hasFooter={!!props.footer} className="react-right-panel">
      <RightPanelHeader title={props.title} onBack={props.onBack} items={props.headerItems} onClose={props.onClose} />
      <PanelContentWrapper ref={props.scrollContentRef} hasFooter={!props.loading && !!props.footer}>
        <Box fill>{props.loading ? <LoadingPanel /> : props.children}</Box>
      </PanelContentWrapper>
      {props.footer && (
        <PanelFooterWrapper>
          <Box width="100%">{props.footer}</Box>
        </PanelFooterWrapper>
      )}
    </PanelContainer>
  )
})

type RightPanelHeaderProps = {
  title: string | ReactNode
  onBack?: () => void
  items?: ReactNode[]
  onClose?: () => void
}

const RightPanelHeader = ({ title, onBack, onClose, items }: RightPanelHeaderProps) => {
  return (
    <Box
      direction="row"
      gap="xxsmall"
      align="center"
      border={{ side: 'bottom', size: '1px', color: 'bg-2' }}
      pad={{ horizontal: '16px' }}
      height={`${PANEL_HEADER_HEIGHT}px`}
      width={{ max: '100%' }}
      justify="between"
      data-testid="right-panel-header"
    >
      <Box gap="xsmall" direction="row" align="center">
        {onBack && <IconButton label="Back" icon="arrow-back" tipPlacement="top" onClick={onBack} />}
        {typeof title === 'string' ? (
          <Heading truncate css="line-height: normal; font-size: 18px" level={3} margin="none">
            {title}
          </Heading>
        ) : (
          <>{title}</>
        )}
      </Box>
      <Box gap="xxsmall" direction="row" align="center" flex={false}>
        {items && (
          <Box direction="row" gap="xxsmall" align="center" flex={false}>
            {items.map((item, i) => (
              <Box key={i}>{item}</Box>
            ))}
          </Box>
        )}

        {onClose && (
          <IconButton data-testid="button-close" label="Close" tipPlacement="top" icon="close" onClick={onClose} />
        )}
      </Box>
    </Box>
  )
}

const PanelContainer = styled(Box)<{ hasFooter: boolean }>`
  display: block;
  background-color: ${themeColor('bg')};
  white-space: nowrap;
  height: 100%;
  position: relative;
  flex-grow: 1;
  bottom: 0;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
`

const PanelContentWrapper = styled(Box).attrs({ id: RIGHT_PANEL_SCROLL_CONTAINER_ID })<{ hasFooter?: boolean }>`
  display: block;
  position: absolute;
  top: ${PANEL_HEADER_HEIGHT}px;
  padding: 16px;
  padding-bottom: ${props => props.hasFooter && '0'};
  height: calc(100% - ${PANEL_HEADER_HEIGHT}px - ${props => (props.hasFooter ? PANEL_FOOTER_HEIGHT : '0')}px);
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
`

const PanelFooterWrapper = styled(Box).attrs({
  id: RIGHT_PANEL_FOOTER_ID,
  'data-testid': RIGHT_PANEL_FOOTER_ID,
  tag: 'footer'
})`
  z-index: 1000; // show over loading panel -- why though?
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${themeColor('bg')};
  padding: 16px;
  min-height: 72px;
`
