import { useMemo } from 'react'
import { UseFormSetValue } from 'react-hook-form'
import { pick } from 'lodash'

import { AccordionPanel } from '@cutover/react-ui'
import { UserTeamMultiSelect } from 'main/components/shared/form/user-team-select'
import { useLanguage } from 'main/services/hooks'
import { TaskEditFormType } from './task-edit-form'
import { useRunbookVersionUsers, useUsersLookupState } from 'main/recoil/data-access'
import { RunbookTeamModel } from 'main/data-access'

export const TaskEditAssignedUsersAndTeamsPanel = ({
  iconColor,
  userIds,
  runbookTeamIds,
  setValue,
  readOnly
}: {
  iconColor: string
  userIds: number[]
  runbookTeamIds: number[]
  setValue: UseFormSetValue<TaskEditFormType>
  readOnly: boolean
}) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel.fields.assignedUsersTeams' })
  const teamLookup = RunbookTeamModel.useGetLookup()
  const users = useRunbookVersionUsers()
  const usersLookup = useUsersLookupState()
  const assignedUsers = useMemo(() => userIds.map(id => usersLookup[id]), [userIds, usersLookup])
  const assignedTeams = useMemo(() => Object.values(pick(teamLookup, runbookTeamIds)), [teamLookup, runbookTeamIds])
  const possibleUsersTeams = useMemo(() => [...Object.values(teamLookup), ...users], [teamLookup, users])

  const setUsersAndTeamsValue = (value?: { userIds?: number[]; runbookTeamIds?: number[] }) => {
    if (value) {
      setValue('user_ids', value.userIds, { shouldDirty: true, shouldTouch: true })
      setValue('runbook_team_ids', value.runbookTeamIds, { shouldDirty: true, shouldTouch: true })
    }
  }

  return (
    <AccordionPanel label={t('accordion')} icon="users" iconColor={iconColor}>
      <UserTeamMultiSelect
        selectedUsers={assignedUsers}
        selectedTeams={assignedTeams}
        possibleUsersTeams={possibleUsersTeams}
        setUsersAndTeamsValue={setUsersAndTeamsValue}
        readOnly={readOnly}
        placeholder={t('placeholder')}
        label={t('label')}
      />
    </AccordionPanel>
  )
}
