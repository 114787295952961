import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Heading } from 'grommet'

import { Button, SaveEditingFormModal } from '@cutover/react-ui'
import { ActiveRunbookModel, CustomFieldModel, RunbookViewModel } from 'main/data-access'
import { DashboardComponent, DashboardComponentCollection } from 'main/components/dashboards/widgets/types'
import { CustomField, Dashboard as DashboardType, FieldValue, FieldValuesAttributes } from 'main/services/queries/types'
import { RunbookUpdatePayload, useUpdateRunbook } from 'main/services/queries/use-update-runbook'
import { useLanguage } from 'main/services/hooks'
import { useRunbookSubHeader } from '../../../runbook-sub-header/use-runbook-sub-header'
import { DashboardSubHeaderRightMenu } from './dashboard-sub-header-right-menu'

export const DashboardPageForm = ({
  components,
  dashboard,
  children
}: {
  dashboard: DashboardType
  components: DashboardComponentCollection
  children: ReactNode
}) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'subHeader' })
  const { mutateAsync: updateRunbook } = useUpdateRunbook()
  const { addSubHeaderContent, resetSubHeaderContent } = useRunbookSubHeader()
  const notify = RunbookViewModel.useAction('notify')
  const innerPageContainer = document.getElementById('layout-inner-page')

  const [isSubmitting, setIsSubmitting] = useState(false)

  const runbookId = ActiveRunbookModel.useId()
  const processRunbookUpdateResponse = ActiveRunbookModel.useOnAction('update')
  const { field_values: fieldValues } = ActiveRunbookModel.useGet()
  const customFieldsLookupCallback = CustomFieldModel.useGetLookupCallback()
  const getCustomFieldContent = (cf: CustomField, fv: FieldValue | undefined): string => {
    return fv?.value ? fv.value : cf.default_value ? cf.default_value.trim() : '<p></p>'
  }

  const defaultValues = useMemo(() => {
    return components.reduce((acc, component: DashboardComponent) => {
      const { custom_fields, type, settings } = component
      if (type !== 'content' && type !== 'runbook_summary' && type !== 'value_realisation') return acc

      // Currently only constrained to PIR
      if (type === 'runbook_summary') {
        const cfs = custom_fields.filter((field: CustomField) => field.apply_to.slug === 'pir_summary')
        cfs.map((cf: CustomField) => {
          const fv = fieldValues?.find((fv: FieldValue) => fv.custom_field_id === cf.id)
          acc[cf.id] = getCustomFieldContent(cf, fv)
        })
      } else if (type === 'value_realisation') {
        const cf = custom_fields.find((field: CustomField) => field.apply_to.slug === 'pir_value_select')
        const fv = cf && fieldValues?.find((fv: FieldValue) => fv.custom_field_id === cf.id)
        if (cf) {
          acc[cf.id] = fv?.field_option_id ? fv.field_option_id : null
        }
      } else {
        const customFieldId = settings.custom_field_id || {}
        const cf = custom_fields.find((field: CustomField) => field.id === customFieldId)
        const fv = fieldValues?.find((fv: FieldValue) => fv.custom_field_id === customFieldId)

        if (cf) {
          acc[cf.id] = getCustomFieldContent(cf, fv)
        }
      }

      return acc
    }, {})
  }, [components, fieldValues])

  const methods = useForm({
    defaultValues
  })

  const {
    getValues,
    formState: { isDirty },
    reset
  } = methods

  const handleReset = useCallback(() => {
    setIsSubmitting(false)
    reset(defaultValues)
  }, [defaultValues])

  const handleSubmit = useCallback(async (fieldValues: FieldValue[]) => {
    // it's important we use this getValues callback and don't add the values as a dependency in
    // this function for performance reasons
    const formValues = getValues()
    const customFieldsLookup = await customFieldsLookupCallback()
    setIsSubmitting(true)

    const fieldValueAttributes = []

    for (const [key, value] of Object.entries(formValues)) {
      const fv = fieldValues.find(fv => fv.custom_field_id === Number(key))
      const customFiled = customFieldsLookup[Number(key)]
      const attributes = {
        custom_field_id: Number(key),
        data_source_value_id: fv?.data_source_value_id ?? null,
        id: fv?.id ?? null
      } as FieldValuesAttributes

      if (value) {
        if (customFiled?.field_type?.slug === 'textarea') {
          attributes.value = value
        }

        if (customFiled?.field_type?.slug === 'select_menu') {
          attributes.field_option_id = Number(value)
        }
      } else {
        attributes['_destroy'] = true
      }

      fieldValueAttributes.push(attributes)
    }

    const data = {
      runbook: {
        id: runbookId,
        field_values_attributes: fieldValueAttributes
      }
    }
    updateRunbook({ ...data } as unknown as RunbookUpdatePayload, {
      onSuccess: response => {
        notify.success(t('updateSuccess'), { title: t('updateSuccessTitle') })
        processRunbookUpdateResponse(response)
        handleReset()
      },
      onError: () => {
        handleReset()
      }
    })
  }, [])

  useEffect(() => {
    if (fieldValues) {
      handleReset()
    }
  }, [fieldValues])

  useEffect(() => {
    addSubHeaderContent({
      left: (
        <Heading as="h2" css="font-weight: 700; font-size: 20px; margin: 0; max-width: 100%; line-height: 25px;">
          {dashboard.name}
        </Heading>
      ),
      right: (
        <DashboardPageSubHeaderRightContent
          isSubmitting={isSubmitting}
          isDirty={isDirty}
          onSubmit={() => handleSubmit(fieldValues)}
          onReset={handleReset}
        />
      )
    })

    return resetSubHeaderContent
  }, [isSubmitting, isDirty, dashboard.name, handleSubmit, handleReset, resetSubHeaderContent])

  return (
    <SaveEditingFormModal
      isDirty={isDirty}
      isSubmitting={isSubmitting}
      onReset={handleReset}
      onSubmit={() => handleSubmit(fieldValues)}
      ignoreWithin={innerPageContainer ?? undefined}
      render={() => {
        return <FormProvider {...methods}>{children}</FormProvider>
      }}
    />
  )
}

type DashboardPageSubHeaderRightContentProps = {
  isSubmitting: boolean
  isDirty: boolean
  onSubmit: () => void
  onReset: () => void
}

const DashboardPageSubHeaderRightContent = ({
  isSubmitting,
  isDirty,
  onSubmit,
  onReset
}: DashboardPageSubHeaderRightContentProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'subHeader' })

  return (
    <>
      {isDirty && (
        <>
          {!isSubmitting && <Button tertiary label={t('discard')} onClick={onReset} />}
          <Button primary loading={isSubmitting} label={isSubmitting ? t('saving') : t('save')} onClick={onSubmit} />
        </>
      )}
      <DashboardSubHeaderRightMenu showShareRunbookDashboardButton={false} />
    </>
  )
}
