import { memo, ReactNode } from 'react'

import { PeopleListItemLayout } from './people-list-item-layout'
import { ItemSize } from './size-config'
import { Avatar } from '../../../avatar'

export type TeamItemProps = {
  id: number
  name: string
  usersCount: number
  color?: string
  linked?: boolean
  size?: ItemSize
  subtitle?: string
  titleSuffix?: string
  disabled?: boolean
  active?: boolean
  hasError?: boolean
  onClick?: () => void
  onClickRemove?: (id: number) => void
  draggable?: boolean
  'data-testid'?: string
  endComponents?: ReactNode[]
}

export const TeamListItem = memo(
  ({
    id,
    name,
    usersCount,
    color,
    linked,
    size,
    onClick,
    onClickRemove,
    draggable = true,
    disabled,
    subtitle,
    active,
    hasError,
    titleSuffix,
    'data-testid': testId,
    endComponents
  }: TeamItemProps) => {
    return (
      <PeopleListItemLayout
        id={id}
        key={'team-' + id}
        title={name}
        subtitle={subtitle}
        titleSuffix={`(${usersCount} users) ${titleSuffix ? titleSuffix : ''}`}
        size={size}
        disabled={disabled}
        active={active}
        hasError={hasError}
        onClick={onClick}
        onClickRemove={onClickRemove}
        avatar={
          <Avatar
            subject={{ id, name, linked, color }}
            draggable={draggable}
            size={size}
            data-testid="team-item-avatar"
          />
        }
        data-testid={testId}
        endComponents={endComponents}
      />
    )
  }
)
