import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Modal, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'

export const TaskUnableToPerformActionModal = () => {
  const { t } = useLanguage('tasks')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const reloadPage = () => {
    setLoading(true)
    // Reload the page
    navigate(0)
  }
  return (
    <Modal
      title={t('unableToPerformAction')}
      open={true}
      loading={loading}
      confirmText={t('unableToPerformActionReloadText')}
      hasCancelButton={false}
      onClickConfirm={reloadPage}
    >
      <Box>
        <Text>{t('unableToPerformActionMessage')}</Text>
      </Box>
    </Modal>
  )
}
