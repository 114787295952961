import { AnchorHTMLAttributes } from 'react'
import styled from 'styled-components/macro'

import { Icon, IconName } from '../../icon'
import { LinkButton } from '../../button'
import { Text } from '../'

// TODO: call this Link once the legacy Link component is removed
const TextLinkWrap = styled.a.attrs(props => ({
  rel: props.target === '_blank' ? 'noopener noreferrer' : undefined
}))<AnchorHTMLAttributes<HTMLAnchorElement> & { inline?: boolean }>`
  display: ${props => (props.inline ? 'inline' : 'inline-flex')};
  align-items: center;
  flex-shrink: 1;
  overflow: hidden;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

type TextLinkProps = {
  label: string
  href?: string
  onClick?: (e: React.MouseEvent) => void
  small?: boolean
  icon?: IconName
  openInNew?: boolean
  active?: boolean
  inline?: boolean
  'data-testid'?: string
  highlight?: boolean
}

export const TextLink = ({
  label,
  href,
  small,
  icon,
  openInNew,
  onClick,
  active,
  inline,
  highlight = true,
  'data-testid': dataTestId
}: TextLinkProps) => {
  return !!href ? (
    <TextLinkWrap
      href={href}
      target={openInNew ? '_blank' : undefined}
      inline={inline}
      data-testid={dataTestId}
      onClick={onClick}
    >
      {icon && <Icon icon={icon as IconName} size="medium" color="primary" css="padding-right: 4px;" />}
      <Text truncate="tip" size={small ? '13px' : '15px'} color="primary">
        {label}
      </Text>
    </TextLinkWrap>
  ) : (
    <LinkButton
      label={label}
      active={active}
      data-testid={dataTestId}
      size={small ? 'small' : 'medium'}
      onClick={e => {
        e.preventDefault()
        onClick?.(e)
      }}
      highlight={highlight}
    />
  )
}
