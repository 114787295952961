import { memo, MouseEvent as ReactMouseEvent, SyntheticEvent, useMemo } from 'react'
import styled from 'styled-components'

import { Box, Button, Checkbox, IconButton, Menu, MenuListItem, Text, Tooltip } from '@cutover/react-ui'
import { useFilter, useSetFilterParams } from 'main/components/shared/filter/filter-provider'
import { useLanguage } from 'main/services/hooks'
import { RunbookListRunbook } from 'main/services/queries/types'

export type SortOption = 'last-updated' | 'name-asc' | 'name-desc' | 'recently-viewed'

type ActionHeaderProps = {
  selectedRunbooksCount: number
  isSelectMode: boolean
  onClickSelectOrDeselectAll: () => void
  onClickMerge: () => void
  onClickDuplicate?: (
    event:
      | ReactMouseEvent<HTMLAnchorElement, MouseEvent>
      | ReactMouseEvent<HTMLButtonElement, MouseEvent>
      | KeyboardEvent,
    runbook?: RunbookListRunbook
  ) => void
  onClickEdit: () => void
  onClickArchive: (
    event:
      | ReactMouseEvent<HTMLAnchorElement, MouseEvent>
      | ReactMouseEvent<HTMLButtonElement, MouseEvent>
      | KeyboardEvent,
    runbook?: RunbookListRunbook,
    runbookIds?: number[]
  ) => void
  allRunbooksSelected?: boolean
  disableMergeAction: boolean
}

type SortParams = {
  sort_by: string
  sort_dir: 'desc' | 'asc'
}

const SORT_PARAMS_MAPPING: Record<SortOption, SortParams> = {
  'last-updated': { sort_by: 'updated_at', sort_dir: 'desc' },
  'name-asc': { sort_by: 'name', sort_dir: 'asc' },
  'name-desc': { sort_by: 'name', sort_dir: 'desc' },
  'recently-viewed': { sort_by: 'resource_views.updated_at', sort_dir: 'desc' }
}

// NOTE: could be a good candidate for making reusable component
export const RunbooksListActionHeader = memo(
  ({
    selectedRunbooksCount,
    isSelectMode = false,
    onClickSelectOrDeselectAll,
    onClickMerge,
    onClickDuplicate,
    onClickEdit,
    onClickArchive,
    disableMergeAction,
    allRunbooksSelected
  }: ActionHeaderProps) => {
    const setFilerParams = useSetFilterParams()
    const sortBy = useFilter<string>('sort_by')
    const sortDir = useFilter<string>('sort_dir')
    const { t } = useLanguage('runbooks')

    const sortOptionLabelMapping: Record<SortOption, string> = useMemo(
      () => ({
        'last-updated': t('list.header.sort.lastUpdated'),
        'name-asc': t('list.header.sort.nameAZ'),
        'name-desc': t('list.header.sort.nameZA'),
        'recently-viewed': t('list.header.sort.recentlyViewed')
      }),
      [t]
    )

    const handleOnClick = (e: SyntheticEvent) => {
      e.stopPropagation()
      onClickSelectOrDeselectAll()
    }

    const handleSortMenuItemClick = (sortOption: SortOption) => {
      setFilerParams(SORT_PARAMS_MAPPING[sortOption])
    }

    const selectedSort: SortOption | undefined = useMemo(() => {
      if ((sortBy === null && sortDir === null) || (sortBy === 'updated_at' && sortDir === 'desc')) {
        return 'last-updated'
      } else if (sortBy === 'name' && sortDir === 'asc') {
        return 'name-asc'
      } else if (sortBy === 'name' && sortDir === 'desc') {
        return 'name-desc'
      } else if (sortBy === 'resource_views.updated_at' && sortDir === 'desc') {
        return 'recently-viewed'
      }
    }, [sortBy, sortDir])

    const sortMenuItems = Object.keys(sortOptionLabelMapping).map(item => ({
      label: sortOptionLabelMapping[item as SortOption],
      onClick: () => handleSortMenuItemClick(item as SortOption),
      active: selectedSort === item
    }))

    return (
      <Box direction="row" flex={false} align="center" justify="between" height={{ min: '64px' }}>
        <Box direction="row">
          <Box
            align="center"
            justify="center"
            height="40px"
            width={{ width: '40px', min: '40px' }}
            margin={{ left: '8px' }}
          >
            <Tooltip
              placement="top"
              content={
                isSelectMode
                  ? t('bulkActionTooltip.deselect', { postProcess: 'replaceShortcut' })
                  : t('bulkActionTooltip.select', { postProcess: 'replaceShortcut' })
              }
            >
              <Checkbox
                aria-label="Select all"
                width={16}
                checked={isSelectMode && allRunbooksSelected}
                indeterminate={isSelectMode && !allRunbooksSelected}
                onChange={handleOnClick}
                onClick={e => e.stopPropagation()}
              />
            </Tooltip>
          </Box>
          {isSelectMode ? (
            <Box direction="row" gap="xsmall">
              {!disableMergeAction && (
                <IconButton
                  tertiary
                  label={t('bulkActionTooltip.merge', { postProcess: 'replaceShortcut' })}
                  icon="merge"
                  onClick={onClickMerge}
                  tipPlacement="top"
                />
              )}
              {onClickDuplicate && (
                <IconButton
                  tertiary
                  label={t('bulkActionTooltip.duplicate', { postProcess: 'replaceShortcut' })}
                  icon="copy"
                  onClick={e => onClickDuplicate(e)}
                  tipPlacement="top"
                />
              )}
              <IconButton
                tertiary
                label={t('bulkActionTooltip.edit', { postProcess: 'replaceShortcut' })}
                icon="edit"
                onClick={onClickEdit}
                tipPlacement="top"
              />
              <IconButton
                tertiary
                label={t('bulkActionTooltip.archive', { postProcess: 'replaceShortcut' })}
                icon="archive"
                onClick={e => onClickArchive(e)}
                tipPlacement="top"
              />
              <SingleLineText>{`${selectedRunbooksCount} ${t('list.header.selected')}`}</SingleLineText>
            </Box>
          ) : (
            <SingleLineText margin={{ left: '6px' }}>{t('list.header.checkboxLabel')}</SingleLineText>
          )}
        </Box>
        {!isSelectMode && (
          <Menu
            trigger={
              <Button
                tertiary
                label={`${t('list.header.dropdownLabel')}: ${selectedSort ? sortOptionLabelMapping[selectedSort] : ''}`}
                icon="switch-vertical"
              />
            }
          >
            {sortMenuItems.map(item => (
              <MenuListItem label={item.label} key={item.label} selected={item.active} onClick={item.onClick} />
            ))}
          </Menu>
        )}
      </Box>
    )
  }
)

const SingleLineText = styled(Text).attrs(() => ({
  alignSelf: 'center',
  color: 'text-light'
}))`
  overflow: hidden;
  white-space: nowrap;
`
