import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Box, Card, CardProps, Checkbox, FeatureIcon, Grid, Message, Pill, TextLink } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import {
  CustomFieldsGroupsForm,
  CustomFieldsGroupsFormProps
} from 'main/components/shared/custom-field-form/custom-field-groups-form'
import {
  CheckboxFieldControlled,
  DateTimePickerField,
  FileInputField,
  FolderSelectField,
  RadioboxGroupField,
  RunbookSelectField,
  TextInputField
} from 'main/components/shared/form'
import { PermittedProject } from 'main/services/queries/use-permitted-resources'
import { CreateRunbookFormType } from './create-runbook-schema'
import { ConfigModel } from 'main/data-access'
import { useAccountRunbookTypes } from 'main/services/api/data-providers/account/account-data'

type CreateRunbookDetailsProps = CustomFieldsGroupsFormProps & {
  folders: PermittedProject[] | undefined
  isTemplate?: boolean
  templatesExist?: boolean
  setIsCreateFromTemplate: Dispatch<SetStateAction<boolean>>
}

type CreateFromType = 'template' | 'blank' | 'ai'

export const CreateRunbookDetails = ({
  isTemplate = false,
  folders,
  templatesExist,
  setIsCreateFromTemplate,
  ...customFieldsProps
}: CreateRunbookDetailsProps) => {
  const { t: tBase } = useLanguage('runbooks', { keyPrefix: 'newCreateRunbookModal.enterDetails' })
  const { t } = useLanguage('runbooks', { keyPrefix: 'newCreateRunbookModal.enterDetails.attributes' })
  const {
    watch,
    getValues,
    setValue,
    setFocus,
    reset,
    formState: { errors }
  } = useFormContext<CreateRunbookFormType>()

  const { accountId: accountSlug } = useParams()

  const runbookTypeId = getValues('runbook.runbook_type_id')
  const { runbookTypeLookup } = useAccountRunbookTypes()
  const runbookType = runbookTypeLookup?.[runbookTypeId]

  const [isStartScheduled, setIsStartScheduled] = useState<boolean>(false)
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)
  const [createFromType, setCreateFromType] = useState<CreateFromType>(templatesExist ? 'template' : 'blank')
  const [showOnlyApprovedTemplates, setShowOnlyApprovedTemplatesFlag] = useState(true)
  const aiFeaturesEnabled = ConfigModel.useIsFeatureEnabled('ai_features')

  const aiSupportingOptions: { label: string; value: string }[] = [
    { label: t('aiFields.none'), value: 'none' },
    { label: t('aiFields.textEntry'), value: 'text' },
    { label: t('aiFields.fileUpload'), value: 'file' }
  ]

  const currentAiSupportingOption = watch('ai_config.supporting_ai_options')
  const baseTemplate = watch('base_template')
  const isTemplateNotApproved = baseTemplate && baseTemplate.template_status !== 'template_approved'
  const lockFolderSelect = baseTemplate?.settings_lock_template_copies_to_folder

  // TODO: when there are more then two cards (if the AI create card is shown), the subtitle may be truncated.
  // We need a way to either show a tooltip on the subtitle or the <Card itself.
  // currently <Card does not support tooltips
  const cardOptions: CardProps[] = [
    {
      title: t('createOptions.createFromTemplate.title'),
      subtitle: templatesExist
        ? t('createOptions.createFromTemplate.subTitle')
        : t('createOptions.createFromTemplate.noneAvailable'),
      startComponent: (
        <FeatureIcon size="large" icon="template" status={createFromType === 'template' ? 'idea' : undefined} />
      ),
      selected: createFromType === 'template',
      key: 'template',
      'data-testid': 'create-runbook-from-template-card',
      disabled: !templatesExist
    },
    {
      title: isTemplate ? t('createOptions.createBlank.template.title') : t('createOptions.createBlank.runbook.title'),
      subtitle: isTemplate
        ? t('createOptions.createBlank.template.subTitle')
        : t('createOptions.createBlank.runbook.subTitle'),
      startComponent: (
        <FeatureIcon size="large" icon="runbook-created" status={createFromType === 'blank' ? 'idea' : undefined} />
      ),
      selected: createFromType === 'blank',
      key: 'blank',
      'data-testid': 'create-blank-runbook-card'
    }
  ]

  const createWithAiCard: CardProps = {
    title: t('createOptions.createBlank.withAi.title'),
    subtitle: t('createOptions.createBlank.withAi.subTitle'),
    startComponent: <FeatureIcon size="large" icon="rocket" status={createFromType === 'ai' ? 'idea' : undefined} />,
    endComponents: [<Pill label="New" color="feature-pink" />],
    selected: createFromType === 'ai',
    key: 'ai',
    'data-testid': 'create-runbook-with-ai-card'
  }

  if (aiFeaturesEnabled && runbookType?.ai_create_enabled) {
    cardOptions.push(createWithAiCard)
  }

  // Ensure correct fields are focused
  useEffect(() => {
    const projectId = baseTemplate?.project?.id

    setValue('runbook.name', baseTemplate ? `${baseTemplate.name} copy` : '')
    setFocus('runbook.name')

    projectId ? setValue('runbook.project_id', projectId) : undefined
  }, [baseTemplate])

  // Show or hide additional fields based on start scheduled
  useEffect(() => {
    if (!!getValues('runbook.runbook_versions_attributes.0.start_scheduled')) {
      setIsStartScheduled(true)
    } else {
      setIsStartScheduled(false)
    }
  }, [watch('runbook.runbook_versions_attributes.0.start_scheduled')])

  // Reset the form when switching cards
  useEffect(() => {
    const runbookTypeId = getValues('runbook.runbook_type_id')
    setShowAdvancedOptions(false)
    reset()
    setValue('runbook.runbook_type_id', runbookTypeId)
    setValue('_step', 2)
    setIsCreateFromTemplate(createFromType === 'template')
  }, [createFromType])

  useEffect(() => {
    const defaultFolder = folders?.find(folder => folder.name === 'Default')
    setValue('runbook.project_id', defaultFolder ? defaultFolder.id : folders?.[0]?.id)
  }, [folders])

  return (
    <Box>
      {isTemplateNotApproved && <Message role="alert" type="warning" message={tBase('unapprovedTemplateWarning')} />}
      <Grid minWidth={120}>
        {cardOptions.map(option => (
          <Card
            onClick={() => {
              setCreateFromType(option.key as CreateFromType)
            }}
            {...option}
          />
        ))}
      </Grid>

      {createFromType === 'template' && accountSlug && (
        <RunbookSelectField<CreateRunbookFormType>
          name="base_template"
          inlineError={false}
          label={t('template.label')}
          accountSlug={accountSlug}
          data-testid="base-template-form-field"
          mode="template"
          emptyMessage={showOnlyApprovedTemplates ? tBase('noApprovedTemplates') : tBase('noTemplates')}
          params={{
            limit: 10,
            template_type: 'default',
            type: [runbookTypeId],
            fields: [
              'id',
              'name',
              'project',
              'copies_count',
              'template_status',
              'settings_lock_template_copies_to_folder'
            ],
            ts: showOnlyApprovedTemplates ? ['template_approved'] : []
          }}
          key={showOnlyApprovedTemplates.toString()}
        />
      )}

      <TextInputField<CreateRunbookFormType>
        name="runbook.name"
        label={t('name')}
        required
        data-testid="runbook-name-form-field"
      />

      {createFromType === 'ai' && (
        <>
          <TextInputField<CreateRunbookFormType> name="ai_config.purpose" label={t('purpose.label')} />

          <RadioboxGroupField<CreateRunbookFormType>
            name="ai_config.supporting_ai_options"
            label={t('aiFields.label')}
            defaultValue="none"
            direction="row"
            options={aiSupportingOptions}
          />

          {currentAiSupportingOption === 'text' && (
            <TextInputField<CreateRunbookFormType>
              name="ai_config.supporting_ai_text"
              label={t('aiFields.supportingText')}
              data-testid="runbook-name-form-field"
            />
          )}

          {currentAiSupportingOption === 'file' && (
            <FileInputField<CreateRunbookFormType>
              name="ai_config.supporting_ai_file"
              accept="text/csv"
              data-testid="supporting-ai-info-file-field"
            />
          )}
        </>
      )}

      <FolderSelectField<CreateRunbookFormType>
        name="runbook.project_id"
        label={t('folderSelect')}
        folders={folders ?? []}
        clearable={false}
        loading={folders === undefined}
        disabled={lockFolderSelect}
      />

      {!isTemplate && (
        <>
          <DateTimePickerField<CreateRunbookFormType>
            name="runbook.runbook_versions_attributes.0.start_scheduled"
            label={t('startScheduled')}
            fixed
            fixedWidth
          />

          {isStartScheduled && (
            <>
              <DateTimePickerField<CreateRunbookFormType>
                name="runbook.runbook_versions_attributes.0.end_scheduled"
                label={t('endScheduled')}
                fixed
                fixedWidth
              />

              {createFromType === 'template' && (
                <CheckboxFieldControlled<CreateRunbookFormType>
                  name="shift_time"
                  label={t('shiftTime.label')}
                  helpText={t('shiftTime.helpText')}
                />
              )}
            </>
          )}
        </>
      )}

      <CustomFieldsGroupsForm {...customFieldsProps} errors={errors} />

      {showAdvancedOptions && (
        <Checkbox
          onChange={() => setShowOnlyApprovedTemplatesFlag(!showOnlyApprovedTemplates)}
          checked={showOnlyApprovedTemplates}
          label={tBase('onlyDisplayApprovedTemplates')}
          css="margin-top: 10px"
        />
      )}

      {createFromType === 'template' && (
        <Box css="margin: 8px 0 32px;">
          <TextLink
            label={showAdvancedOptions ? tBase('hideAdvancedOptions') : tBase('showAdvancedOptions')}
            onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
            inline
            highlight={false}
          />
        </Box>
      )}
    </Box>
  )
}
