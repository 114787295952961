import { useDeferredValue, useEffect, useState } from 'react'
import { useEffectOnce, useUnmount } from 'react-use'

import { useRightPanelTypeValue, useToggleRightPanel } from 'main/components/layout/right-panel'
import { useCentralTeamsInitialTeamIdStore } from './use-central-teams-initial-team-id-store'

type UseCentralTeamsInitialTeamProps = {
  store?: ReturnType<typeof useCentralTeamsInitialTeamIdStore> // custom store - useful for testing
}

export function useCentralTeamsInitialTeam({ store: customIdStore }: UseCentralTeamsInitialTeamProps = {}) {
  const defaultIdStore = useCentralTeamsInitialTeamIdStore()
  const idStore = customIdStore || defaultIdStore
  const togglEditPanel = useToggleRightPanel('central-team-edit')
  const { get: getInitialTeamIdFromStore, clear: clearInitialTeamIdStore } = idStore
  const [selectedTeamId, setSelectedTeamId] = useState<number | null>(null)
  const deferredSelectedTeamId = useDeferredValue(selectedTeamId)
  const { teamId: editingTeamId } = useRightPanelTypeValue('central-team-edit')

  // sync the external store to local state
  useEffectOnce(() => {
    const selectedId = getInitialTeamIdFromStore()
    if (selectedId) setSelectedTeamId(Number(selectedId))
  })

  // use the deferred value to auto-open the edit panel, so the rest of the page has
  // already rendered
  useEffect(() => {
    if (deferredSelectedTeamId) {
      togglEditPanel({ teamId: Number(deferredSelectedTeamId) })
    }
  }, [deferredSelectedTeamId])

  // once the edit panel is open, clear the store
  useEffect(() => {
    if (editingTeamId) {
      setSelectedTeamId(null)
      clearInitialTeamIdStore() // sync external store
    }
  }, [editingTeamId, clearInitialTeamIdStore])

  // only here as a failsafe in case the edit panel doesn't open for some reason.
  useUnmount(clearInitialTeamIdStore)
}
