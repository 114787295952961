import { MutateOptions, useMutation } from 'react-query'

import {
  RunbookCommentCreateResponse,
  RunbookCommentsGetResponse,
  RunbookCommentToggleFeaturedResponse
} from '../api/data-providers/runbook-types'
import { queryClient as queryClientStatic } from 'main/query-client'
import { apiClient } from 'main/services/api/api-client'

type CommentCreatePayload = {
  comment: {
    content: string
    task_id?: number
    attachment?: File
  }
}

export type CreateCommentProps = {
  comment: string
  file?: File
  ident?: string
  taskId?: number
}

export type CreateCommentMutateOptions = MutateOptions<
  RunbookCommentCreateResponse | null | undefined,
  unknown,
  CreateCommentProps,
  unknown
>

export function useRunbookCommentCreate({
  runbookId,
  runbookVersionId = 'current_version',
  taskId: initTaskId
}: {
  runbookId: number | string
  runbookVersionId?: number | string
  taskId?: number
}) {
  return useMutation(async ({ comment, file, taskId }: CreateCommentProps) => {
    const payload = buildCommentPayload(comment, file, taskId ?? initTaskId)
    const contentType = file ? 'multipart/form-data' : 'application/json'

    const { data } = await apiClient.post<CommentCreatePayload | FormData, RunbookCommentCreateResponse>({
      url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/comments`,
      headers: {
        'Content-Type': contentType
      },
      data: payload
    })

    return data
  })
}

export const getRunbookComments = async ({
  runbookId,
  runbookVersionId
}: {
  runbookId: number
  runbookVersionId: number
}) => {
  const resp = await queryClientStatic.fetchQuery({
    queryKey: ['runbook-comments', String(runbookId), String(runbookVersionId)],
    queryFn: async () => {
      const { data } = await apiClient.get<RunbookCommentsGetResponse>({
        url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/comments?include_all=true`
      })

      return data
    }
  })

  return resp
}

export const toggleCommentFeatured = async ({
  runbookId,
  runbookVersionId,
  commentId
}: {
  runbookId: number
  runbookVersionId: number
  commentId: number
}) => {
  const { data } = await apiClient.patch<{}, RunbookCommentToggleFeaturedResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/comments/${commentId}/toggle_featured`
  })

  return data
}

function buildCommentPayload(comment: string, file?: File, taskId?: number) {
  if (file) {
    const formData = new FormData()
    !!comment ? formData.append('comment[content]', comment) : null
    formData.append('comment[attachments][]', file)
    return formData
  }

  return {
    comment: {
      content: comment,
      task_id: taskId ?? undefined
    }
  }
}
