import { useCallback, useEffect } from 'react'
import { useRecoilCallback, useRecoilStateLoadable, useRecoilValue, useResetRecoilState } from 'recoil'
import { produce } from 'immer'
import { extend } from 'lodash'

import {
  runbookCommentsLookup,
  runbookCommentsRequest_INTERNAL,
  runbookCommentsResponseState_INTERNAL,
  taskCommentsLookupState
} from 'main/recoil/runbook/models/runbook/runbook-comments'
import { runbookCommentsPermissions } from 'main/recoil/runbook'
import {
  RunbookCommentCreateResponse,
  RunbookCommentsPermissionsResponse,
  RunbookCommentToggleFeaturedResponse,
  RunbookResponse
} from 'main/services/api/data-providers/runbook-types'
import { RunbookComment } from 'main/services/api/data-providers/runbook-types/runbook-shared-types'
import { CommentModelType } from 'main/data-access/models'
import { useEnsureStableArgs } from 'main/data-access/models/model-utils'
import { updateAddNewComments } from './shared-updates'

/* -------------------------------------------------------------------------- */
/*                                    Get                                     */
/* -------------------------------------------------------------------------- */

export const useGetComment: CommentModelType['useGet'] = (id: number) => {
  const lookup = useGetCommentsLookup()

  return lookup[id]
}

export const useGetCommentCallback: CommentModelType['useGetCallback'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async (id: number) => {
        const lookup = await snapshot.getPromise(runbookCommentsLookup)
        return lookup?.[id]
      },
    []
  )
}

/* -------------------------------------------------------------------------- */
/*                                  Get All                                   */
/* -------------------------------------------------------------------------- */

// TODO: needs to be converted to single object arg
export const useGetAllComments: CommentModelType['useGetAll'] = (opts?: { scope?: 'featured' } | undefined) => {
  useEnsureStableArgs(opts)

  const commentsResponse = useRecoilValue(runbookCommentsRequest_INTERNAL)
  const comments = commentsResponse?.comments ?? []

  if (opts?.scope === 'featured') {
    return comments?.filter(comment => comment.featured) ?? []
  }

  return comments
}

export const useGetAllCommentsCallback: CommentModelType['useGetAllCallback'] = (opts = {}) => {
  const { scope } = opts

  return useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const commentsResponse = await snapshot.getPromise(runbookCommentsRequest_INTERNAL)
        const comments = commentsResponse?.comments ?? []
        if (scope === 'featured') {
          return comments?.filter(comment => comment.featured) ?? []
        }
        return comments
      },
    [scope]
  )
}

/* -------------------------------------------------------------------------- */
/*                                  Get All By                                */
/* -------------------------------------------------------------------------- */

// TODO: needs to be converted to single object arg
export const useGetAllCommentsBy: CommentModelType['useGetAllBy'] = (getAllBy, opts) => {
  useEnsureStableArgs(getAllBy)
  useEnsureStableArgs(opts)

  const taskCommentsLookup = useRecoilValue(taskCommentsLookupState)

  return taskCommentsReturn(taskCommentsLookup, getAllBy.taskInternalId, opts)
}

export const useGetAllCommentsByCallback: CommentModelType['useGetAllByCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async (getAllBy, opts) => {
        const taskCommentsLookup = await snapshot.getPromise(taskCommentsLookupState)

        return taskCommentsReturn(taskCommentsLookup, getAllBy.taskInternalId, opts)
      },
    []
  )

/* -------------------------------------------------------------------------- */
/*                              Get All Loadable                              */
/* -------------------------------------------------------------------------- */

// FIXME: isn't part of the pattern to do side effects in these hooks.
export const useGetAllCommentsLoadable: CommentModelType['useGetAllLoadable'] = opts => {
  useEnsureStableArgs(opts)

  const [commentsRequestLoadable, setCommentsData] = useRecoilStateLoadable(runbookCommentsRequest_INTERNAL)
  const hasComments = commentsRequestLoadable.state === 'hasValue'

  useEffect(() => {
    if (hasComments) {
      setCommentsData(commentsRequestLoadable.contents)
    }
    // TODO: can this dependency array be updated to include missing values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasComments])

  return commentsRequestLoadable.map(response => {
    const comments = response.comments
    return opts?.scope === 'featured' ? comments.filter(comment => comment.featured) : comments
  })
}

// FIXME: isn't part of the pattern to do side effects in these hooks.
export const useGetAllCommentsLoadableCallback: CommentModelType['useGetAllLoadableCallback'] = () => {
  const [commentsRequestLoadable, setCommentsData] = useRecoilStateLoadable(runbookCommentsRequest_INTERNAL)
  const hasComments = commentsRequestLoadable.state === 'hasValue'

  useEffect(() => {
    if (hasComments) {
      setCommentsData(commentsRequestLoadable.contents)
    }
    // TODO: can this dependency array be updated to include missing values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasComments])

  return useCallback(
    opts =>
      commentsRequestLoadable.map(response => {
        const comments = response.comments
        return opts?.scope === 'featured' ? comments.filter(comment => comment.featured) : comments
      }),
    [commentsRequestLoadable]
  )
}

export const useGetAllCommentsLoadableBy: CommentModelType['useGetAllLoadableBy'] = getAllBy => {
  useEnsureStableArgs(getAllBy)

  const loadable = useGetAllCommentsLoadable()

  return loadable.map(comments => comments.filter(comment => comment.task_internal_id === getAllBy.taskInternalId))
}

export const useGetAllCommentsLoadableByCallback: CommentModelType['useGetAllLoadableByCallback'] = () => {
  const loadable = useGetAllCommentsLoadable()

  return useCallback(
    getAllBy =>
      loadable.map(comments => comments.filter(comment => comment.task_internal_id === getAllBy.taskInternalId)),
    [loadable]
  )
}

/* -------------------------------------------------------------------------- */
/*                                   Actions                                  */
/* -------------------------------------------------------------------------- */

export const useOnActionComment = () => {
  const processRunbookCommentCreateResponse = useProcessRunbookCommentCreateResponse()
  const processRunbookCommentFeaturedResponse = useProcessRunbookCommentFeaturedResponse()

  return useCallback(
    (response: RunbookResponse) => {
      switch (response.meta.headers.request_method) {
        case 'create':
          processRunbookCommentCreateResponse(response as RunbookCommentCreateResponse)
          break
        case 'toggle_featured':
          processRunbookCommentFeaturedResponse(response as RunbookCommentToggleFeaturedResponse)
          break
        default:
          return
      }
    },
    [processRunbookCommentCreateResponse, processRunbookCommentFeaturedResponse]
  )
}

export const useProcessRunbookCommentCreateResponse = () =>
  useRecoilCallback(
    callbackInterface => (response: RunbookCommentCreateResponse) => {
      updateAddNewComments(callbackInterface)({
        comments: [response.comment],
        requesterId: response.meta.headers.request_user_id
      })
    },
    []
  )

export const useProcessRunbookCommentFeaturedResponse = () =>
  useRecoilCallback(
    ({ set }) =>
      async (response: RunbookCommentToggleFeaturedResponse) => {
        set(runbookCommentsResponseState_INTERNAL, previousState =>
          produce(previousState, draft => {
            // If we dont' have comments loaded yet, they are not looking at a UI that requires that data so we don't
            // need to make any internal updates. When they open the comments panel or visit the dashboard, it will populate
            // the runbook comments store.
            if (draft) {
              const index = draft.comments.findIndex(comment => comment.id === response.comment.id)

              if (index !== -1) {
                extend(draft.comments[index], response.comment)
              }
            }
          })
        )
      },
    []
  )

/* -------------------------------------------------------------------------- */
/*                                    Lookup                                  */
/* -------------------------------------------------------------------------- */

export const useGetCommentsLookup: CommentModelType['useGetLookup'] = () => {
  return useRecoilValue(runbookCommentsLookup)
}

export const useGetCommentsLookupCallback: CommentModelType['useGetLookupCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getPromise(runbookCommentsLookup),
    []
  )

/* -------------------------------------------------------------------------- */
/*                                   Reload                                   */
/* -------------------------------------------------------------------------- */

export const useReloadComments: CommentModelType['useReload'] = () => {
  const resetComments = useResetRecoilState(runbookCommentsRequest_INTERNAL)

  return useCallback(async () => resetComments(), [resetComments])
}

export const useReloadCommentsSync: CommentModelType['useReloadSync'] = () =>
  useResetRecoilState(runbookCommentsRequest_INTERNAL)

/* -------------------------------------------------------------------------- */
/*                                     Can                                    */
/* -------------------------------------------------------------------------- */

export const useGetCommentsPermission: CommentModelType['useCan'] = <
  TKey extends keyof RunbookCommentsPermissionsResponse
>(
  attribute: TKey
) => useRecoilValue(runbookCommentsPermissions({ attribute }))

/* -------------------------------- Internal -------------------------------- */

const taskCommentsReturn = (
  taskCommentsLookup: Record<number, RunbookComment[]>,
  taskInternalId: number,
  scope?: { scope?: 'featured' }
) => {
  const taskComments = taskCommentsLookup[taskInternalId] ?? []

  if (scope?.scope === 'featured') {
    return taskComments?.filter(comment => comment.featured) ?? []
  }

  return taskComments
}
