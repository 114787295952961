import { DragEvent, useEffect, useRef, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import styled from 'styled-components'

import {
  IconButton,
  ItemCreateInput,
  LoadingPanel,
  TeamListItem,
  transition,
  UserListItem,
  UserListItemProps
} from '@cutover/react-ui'
import { TeamUnlinkModal } from './team-unlink-modal'
import { ErrorItem, FormValues, SelectedTeam, SelectedUser, SelectedUserTeam } from './types'
import { useTeamQuery } from 'main/components/runbook/right-panels/people-panel/use-team'
import { useLanguage } from 'main/services/hooks'
import { useCollapseContent } from 'Shared/Components/Stateless/accordion/use-collapse-content'
import { useValidation } from './use-validation'
import { ValidationRunbookTeam } from '../../right-panels/people-panel/types'
import { RunbookViewModel } from 'main/data-access'

const collapseTransition = transition(undefined, 'height')

type TeamFormItemProps = {
  removeItem: (index: number) => void
  item: SelectedTeam
  methods: UseFormReturn<FormValues>
  index: number
  errorList: ErrorItem[]
  selectedItems: SelectedUserTeam[]
  runbookTeams: ValidationRunbookTeam[]
  removeErrorItem: (id: number, type: 'user' | 'team') => void
}

export const TeamFormItem = ({
  item,
  methods,
  removeItem,
  index,
  errorList,
  selectedItems,
  runbookTeams,
  removeErrorItem
}: TeamFormItemProps) => {
  const [isApiCallEnabled, setIsApiCallEnabled] = useState<boolean>(false)
  const [usersCount, setUsersCount] = useState<number>(item.usersCount || 0)
  const [isUsersCountChanged, setIsUsersCountChanged] = useState<boolean>(false)
  const [teamUsers, setTeamUsers] = useState<any>(null)
  const [isRenameOpen, setIsRenameOpen] = useState<boolean>(false)

  const [isUnlinking, setIsUnlinking] = useState<boolean>(false)

  const userListRef = useRef<HTMLDivElement>(null)
  const { handleClick, accordionState } = useCollapseContent(userListRef, true)
  const { translate } = useLanguage()
  const notify = RunbookViewModel.useAction('notify')

  const { register } = methods

  // Make team API call, only after a click to expand the list of team users is done.
  // isApiCallEnabled is used to make this api call, since hooks don't work within if/else
  const { data, isLoading: isTeamLoading } = useTeamQuery({ id: item.id, isEnabled: isApiCallEnabled })
  const { validateTeamName, displayWarning } = useValidation()

  const unlinkTeam = () => {
    // Use setValue to exclude component unmount/mount that happens when using update from useFieldArray
    methods.setValue(`usersTeamsSelect.${index}.linked`, false)
  }

  const teamName = methods.watch(`usersTeamsSelect.${index}.name`)
  const teamLinked = methods.watch(`usersTeamsSelect.${index}.linked`)

  const isInvalid = !!errorList.find(
    errorItem => errorItem.itemId === item.id && errorItem.itemName === teamName && errorItem.itemType === item.type
  )

  useEffect(() => {
    // Get users from the API and set into fields
    if (data?.users && teamUsers === null) {
      setTeamUsers(data?.users)
    }
  }, [data?.users])

  useEffect(() => {
    // Open user list after API call is started
    if (isApiCallEnabled) {
      handleClick()
    }
  }, [isApiCallEnabled])

  useEffect(() => {
    // Updates users from of a custom team
    const values = teamUsers && teamUsers.map((field: { id: number }) => field.id)
    methods.setValue(`usersTeamsSelect.${index}.userIds`, values)
  }, [teamUsers])

  useEffect(() => {
    // If removed or added users, get user const from fields
    if (isUsersCountChanged) {
      setUsersCount(teamUsers.length)
    }
  }, [teamUsers, isUsersCountChanged])

  const onDragOver = (event: DragEvent<HTMLLIElement>) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const addUserItem = (item: SelectedUser) => {
    const index = teamUsers ? teamUsers.findIndex((teamUser: SelectedUser) => item.name < teamUser.name) : 0
    if (index === 0 && teamUsers === null) {
      // Add first item
      setTeamUsers([item])
    } else {
      const itemIndex = index === -1 ? teamUsers.length : index
      // Insert item in aphabetical order
      setTeamUsers([...teamUsers.slice(0, itemIndex), item, ...teamUsers.slice(itemIndex)])
    }
    if (!isUsersCountChanged) {
      setIsUsersCountChanged(true)
    }
  }

  const removeUserItem = (index: number) => {
    setTeamUsers([...teamUsers.slice(0, index), ...teamUsers.slice(index + 1)])
    if (!isUsersCountChanged) {
      setIsUsersCountChanged(true)
    }
  }

  const onDrop = (event: DragEvent<HTMLLIElement>) => {
    event.preventDefault()
    const droppedItem = event.dataTransfer && JSON.parse(event.dataTransfer.getData('text'))
    const newUser = droppedItem?.user
    const userIndex = droppedItem?.index

    // Restrict adding users to linked team
    if (teamLinked) {
      notify.warning(translate('runbook:addUsersTeams:notification:warning:notification:readOnlyLinkedTeam'), {
        title: translate('runbook:addUsersTeams:notification:warning:titleInvalid')
      })
    } // Check if this user wasn't added already
    else if (
      newUser &&
      (!teamUsers || !teamUsers.some((existingUser: SelectedUser) => existingUser.id === newUser.id))
    ) {
      addUserItem(newUser)
      removeItem(userIndex)
    } else {
      notify.warning(translate('runbook:addUsersTeams:notification:warning:notification:userOnTeam'), {
        title: translate('runbook:addUsersTeams:notification:warning:titleInvalid')
      })
    }
  }

  const onTeamItemClick = () => {
    // If list already populated with users
    if (teamUsers?.length > 0 && usersCount > 0) {
      // if (fields.length > 0 && usersCount > 0) {
      handleClick()
      // If team is linked of copied we need to get users from the API
    } else if (item.id && usersCount > 0) {
      setIsApiCallEnabled(true)
    }
  }

  const handleRename = (newTeamName: string) => {
    if (
      !displayWarning(
        validateTeamName({
          item: { id: item.id, name: newTeamName, type: 'team', linked: false },
          currentTeamName: teamName,
          selectedItems,
          runbookTeams
        })
      )
    ) {
      methods.setValue(`usersTeamsSelect.${index}.name`, newTeamName)
      if (isInvalid) {
        removeErrorItem(item.id, item.type)
      }
    } else {
      return true
    }

    setIsRenameOpen(false)
  }
  const removeTeamItem = () => {
    removeItem(index)
  }

  const userItemHeight = 28
  let endComponents = []
  if (teamLinked) {
    endComponents.push(
      <IconButton
        tertiary
        icon="unlink"
        size="small"
        data-testid="unlink-button"
        onClick={e => {
          setIsUnlinking(true)
          e.stopPropagation()
        }}
        label={translate('runbook:peoplePanel:teams:unlinkTooltip')}
      />
    )
  } else {
    endComponents.push(
      <IconButton
        tertiary
        icon="edit"
        size="small"
        data-testid="edit-name-button"
        onClick={e => {
          setIsRenameOpen(true)
          e.stopPropagation()
        }}
        label={translate('runbook:peoplePanel:teams:editNameTooltip')}
      />
    )
  }

  return (
    <Item onDragOver={onDragOver} onDrop={onDrop}>
      {isRenameOpen ? (
        <ItemCreateInput
          icon="edit"
          onCreateItem={handleRename}
          onCancel={() => setIsRenameOpen(false)}
          hasTrigger={false}
          initialValue={teamName}
          size="small"
          placeholder={translate('runbook:peoplePanel:teams:editNamePlaceholder')}
        />
      ) : (
        <TeamItemWrapper onClick={onTeamItemClick} usersCount={usersCount}>
          <TeamListItem
            id={item.id}
            size="small"
            usersCount={usersCount}
            name={teamName}
            linked={teamLinked}
            color={item.color}
            hasError={isInvalid}
            onClickRemove={removeTeamItem}
            draggable={false}
            endComponents={endComponents}
          />
        </TeamItemWrapper>
      )}
      <>
        {item.hasOwnProperty('id') && (
          <input type="hidden" {...register(`usersTeamsSelect.${index}.id` as const)} defaultValue={item.id} />
        )}
        <input type="hidden" {...register(`usersTeamsSelect.${index}.name` as const)} defaultValue={teamName} />
        <input type="hidden" {...register(`usersTeamsSelect.${index}.type` as const)} defaultValue="team" />
        <InputHidden>
          <input
            type="checkbox"
            {...register(`usersTeamsSelect.${index}.linked` as const)}
            checked={teamLinked}
            readOnly
          />
        </InputHidden>
        <UserListWrapper accordionState={accordionState} ref={userListRef} data-cy="team-user-list">
          {teamUsers?.length > 0 &&
            teamUsers.map((user: UserListItemProps, index: number) => {
              return (
                <UserListItem
                  size="small"
                  id={user.id}
                  key={user.id}
                  name={user.name}
                  online={user.online}
                  color={user.color}
                  status={user.status}
                  onClickRemove={
                    teamLinked
                      ? undefined
                      : () => {
                          removeUserItem(index)
                        }
                  }
                  draggable={false}
                />
              )
            })}
          <input type="hidden" defaultValue={[]} {...methods.register(`usersTeamsSelect.${index}.userIds`)} />

          {isTeamLoading && (
            <LoadingPanelWrapper usersCount={usersCount} userItemHeight={userItemHeight}>
              <LoadingPanel size={50} />
            </LoadingPanelWrapper>
          )}
        </UserListWrapper>
      </>
      <TeamUnlinkModal isOpen={isUnlinking} setIsUnlinking={setIsUnlinking} unlinkTeam={unlinkTeam} />
    </Item>
  )
}

const Item = styled.li`
  position: relative;
`

const TeamItemWrapper = styled.div<{ usersCount: number }>`
  display: flex;
  align-items: center;
  position: relative;
  cursor: ${props => (props.usersCount > 0 ? 'pointer' : 'default')};
`

const UserListWrapper = styled.div<{ accordionState: string }>`
  position: relative;
  width: 100%;
  padding-left: 28px;
  overflow: ${props => (props.accordionState === 'closed' ? 'hidden' : 'none')};
  height: ${props => (props.accordionState === 'closed' ? 0 : 'auto')};
  ${collapseTransition};
`

const LoadingPanelWrapper = styled.div<{ usersCount: number; userItemHeight: number }>`
  position: relative;
  margin: 10px 0;
  height: ${props => Math.min(props.usersCount, 4) * props.userItemHeight}px;
`

const InputHidden = styled.div`
  display: none;
`
