import { forwardRef, useCallback, useId, useImperativeHandle, useMemo, useState } from 'react'
import isHotkey from 'is-hotkey'
import { createEditor, Descendant, Transforms } from 'slate'
import { withHistory } from 'slate-history'
import { Editable, ReactEditor, RenderElementProps, RenderLeafProps, Slate, withReact } from 'slate-react'
import { onKeyDown as onListKeyDown, withLists } from '@prezly/slate-lists'
import styled from 'styled-components/macro'

import { SettableFieldType } from '../../utilities'
import {
  withAutoFormat,
  withCodeBlock,
  withCustomBreak,
  withEmptyReset,
  withImages,
  withLinks,
  withPasteHtml,
  withTables
} from './plugins'
import { deserializeHtml, serialize } from './utils/serializer'
import { Box } from '../../layout/box'
import { themeColor } from '../../theme'
import { FormField, FormFieldComponentProps, FormFieldLabelBox, StaticFormField } from '../../form/internal/form-field'
import { TextEditorToolbar } from './components/text-editor-toolbar'
import { createEmptyParagraphNode, getParentBlock, insertLink, toggleMark } from './utils'
import { Element, Leaf, LinkEditor } from './elements'
import { CustomElement, CustomText, Format, ListSchema } from './text-editor-types'
import { LinkPayload, TextEditorLinkModal } from './components/text-editor-link-modal'

// Note: Slate once was a controlled component (i.e. it's contents were strictly controlled by
// the value prop) but due to features like its edit history which would be corrupted by direct
// editing of the value it is no longer a controlled component. So the 'value' prop is actually
// just the 'initial' value

const pipe =
  (...fns: any) =>
  (x: any) =>
    fns.reduce((v: any, f: any) => f(v), x)

// Note: the order of these matters as plugins sequentially override core functions
const withPlugins = (onPasteFile?: (data: DataTransfer) => void) =>
  pipe(
    withReact,
    withCustomBreak,
    withAutoFormat,
    withHistory,
    withTables,
    withEmptyReset,
    withImages,
    withPasteHtml(onPasteFile),
    withCodeBlock,
    withLinks
  )

const HOTKEYS: { [x: string]: Format } = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline'
}

export type TextEditorProps = {
  value?: string
  inlineError?: string
  placeholder?: string
  hasError?: boolean
  disabled?: boolean
  readOnly?: boolean
  required?: boolean
  name?: string // TODO: how can we not support this? -- could pass this to the the textarea input element that holds the html input value even if it isn't visible, always.
  'data-testid'?: string // TODO: should we have this somewhere?
  a11yTitle?: string
  autoFocus?: boolean
  label?: string
  'aria-label'?: string
  onChange: (value?: string) => void
  plain?: boolean // Hide label & underline (when field is standalone)
  invertColor?: boolean
  restrictedMode?: boolean
  onEnterSubmit?: () => void
  onPasteFile?: (data: DataTransfer) => void
}

const isValEmpty = (val?: string | null) => [null, undefined, '', '<p></p>'].includes(val)
const isValEmptyParagraph = (val?: string | null) => val === '<p></p>'

export const TextEditor = forwardRef<SettableFieldType, TextEditorProps>(
  (
    {
      placeholder = 'Enter content here...',
      restrictedMode, // NOTE: initially, hardcode this to basic in-app
      onChange,
      autoFocus,
      readOnly = false,
      invertColor = false,
      onEnterSubmit,
      onPasteFile,
      disabled,
      plain,
      label,
      a11yTitle,
      value,
      ...restFormFieldProps
    },
    ref
  ) => {
    const labelId = useId()
    const editor = useMemo(() => withLists(ListSchema)(withPlugins(onPasteFile)(createEditor())), [])
    const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, [])
    const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, [])
    const initialInput = useMemo(() => value, [])
    const wasInitiallyEmpty = useMemo(() => isValEmpty(initialInput), [])
    const [linkModalOpen, setLinkModalOpen] = useState(false)

    const createLocalInitialValue = () => {
      if (initialInput) {
        return deserializeHtml(initialInput ?? '')
      } else {
        return [createEmptyParagraphNode()]
      }
    }

    const handleInsertLink = (data: LinkPayload) => {
      insertLink(editor, data)
    }

    // This part is important. On init, we pass localValue into the editor, which is either an html string from our database, or an empty paragraph
    const [localValue, setLocalValue] = useState<any>(createLocalInitialValue())

    const setFieldValue = (value?: string) => {
      if (value) setLocalValue(deserializeHtml(value ?? ''))
      else setLocalValue([createEmptyParagraphNode()])
    }

    const resetEditorSelection = (editor: any) => {
      Transforms.deselect(editor)
    }

    useImperativeHandle(
      ref,
      () => ({
        reset: (value: string) => {
          // Reset the editor selection to avoid errors with non-existent nodes after reset
          resetEditorSelection(editor)
          if (value) {
            setFieldValue(value)
          } else {
            setLocalValue(createLocalInitialValue())
            onChange(initialInput)
          }
        },
        clear: () => {
          resetEditorSelection(editor)
          const emptyInput = ['<p></p>', '', null, undefined].find(val => val === initialInput) ?? '<p></p>'
          onChange(emptyInput)
          setLocalValue([createEmptyParagraphNode()])
        },
        focus: () => {
          ReactEditor.focus(editor)
        },
        setValue: setFieldValue
      }),
      []
    )

    const handleInnerValueChange = (val: Descendant[]) => {
      setLocalValue(val)
      const htmlValue = serialize({ children: val } as CustomElement | CustomText)
      if (isValEmptyParagraph(htmlValue) && wasInitiallyEmpty) {
        if (!isValEmpty(value)) onChange(initialInput)
      } else {
        onChange(htmlValue)
      }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      const { onKeyDown } = editor

      for (const hotkey in HOTKEYS) {
        if (isHotkey(hotkey, event as any)) {
          event.preventDefault()
          const mark = HOTKEYS[hotkey]
          toggleMark(editor, mark)
        }
      }

      // Note: the list keydown handling would normally be via plugin file, but we use external module for this
      // Only want to activate the list keydown handlers when within a list. Otherwise can't tab out of field
      const currentBlock = getParentBlock(editor)
      const currentBlockType = currentBlock ? currentBlock.type : ''
      if (currentBlockType === 'bulleted-list' || currentBlockType === 'numbered-list') {
        onListKeyDown(editor, event)
      } else if (!!onEnterSubmit && event.key === 'Enter' && !event.shiftKey) {
        // Only used for activity feed. Block enter key handling to allow enter to submmit the message
        event.preventDefault()
        onEnterSubmit?.()
        return
      }

      // Expose this keydown event to plugins
      onKeyDown?.(event)
    }

    const slateEditor = (
      <Slate editor={editor} initialValue={localValue} onChange={handleInnerValueChange}>
        <SlateEditorContainer>
          {!readOnly && <LinkEditor />}
          {!readOnly && (
            <TextEditorToolbar
              openLinkModal={() => setLinkModalOpen(true)}
              invertColor={invertColor}
              restrictedMode={restrictedMode}
              plain={plain}
            />
          )}
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            tabIndex={!readOnly && !disabled ? 0 : -1}
            readOnly={readOnly || disabled}
            placeholder={placeholder ?? ''}
            role="textbox"
            aria-required={restFormFieldProps.required}
            autoFocus={autoFocus}
            data-autofocus={autoFocus}
            onKeyDown={handleKeyDown}
            // Note: If plain=true, the editor is NOT wrapped in standard common form element, so set the aria stuff here
            // Also note - this doesnt seem right, the 'plain' should be a prop on FormField & common to all form comps, and just hide the 'label' wrapper, not omit it
            aria-labelledby={plain ? undefined : labelId}
            aria-label={label}
            css={`
              [data-slate-placeholder='true'] {
                top: unset !important;
              }
              > :first-child {
                margin-top: ${plain && readOnly ? '0' : undefined};
              }
              position: static !important;
              border-radius: 8px 8px 0 0;
              padding: ${invertColor ? '0 12px' : undefined};
              background: ${invertColor ? themeColor('bg-1') : undefined};

              // Adjust default margins when empty or only 1 paragraph elem, to give total input height of 32px
              p:first-child {
                margin-top: ${invertColor ? '5px' : undefined};
              }
              p:last-child {
                margin-bottom: ${invertColor ? '5px' : undefined};
              }
            `}
          />
        </SlateEditorContainer>
      </Slate>
    )

    return (
      <>
        {plain ? (
          <SlateStaticFormField tabIndex={-1}>{slateEditor}</SlateStaticFormField>
        ) : (
          <>
            {/* give the static form field a tabIndex of -1 since we have to give it the onClick here for some reason
    related to overriding a slate focus state issue */}
            <SlateStaticFormField tabIndex={-1}>
              <FormField
                readOnly={readOnly}
                disabled={disabled}
                {...restFormFieldProps}
                label={label}
                labelProps={{
                  // otherwise the toolbar items are included
                  'aria-label': a11yTitle ?? label,
                  id: labelId
                }}
              >
                <Box width="100%">{slateEditor}</Box>
              </FormField>
            </SlateStaticFormField>
          </>
        )}
        {linkModalOpen && (
          <TextEditorLinkModal
            editor={editor}
            onClose={() => setLinkModalOpen(false)}
            onInsertLink={handleInsertLink}
          />
        )}
      </>
    )
  }
)

const SlateStaticFormField = styled(StaticFormField)<FormFieldComponentProps>`
  min-height: unset;
  cursor: default;
  ${FormFieldLabelBox} {
    &:focus-visible {
      box-shadow: none !important;
    }
  }
`

const SlateEditorContainer = styled(Box)`
  [contenteditable='true'] {
    cursor: text;
  }
  font-size: 15px;
`
