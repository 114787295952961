import { useCallback, useEffect, useMemo, useState } from 'react'

import { toCamelCase } from '@cutover/api'
import { IntegrationStatusProps } from '@cutover/react-ui'
import { IntegrationConnectionConfigCamel } from 'main/services/hooks'
import {
  IntegrationActionItem,
  IntegrationFinishedStatus,
  IntegrationStatus,
  TaskListTask
} from 'main/services/queries/types'
import { ConfigModel, RunbookViewModel, TaskTypeModel } from 'main/data-access'

const INTEGRATION_STATUS_COLOR_LOOKUP: Record<IntegrationFinishedStatus, string> = {
  Success: 'success',
  Failed: 'error',
  Cancelled: 'warning'
}

type IntegrationStatusResult = {
  integrationActionItem: IntegrationActionItem
  integrationOptions: { [key: string]: any }
  integrationStatusProps: IntegrationStatusProps | undefined
  integrationEventStatus: IntegrationStatus | undefined
}

export const useTaskListItemIntegrationStatus = (task: TaskListTask) => {
  const request = RunbookViewModel.useGet('integrationRequest')
  const removeRequest = RunbookViewModel.useAction('integrationRequest:remove')
  const taskType = TaskTypeModel.useGet(task.task_type_id)
  const { integrations } = ConfigModel.useGet()

  const lastEvent = useMemo(() => {
    const integrationEvents = task.integration_events
    const length = integrationEvents.length

    if (length === 0) return undefined
    return integrationEvents[length - 1]
  }, [task])

  const memoizedResult = useCallback(
    (isRequest: boolean) => {
      const actionItem = taskType.integration_action_items[0]
      const action = actionItem?.integration_action

      const connectionConfig = (action &&
        integrations.filter(integration =>
          integration.klass.startsWith(action.substring(0, action.lastIndexOf('::')))
        )[0]) as IntegrationConnectionConfigCamel

      const actionItemConfig = connectionConfig?.actions.find(act => act.klass === action)

      const options = {
        ...actionItemConfig?.options,
        ...toCamelCase(actionItem?.option_overrides)
      }

      // Ensure the first status is always 'Connect' if there is a manual request
      const eventStatus: IntegrationStatus | undefined = isRequest
        ? 'Connect'
        : lastEvent
        ? (lastEvent.status as IntegrationStatus)
        : undefined

      const status = eventStatus
        ? actionItemConfig?.statusMessages[eventStatus.toLowerCase() as keyof typeof actionItemConfig.statusMessages]
        : undefined

      let statusProps: IntegrationStatusProps | undefined = undefined

      if (status) {
        statusProps = {
          status: status,
          color: INTEGRATION_STATUS_COLOR_LOOKUP[eventStatus as IntegrationFinishedStatus] || 'text-light',
          error: isRequest ? null : lastEvent ? lastEvent.error_reason : null,
          progress: lastEvent && lastEvent.progress ? lastEvent.progress : null
        }
      }

      return {
        integrationActionItem: actionItem,
        integrationOptions: options,
        integrationStatusProps: statusProps,
        integrationEventStatus: eventStatus
      }
    },
    [task, taskType, integrations]
  )

  const [result, setResult] = useState<IntegrationStatusResult | null>(null)

  useEffect(() => {
    if (request?.hasOwnProperty(task.id)) {
      setResult(() => memoizedResult(true))
    }
  }, [request])

  useEffect(() => {
    removeRequest(task.id)
    setResult(() => memoizedResult(false))
  }, [task])

  return result
}
