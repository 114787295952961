import { useCallback } from 'react'

import {
  RunbookResponse,
  RunbookVersionCancelApprovalResponse,
  RunbookVersionConvertToTemplateResponse,
  RunbookVersionCreateResponse,
  RunbookVersionImportResponse,
  RunbookVersionMarkAsApprovedResponse,
  RunbookVersionMergedResponse,
  RunbookVersionRequestApprovalResponse,
  RunbookVersionSubmitReviewResponse
} from 'main/services/api/data-providers/runbook-types'
import { ActiveRunbookVersionModel } from 'main/data-access'

export const useProcessRunbookVersionResponse = () => {
  const processRunbookVersionCsvImportResponse = ActiveRunbookVersionModel.useOnAction('runbook_version_import')
  const processRunbookVersionCreateResponse = ActiveRunbookVersionModel.useOnAction('create')
  const processRunbookVersionConvertToTemplateResponse = ActiveRunbookVersionModel.useOnAction('convert_to_template')
  const processRunbookVersionMarkAsApprovedResponse = ActiveRunbookVersionModel.useOnAction('mark_as_approved')
  const processRunbookVersionCancelApprovalResponse = ActiveRunbookVersionModel.useOnAction('cancel_approval')
  const processRunbookVersionRequestApprovalResponse = ActiveRunbookVersionModel.useOnAction('request_approval')
  const processRunbookVersionSubmitReviewResponse = ActiveRunbookVersionModel.useOnAction('submit_review')
  const processRunbookVersionMergedResponse = ActiveRunbookVersionModel.useOnAction('runbook_merged_after_task')

  return useCallback(
    (response: RunbookResponse) => {
      switch (response.meta.headers.request_method) {
        case 'create':
          processRunbookVersionCreateResponse(response as RunbookVersionCreateResponse)
          break
        case 'runbook_version_import':
          processRunbookVersionCsvImportResponse(response as RunbookVersionImportResponse)
          break
        case 'convert_to_template':
          processRunbookVersionConvertToTemplateResponse(response as RunbookVersionConvertToTemplateResponse)
          break
        case 'mark_as_approved':
          processRunbookVersionMarkAsApprovedResponse(response as RunbookVersionMarkAsApprovedResponse)
          break
        case 'request_approval':
          processRunbookVersionRequestApprovalResponse(response as RunbookVersionRequestApprovalResponse)
          break
        case 'cancel_approval':
          processRunbookVersionCancelApprovalResponse(response as RunbookVersionCancelApprovalResponse)
          break
        case 'submit_review':
          processRunbookVersionSubmitReviewResponse(response as RunbookVersionSubmitReviewResponse)
          break
        case 'runbook_merged_after_task':
          processRunbookVersionMergedResponse(response as RunbookVersionMergedResponse)
          break
        default:
          return
      }
    },
    [
      processRunbookVersionCsvImportResponse,
      processRunbookVersionCreateResponse,
      processRunbookVersionConvertToTemplateResponse,
      processRunbookVersionMarkAsApprovedResponse,
      processRunbookVersionRequestApprovalResponse,
      processRunbookVersionCancelApprovalResponse,
      processRunbookVersionSubmitReviewResponse,
      processRunbookVersionMergedResponse
    ]
  )
}
