import { RefObject, useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { usePrevious } from 'react-use'

import {
  Accordion,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  DayTimePicker,
  DurationPicker,
  Icon,
  IconButton,
  Message,
  RIGHT_PANEL_SCROLL_CONTAINER_ID,
  SettableFieldType,
  TaskTypeIcon,
  Tooltip
} from '@cutover/react-ui'
import {
  CheckboxFieldControlled,
  DateTimePickerField,
  DurationPickerField,
  RadioboxGroupField,
  SelectField,
  SnippetSelectField,
  StreamSelectField,
  TextAreaField,
  TextEditorField
} from 'main/components/shared/form'
import { TaskShowPermissions, useTaskEditTaskTypes, useTaskTypeRemovedName } from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { RunbookTemplateRunbook, TaskBase, TaskType } from 'main/services/queries/types'
import { TaskCommsSettingsModal } from 'main/components/runbook/modals/task-modals/task-comms-settings-modal'
import { useCustomFieldFormCallback } from 'main/components/shared/custom-field-form'
import {
  CustomFieldsGroupsForm,
  CustomFieldsGroupsFormProps
} from 'main/components/shared/custom-field-form/custom-field-groups-form'
import { TaskEditDependenciesPanel } from './dependencies-panel'
import { TaskActionContent } from './task-action-content'
import { TaskAdvancedSettings } from './task-advanced-settings'
import { TaskOtherDetails } from './task-other-details'
import { TaskWebhooks } from './task-webhooks'
import { FormType } from 'main/components/shared/form/form'
import { TaskCommsStatus } from './task-comms-status'
import { TaskEditAssignedUsersAndTeamsPanel } from './assigned-users-teams-panel'
import { TaskEditDescriptionModal } from './task-edit-description-modal'
import {
  ActiveAccountModel,
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  ActiveRunModel,
  ConfigModel,
  CurrentRunbookVersionModel,
  CustomFieldModel,
  RunbookViewModel,
  StreamModel,
  TaskTypeModel
} from 'main/data-access'
import { DynamicTextField } from 'main/components/shared/form/dynamic-text-field/dynamic-text-field'
import { TaskSourceRunbookPanel } from './task-source-runbook-panel'
import { TaskEditFormType } from './task-edit-form'

export const TaskEditFormFields = ({
  task,
  permissions: can,
  readOnly,
  setTaskTypeId,
  descriptionFieldRef,
  formRef,
  ...customFieldsProps
}: CustomFieldsGroupsFormProps & {
  /** This task is a combination of data from TaskListTask and TaskShowTask, represented by TaskBase. */
  task: TaskBase
  permissions: TaskShowPermissions
  readOnly: boolean
  setTaskTypeId: (id: number) => void
  descriptionFieldRef: RefObject<SettableFieldType>
  formRef: RefObject<FormType<any>>
}) => {
  const {
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
    getFieldState,
    control
  } = useFormContext<TaskEditFormType>()
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel' })
  const [showTaskCommsSettingsModal, setShowTaskCommsSettingsModal] = useState(false)
  const [isDescriptionModalOpen, setDescriptionModalOpen] = useState(false)
  const {
    stage: runbookVersionStage,
    start_scheduled: startScheduled,
    start_display: versionStartDisplay,
    timing_mode: timingMode
  } = CurrentRunbookVersionModel.useGet()
  const run = ActiveRunModel.useGet()
  const {
    id: runbookId,
    timezone,
    is_template: isTemplate,
    settings_task_description_on_task_start: descriptionOnTaskStart,
    archived: archivedRunbook
  } = ActiveRunbookModel.useGet()
  const taskTypeLookup = TaskTypeModel.useGetLookup()
  const streamLookup = StreamModel.useGetLookup()
  const { dynamic: isDynamic } = ActiveRunbookModel.useRunbookType()

  // Linked modal requirements
  const openModal = RunbookViewModel.useAction('modal:open')
  const runbookVersionId = ActiveRunbookVersionModel.useId()

  const taskTypeId = watch('task_type_id')
  const { taskTypes, taskTypeIntegrations } = useTaskEditTaskTypes()
  const taskTypeRemovedName = useTaskTypeRemovedName(task.task_type_id)
  const currentTaskType = taskTypeLookup[taskTypeId]
  const previousTaskType = usePrevious(currentTaskType)
  const taskTypesOptions =
    taskTypes?.map((t: TaskType) => ({ value: t.id, label: t.name, icon: <TaskTypeIcon icon={t.icon} /> })) ?? []
  const taskTypesIntegrationsOptions =
    taskTypeIntegrations?.map((t: TaskType) => ({
      value: t.id,
      label: t.integration_action_items[0].name,
      icon: (
        <IntegrationIcon
          alt={`${t.integration_action_items[0].name}-icon`}
          src={t.integration_action_items[0].image_url || t.integration_action_items[0].integration_setting.image_url}
        />
      )
    })) ?? []
  const { taskLevels, integrations } = ConfigModel.useGet()
  const integrationActionItem = currentTaskType?.integration_action_items?.[0]
  const { slug: accountSlug } = ActiveAccountModel.useGet()
  const customFieldsLookup = CustomFieldModel.useGetLookup()

  const recipients = watch('recipients')
  const autoFinish = watch('auto_finish')
  const description = watch('description')
  const endDisplay = watch('end_display')
  const duration = watch('duration')
  const endFixed = watch('end_fixed')
  const endActual = watch('end_actual')
  const endPlanned = watch('end_planned')
  const endRequirements = watch('end_requirements')
  const message = watch('message')
  const possiblePredecessorsIds = watch('possible_predecessors_ids')
  const recipientUserIds = watch('recipient_user_ids')
  const recipientRunbookTeamIds = watch('recipient_runbook_team_ids')
  const runbookTeamIds = watch('runbook_team_ids')
  const startLatestPlanned = watch('start_latest_planned')
  const startActual = watch('start_actual')
  const stage = watch('stage')
  const startDisplay = watch('start_display')
  const startFixed = watch('start_fixed')
  const streamId = watch('stream_id')
  const successorIds = watch('successor_ids')
  const userIds = watch('user_ids')
  const functionalOauthUser = watch('functional_oauth_user')

  const isTaskTypeAutoFinish = currentTaskType?.auto_finish
  const isTaskTypeBranching = currentTaskType?.conditional_progression
  const isTaskTypeEnableStartFixed = currentTaskType?.enable_start_fixed
  const isTaskTypeEnableEndFixed = currentTaskType?.enable_end_fixed
  const isTaskTypeCommsOff = currentTaskType ? currentTaskType.comms === 'comms_off' : true
  const isTaskTypeIntegration = !!taskTypeIntegrations.find(taskType => taskType.id === currentTaskType?.id)
  const isTaskTypeLinkable = currentTaskType?.linkable
  const isTaskTypeSnippet = currentTaskType?.key === 'snippet'
  const isTaskTypeValidation = currentTaskType?.key === 'validation'

  const { getCustomFieldData: getTaskEndCustomFieldData } = useCustomFieldFormCallback({
    applyToSlugs: ['task_end'],
    customFieldsLookup
  })

  const { customFields: taskEndCustomFields } = getTaskEndCustomFieldData(
    currentTaskType?.integration_action_items?.[0],
    {
      task_type_id: taskTypeId
    }
  )

  const hasRequiredTaskEndCustomField = !!taskEndCustomFields?.find(customField => customField.required)

  const isRunbookPlanningOrPaused = !run || run?.mode === 'paused'

  const durationDifference = useMemo(
    () =>
      endDisplay && startDisplay
        ? {
            start: (endDisplay - startDisplay) / 1000,
            end: duration ?? 0
          }
        : undefined,
    []
  )

  const endDifference = useMemo(
    () =>
      endDisplay && endPlanned && !(isTemplate || timingMode === 'unscheduled')
        ? {
            start: endDisplay / 1000,
            end: endFixed ? Math.max(endPlanned, (endFixed as number) / 1000) : endPlanned
          }
        : undefined,
    []
  )

  const startDifference = useMemo(
    () =>
      startDisplay && startLatestPlanned && !(isTemplate || timingMode === 'unscheduled')
        ? {
            start: startDisplay / 1000,
            end: startLatestPlanned
          }
        : undefined,
    []
  )

  const permittedStreams = StreamModel.useGetAll({ scope: 'permitted' })
  const selectedStream = StreamModel.useGet(streamId)
  const selectedStreamColor = selectedStream?.color
  const streams = !readOnly ? permittedStreams : [selectedStream]

  const shouldShowTimingField = (arg: any) =>
    (startScheduled || run) && (!(isTemplate || timingMode === 'unscheduled') || !!arg)

  const recipientCount = recipients
    ? (recipients.users?.length ?? 0) + (recipients.runbook_teams?.length ?? 0)
    : (recipientUserIds?.length ?? 0) + (recipientRunbookTeamIds?.length ?? 0)

  const allowInProgressEdit = (() => {
    if (!run || run.mode === 'cancelled' || archivedRunbook) {
      return can?.update
    }

    if ((isDynamic && run.mode === 'active') || run.mode === 'paused') {
      return task.end_actual ? can?.update : can?.in_progress_update
    }

    return can?.update
  })()

  useEffect(() => {
    if (startDisplay && duration && getFieldState('duration').isDirty && isRunbookPlanningOrPaused) {
      setValue('end_display', startDisplay + duration * 1000, { shouldDirty: false, shouldTouch: false })
    }
  }, [duration])

  useEffect(() => {
    const userHasUpdatedTaskType = previousTaskType && currentTaskType?.id !== previousTaskType?.id
    if (!userHasUpdatedTaskType) return

    if (previousTaskType.enable_end_fixed && currentTaskType?.enable_end_fixed && endFixed) {
      setValue('end_fixed', new Date(endFixed as number))
    }

    if (previousTaskType.enable_end_fixed && !currentTaskType?.enable_end_fixed && endFixed) {
      setValue('end_fixed', null)
    }

    if (previousTaskType.enable_start_fixed && !currentTaskType?.enable_start_fixed && startFixed) {
      setValue('start_fixed', null)
    }

    setTaskTypeId(taskTypeId)
  }, [previousTaskType, currentTaskType, endFixed, startFixed, task])

  useEffect(() => {
    if (!isTaskTypeSnippet) {
      setValue('linked_snippets', [])
    }
  }, [isTaskTypeSnippet, setValue])

  useEffect(() => {
    if (
      isTaskTypeAutoFinish ||
      isTaskTypeLinkable ||
      isTaskTypeBranching ||
      isTaskTypeIntegration ||
      hasRequiredTaskEndCustomField ||
      isTaskTypeValidation ||
      endRequirements === 'all_must_end' ||
      endRequirements === 'same_must_end'
    ) {
      setValue('auto_finish', false)
    }
  }, [
    isTaskTypeAutoFinish,
    isTaskTypeLinkable,
    isTaskTypeBranching,
    isTaskTypeIntegration,
    hasRequiredTaskEndCustomField,
    isTaskTypeValidation,
    endRequirements
  ])

  const integrationDescription = useMemo(() => {
    if (!integrationActionItem) {
      return
    }

    const integration = integrations.find(i => i.name === integrationActionItem?.integration_setting?.integration_title)
    const integrationAction = integration?.actions?.find(
      action => action.klass === integrationActionItem?.integration_action
    )
    return integrationAction?.description
  }, [integrationActionItem, integrations, task])

  const handleDescriptionModalConfirm = (descriptionModalValue: string) => {
    descriptionFieldRef.current?.setValue?.(descriptionModalValue)
    setDescriptionModalOpen(false)
  }

  const templateValue = getValues('name_template')
  const hasDynamicTitle = templateValue !== undefined && templateValue !== null

  return (
    <>
      {isDescriptionModalOpen && (
        <TaskEditDescriptionModal
          readOnly={readOnly}
          initialValue={description}
          onClose={() => setDescriptionModalOpen(false)}
          onClickConfirm={handleDescriptionModalConfirm}
        />
      )}
      {hasDynamicTitle ? (
        <DynamicTextField
          as="textarea"
          hasError={!!errors['name']}
          label={t('fields.title.label')}
          readOnly={readOnly}
          required
          templateKey="name_template"
          valueKey="name"
        />
      ) : (
        <TextAreaField<TaskEditFormType>
          data-testid="edit-task-title"
          name="name"
          label={t('fields.title.label')}
          readOnly={!allowInProgressEdit}
        />
      )}
      <Box flex direction="row" gap="small">
        <TaskTypeSelectWrapper isTaskTypeCommsOff={isTaskTypeCommsOff}>
          <SelectField<TaskEditFormType>
            name="task_type_id"
            options={[
              { header: t('fields.taskType.taskTypeSelectHeader') },
              ...taskTypesOptions,
              ...[
                ...(taskTypesIntegrationsOptions.length
                  ? [{ header: t('fields.taskType.integrationTaskTypeSelectHeader') }]
                  : [])
              ],
              ...taskTypesIntegrationsOptions
            ]}
            label={t('fields.taskType.label')}
            placeholder={!!taskTypeRemovedName ? taskTypeRemovedName : t('fields.taskType.placeholder')}
            noBottomPadding={!isTaskTypeCommsOff}
          />
        </TaskTypeSelectWrapper>

        {!isTaskTypeCommsOff && (
          <Button
            css="flex: 1; position: relative; top: 6px;"
            label={t('fields.commsSettings.settings')}
            secondary
            onClick={() => setShowTaskCommsSettingsModal(true)}
          />
        )}
      </Box>

      {isTaskTypeLinkable && !isTaskTypeSnippet && (
        <Box flex direction="row" gap="small">
          <TextAreaField<TaskEditFormType>
            role="textbox"
            name="linked_resource_name"
            label={t('fields.linkedResource.label')}
            readOnly
            required
            disabled={isSubmitting}
          />
          {!readOnly && (
            <IconButton
              role="button"
              label={t('fields.linkedResource.editLinkedTemplate')}
              icon="edit"
              css="position: relative; top: 10px;"
              onClick={() => {
                openModal({
                  type: 'linked-template-selector',
                  data: {
                    accountId: accountSlug,
                    action: 'update',
                    multiselect: false,
                    runbookId: runbookId.toString(),
                    runbookVersionId: runbookVersionId.toString(),
                    onSubmit: (template: RunbookTemplateRunbook) => {
                      setValue('linked_resource_id', template.id, { shouldDirty: true })
                      setValue('linked_resource_name', template.name)
                    }
                  }
                })
              }}
            />
          )}
        </Box>
      )}
      {isTaskTypeSnippet && (
        <SnippetSelectField<TaskEditFormType>
          name="linked_snippets"
          label={t('fields.snippets.label')}
          a11yTitle={t('fields.snippets.addSnippet')}
          isSubmitting={isSubmitting}
          accountSlug={accountSlug}
          helpText={t('fields.snippets.helpText')}
        />
      )}
      {!isTaskTypeCommsOff && (
        <MessageStatusWrapper>
          <TaskCommsStatus
            condition={!!message}
            statusMessage={t('fields.commsSettings.messageStatus', { count: message ? 1 : 0 })}
            position="left"
            type="message"
          />
          <TaskCommsStatus
            condition={recipientCount > 0}
            statusMessage={t('fields.commsSettings.recipientsStatus', { count: recipientCount })}
            type="recipients"
          />
        </MessageStatusWrapper>
      )}
      <TaskCommsSettingsModal
        loadInitialRecipients={!recipients}
        open={showTaskCommsSettingsModal}
        recipientUserIds={recipientUserIds ?? []}
        recipientRunbookTeamIds={recipientRunbookTeamIds ?? []}
        onClose={() => setShowTaskCommsSettingsModal(false)}
        canModifyComms={can?.modify_comms}
        taskId={task.id}
        commsType={currentTaskType?.comms}
      />
      <StreamSelectField<TaskEditFormType>
        label={t('fields.stream.label')}
        streams={streams ?? []}
        name="stream_id"
        clearable={false}
        placeholder={t('fields.stream.placeholder')}
        loading={streams === undefined}
      />
      <RadioboxGroupField<TaskEditFormType>
        name="level"
        label={t('fields.level.label')}
        direction="row"
        options={taskLevels.map(level => ({ value: level.id, label: level.name })).reverse()}
      />
      {shouldShowTimingField(startActual) && (
        <DateTimePickerField<TaskEditFormType>
          diff={startDifference}
          name="start_display"
          readOnly // always true as it's a computed value
          onChange={date => date}
          label={t('fields.forecastStart.label', { context: startActual ? 'started' : undefined })}
          timezone={timezone}
        />
      )}
      {!isTaskTypeAutoFinish && stage !== 'complete' && (
        <DurationPickerField<TaskEditFormType>
          label={t('fields.duration.label')}
          readOnly={(!can?.update && !(isDynamic && task.stage === 'in-progress')) || !!task.linked_resource}
          name="duration"
          diff={durationDifference}
        />
      )}
      {!isTaskTypeAutoFinish && stage === 'complete' && endDisplay && startDisplay && (
        // Not using DurationPickerField because value is computed
        <DurationPicker
          value={(endDisplay - startDisplay) / 1000}
          readOnly
          onChange={value => value}
          label={t('fields.duration.labelActual')}
          diff={durationDifference}
        />
      )}
      {shouldShowTimingField(endActual) && !isTaskTypeAutoFinish && (
        <DateTimePickerField<TaskEditFormType>
          diff={endDifference}
          name="end_display"
          readOnly // always true as it's a computed value
          onChange={date => date}
          label={t('fields.forecastFinish.label', { context: endActual ? 'finished' : undefined })}
          timezone={timezone}
        />
      )}
      {isTaskTypeEnableStartFixed && (
        <>
          {(isRunbookPlanningOrPaused || isDynamic) && (
            // Not using a checkbox field since it is setting the value of start_fixed. This checkbox is not registered with the form.
            <Checkbox
              label={t('fields.startFixed.checkboxLabel')}
              checked={startFixed !== null}
              disabled={isSubmitting}
              readOnly={readOnly}
              helpText={t('fields.startFixed.tooltip')}
              onChange={function (e) {
                e.preventDefault()
                setValue('start_fixed', startFixed === null ? new Date(startDisplay as number | Date) : null, {
                  shouldDirty: true,
                  shouldTouch: true
                })
              }}
            />
          )}
          {startFixed !== null && (
            <Box margin={{ top: '10px' }}>
              {timingMode === 'unscheduled' && (
                <Controller
                  name="start_fixed"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <DayTimePicker
                        dayZeroStartValue={versionStartDisplay ? new Date(versionStartDisplay * 1000) : new Date()}
                        onChange={date => (!!date ? onChange(date.toISOString()) : onChange(null))}
                        value={!!value ? new Date(value as number) : new Date()}
                        disabled={isSubmitting}
                        readOnly={readOnly}
                        timezone={timezone}
                      />
                    )
                  }}
                />
              )}
              {timingMode === 'scheduled' && (
                <DateTimePickerField<TaskEditFormType>
                  name="start_fixed"
                  label={t('fields.startFixed.timeFieldLabel')}
                  disabled={isSubmitting}
                  readOnly={readOnly}
                  timezone={timezone}
                />
              )}
            </Box>
          )}
        </>
      )}
      {isTaskTypeEnableEndFixed && (
        <>
          {(isRunbookPlanningOrPaused || isDynamic) && (
            // Not using a checkbox field since it is setting the value of end_fixed. This checkbox is not registered with the form.
            <Checkbox
              label={t('fields.dueDate.checkboxLabel')}
              checked={endFixed !== null}
              disabled={isSubmitting}
              readOnly={readOnly}
              helpText={t('fields.dueDate.tooltip')}
              onChange={function (e) {
                e.preventDefault()
                setValue('end_fixed', endFixed === null ? new Date(endDisplay as number | Date) : null, {
                  shouldDirty: true,
                  shouldTouch: true
                })
              }}
            />
          )}
          {endFixed !== null && (
            <Box margin={{ top: '10px' }}>
              {timingMode === 'unscheduled' && (
                // TODO: Add "day" time picker to smart form fields.
                <Controller
                  name="end_fixed"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <DayTimePicker
                        dayZeroStartValue={versionStartDisplay ? new Date(versionStartDisplay * 1000) : new Date()}
                        onChange={date => (!!date ? onChange(date.toISOString()) : onChange(null))}
                        value={!!value ? new Date(value as number) : new Date()}
                        timezone={timezone}
                        disabled={isSubmitting}
                        readOnly={readOnly}
                      />
                    )
                  }}
                />
              )}
              {timingMode === 'scheduled' && (
                <DateTimePickerField<TaskEditFormType>
                  name="end_fixed"
                  label={t('fields.dueDate.timeFieldLabel')}
                  timezone={timezone}
                  disabled={isSubmitting}
                  readOnly={readOnly}
                />
              )}
            </Box>
          )}
        </>
      )}

      {!isTaskTypeAutoFinish &&
        !isTaskTypeLinkable &&
        !isTaskTypeBranching &&
        !isTaskTypeIntegration &&
        !hasRequiredTaskEndCustomField &&
        !isTaskTypeValidation &&
        endRequirements !== 'all_must_end' &&
        endRequirements !== 'same_must_end' && (
          <>
            <Box margin={{ bottom: '16px' }}>
              <CheckboxFieldControlled<TaskEditFormType>
                name="auto_finish"
                label={t('fields.autoFinish.label')}
                helpText={t('fields.autoFinish.helpText')}
              />
            </Box>

            {autoFinish && (
              <Message margin data-testid="show-auto-finish-info" type="info">
                {t('fields.autoFinish.info')}
              </Message>
            )}
          </>
        )}
      <Accordion a11yTitle={t('fieldGroupsAccordion')} scrollContainer={RIGHT_PANEL_SCROLL_CONTAINER_ID}>
        <CustomFieldsGroupsForm
          {...customFieldsProps}
          formRef={formRef}
          integrationActionItem={integrationActionItem}
          iconColor={selectedStreamColor}
          errors={errors}
          readOnly={readOnly}
          integrationDescription={integrationDescription}
          functionalOauthUser={functionalOauthUser ?? false}
          taskId={task.id}
        />
        {runbookVersionStage !== 'planning' && (
          <AccordionPanel iconColor={selectedStreamColor} icon="play" label={t('actionAccordion.label')}>
            <TaskActionContent task={task} />
          </AccordionPanel>
        )}
        {!!task.runbook_component_id && task.source_runbook_id && (
          <TaskSourceRunbookPanel
            iconColor={selectedStreamColor}
            timezone={timezone}
            sourceRunbookId={task.source_runbook_id}
            sourceRunbookName={task.runbook_component_name}
            sourceRunbookTaskInternalId={task.source_internal_id}
            sourceRunbookFieldValues={task.runbook_component_field_values}
          />
        )}
        <AccordionPanel
          iconColor={selectedStreamColor}
          icon="info"
          label={t('description.label')}
          suffix={
            <Tooltip content={t('description.tooltip')}>
              <Icon
                icon="open-new"
                color="text-light"
                data-testid="open-description-modal-icon"
                onClick={() => setDescriptionModalOpen(true)}
                onKeyDown={e => {
                  if (e.code === 'Space' || e.code === 'Enter') {
                    setDescriptionModalOpen(true)
                  }
                }}
                tabIndex={0}
                css="display: block"
              />
            </Tooltip>
          }
        >
          <Box gap="medium">
            <TextEditorField<TaskEditFormType>
              formRef={formRef}
              name="description"
              label={t('fields.description.label')}
              readOnly={!allowInProgressEdit}
              ref={descriptionFieldRef}
              plain
            />

            {descriptionOnTaskStart && (
              <Message data-testid="show-description-on-task-start-info" type="info">
                {t('description.showOnTaskStartInfo')}
              </Message>
            )}
          </Box>
        </AccordionPanel>
        <TaskEditAssignedUsersAndTeamsPanel
          iconColor={selectedStreamColor}
          userIds={userIds ?? []}
          runbookTeamIds={runbookTeamIds ?? []}
          setValue={setValue}
          readOnly={!can?.update && !(isDynamic && task.stage === 'in-progress')}
        />
        <TaskEditDependenciesPanel
          successorIds={successorIds ?? []}
          predecessorIds={task.predecessor_ids ?? []}
          possiblePredecessorsIds={
            possiblePredecessorsIds && possiblePredecessorsIds.length > 0
              ? possiblePredecessorsIds
              : task.predecessor_ids
          }
          readOnly={readOnly}
          iconColor={selectedStreamColor}
          taskTypeLookup={taskTypeLookup}
          streamLookup={streamLookup}
        />
        <TaskAdvancedSettings iconColor={selectedStreamColor} readOnly={readOnly} />
        <TaskOtherDetails
          iconColor={selectedStreamColor}
          createdBy={task.author_name}
          createdAt={task.created_at}
          updatedAt={task.updated_at}
          timezone={timezone}
        />
        <TaskWebhooks iconColor={selectedStreamColor} taskInternalId={task.internal_id} runbookId={runbookId} />
      </Accordion>
    </>
  )
}

const TaskTypeSelectWrapper = styled(Box)<{ isTaskTypeCommsOff: boolean }>`
  flex-grow: 1;
  max-width: ${({ isTaskTypeCommsOff }) => (isTaskTypeCommsOff ? '100%' : '50%')};
`

const IntegrationIcon = styled.img`
  height: 22px;
  width: 22px;
`
const MessageStatusWrapper = styled(Box)`
  display: inline-block;
  position: relative;
  top: 5px;
  padding-bottom: 20px;
`
