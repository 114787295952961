import { DragEvent, useCallback, useEffect, useRef, useState } from 'react'
import {
  RecoilValueReadOnly,
  SetterOrUpdater,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useResetRecoilState,
  useSetRecoilState
} from 'recoil'
import { isEqual, keyBy, mapValues } from 'lodash'

import { toggleRightPanelLayout } from '@cutover/react-ui'
import { currentUserState } from '../current-user'
import { CurrentUser } from 'main/services/queries/use-get-validate-token'
import {
  Favorite,
  RunbookEditRunbook,
  RunbookTeam,
  StreamListStream,
  TaskListTask,
  TaskType
} from 'main/services/queries/types'
import {
  accountMetaState,
  accountSlugUrlParamState,
  accountTaskTypeLookup,
  favoritesState,
  favoriteStateById,
  filteredTasksState,
  newTaskStreamState,
  runbookIdState,
  runbookUrlParamState,
  runbookVersionIdState,
  streamsLookupState,
  streamTaskCountsRecordState,
  taskListLookupState,
  taskListTaskState,
  teamIdToTaskRecord,
  usersLookupState,
  usersState,
  userTasksCountState
} from '../runbook'
import { AccountResponseMeta, fetchAccountQuery } from 'main/services/api/data-providers/account/use-get-account-data'
import { useToggleFavorite } from 'main/services/queries/use-favorites'
import { runbookEditUpdatedRunbook } from '../runbook/models/runbook/runbook-edit'
import {
  RunbookCommentCreateResponse,
  RunbookResponse,
  RunbookStreamShowResponse,
  RunbookTaskBulkDeleteResponse,
  RunbookTaskBulkSkipResponse,
  RunbookTaskBulkUpdateResponse,
  RunbookTaskFinishResponse,
  RunbookTaskShowResponse,
  RunbookTaskStartResponse,
  RunbookTaskUpdateResponse
} from 'main/services/api/data-providers/runbook-types'
import { getRunbookVersion, RunbookVersionUser } from 'main/services/queries/use-runbook-versions'
import { taskEditTaskTypesState, taskEditUpdatedState } from '../runbook/models/tasks/task-edit'
import { quickUpdateTask, QuickUpdateTaskPayload } from 'main/services/queries/use-task'
import { useLanguage } from 'main/services/hooks'
import {
  activeTasksFilterState,
  ancestorsFilterState,
  appliedFilterState,
  assignedFilterState,
  auditLogUserFilterState,
  completionTypeFilterState,
  createdAfterFilterState,
  createdBeforeFilterState,
  criticalPathFilterState,
  criticalPathToHereFilterState,
  customFieldFilterSelector,
  dateFromFilterState,
  dateToFilterState,
  dateWithinFilterState,
  endRequirementsFilterState,
  filterCount,
  filterSelector,
  fixedEndFilterState,
  fixedStartFilterState,
  hasCommentsFilterState,
  hasErrorsFilterState,
  hasFiltersState,
  hasPredecessorsFilterState,
  hasSuccessorsFilterState,
  labelsState,
  lateFilterState,
  milestoneFilterState,
  myTasksFilterState,
  nowDateAtom,
  objectTypeFilterState,
  overRunningFilterState,
  runbookComponentFilterState,
  runbookLevelFilterState,
  runbookTeamFilterState,
  runbookTeamIncludeUsersState,
  runbookTypeAccountFilterState,
  runbookTypeGlobalFilterState,
  runbookUserFilterState,
  searchAtom,
  searchQueryFilterState,
  stageFilterState,
  startNotificationFilterState,
  startRequirementsFilterState,
  streamFilterState,
  taskInternalIdFilterState,
  taskTypeFilterState
} from '../shared/filters'
import { AuditLogFilterType, RunbookFilterType, RunbookUsersFilterType } from 'main/services/tasks/filtering'
import {
  fieldOptionCountsState,
  runbookFilterCustomFieldsState
} from '../runbook/models/runbook-version/custom-fields/custom-fields-filters'
import { runbookAtom } from '../shared/recoil-state-runbook-decorators'
import {
  CloseRightPanelAction,
  OpenRightPanelAction,
  OpenRightPanelTypeProps,
  RightPanel,
  RightPanelType,
  TogglePanelFunctionType,
  useGetActiveRightPanelValue
} from 'main/components/layout/right-panel'
import { getTasks } from 'main/services/queries/use-tasks'
import { RunbookTypeFilterType } from 'main/services/settings/filtering'
import { useProcessTaskQuickUpdateResponse, useRunbookNotifications } from 'main/data-access/hooks'

/* -------------------------------------------------------------------------- */
/*                                Current User                                */
/* -------------------------------------------------------------------------- */

/**
 * Use CurrentUserModel.useGet() instead.
 *
 * This hook is used by `use-has-permissions-or-navigate-home.ts` file. The UI breaks when the model is used directly in that file.
 * Working theory is that recoil state is set to a default value due to imports in this temporary data access files, causing the model references to break if the hook below is not used.
 *
 * FIXME: investigate how to break this dependency and use model methods directly.
 * @deprecated
 */
export const useCurrentUser = () => useRecoilValue(currentUserState) as CurrentUser // if using this hook can assume we're authenticated

/* -------------------------------------------------------------------------- */
/*                                 Permissions                                */
/* -------------------------------------------------------------------------- */

export type TaskShowPermissions = ReturnType<typeof getTaskShowPermissions>
export type StreamShowPermissions = ReturnType<typeof getStreamShowPermissions>

export const useGetTaskShowPermissions = () => {
  const { id: currentUserId } = useCurrentUser()

  return useCallback((resp: RunbookTaskShowResponse) => getTaskShowPermissions(resp, currentUserId), [currentUserId])
}

const getTaskShowPermissions = (resp: RunbookTaskShowResponse, currentUserId: number) => {
  return mapValues(resp.meta.permissions, v => v?.includes(currentUserId))
}

export const useGetStreamShowPermissions = () => {
  const { id: currentUserId } = useCurrentUser()

  return useCallback(
    (resp: RunbookStreamShowResponse) => getStreamShowPermissions(resp, currentUserId),
    [currentUserId]
  )
}

const getStreamShowPermissions = (resp: RunbookStreamShowResponse, currentUserId: number) => {
  return mapValues(resp.meta.permissions, v => v?.includes(currentUserId))
}

/* -------------------------------------------------------------------------- */
/*                                   Account                                  */
/* -------------------------------------------------------------------------- */

export const useAccountSlugUrlParamState = () => useRecoilValue(accountSlugUrlParamState)

// NOTE: the only remaining use of this is for the headers to get the offset in the runbook elapsed timer
export const useAccountMetaProperty = <TKey extends keyof AccountResponseMeta>({ attribute }: { attribute: TKey }) =>
  useRecoilValue(accountMetaState)[attribute]

export const useTaskSelectDataCallback = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async ({
        accountSlug,
        runbookVersionId,
        runbookId
      }: {
        accountSlug?: string
        runbookVersionId?: number
        runbookId?: number
      }) => {
        const accountSlugUrlParam = await snapshot.getPromise(accountSlugUrlParamState)
        const runbookVersionUrlId = await snapshot.getPromise(runbookUrlParamState)

        let taskTypeLookup: Record<number, TaskType> = {}
        let streamLookup: Record<number, StreamListStream> = {}
        let taskLookup: Record<number, TaskListTask> = {}

        if (accountSlug === accountSlugUrlParam && runbookVersionUrlId) {
          taskTypeLookup = await snapshot.getPromise(accountTaskTypeLookup)
        } else if (accountSlug) {
          const { meta } = await fetchAccountQuery(accountSlug)
          taskTypeLookup = keyBy(meta.task_types, 'id')
        }

        if (runbookVersionId === runbookVersionUrlId) {
          streamLookup = await snapshot.getPromise(streamsLookupState)
          taskLookup = await snapshot.getPromise(taskListLookupState)
        } else if (runbookId && runbookVersionId) {
          const { meta } = await getRunbookVersion(runbookId, runbookVersionId)
          const flattenedStreams = meta.streams.flatMap(stream => [stream, ...(stream.children ?? [])])
          streamLookup = keyBy(flattenedStreams, 'id')

          const { tasks } = await getTasks(runbookId, runbookVersionId)
          taskLookup = keyBy(tasks, 'id')
        }

        return {
          taskTypeLookup,
          streamLookup,
          taskLookup
        }
      },
    []
  )

/* -------------------------------------------------------------------------- */
/*                                Custom Fields                               */
/* -------------------------------------------------------------------------- */

export const useFieldOptionCountsState = (id: number) => {
  return useRecoilValue(fieldOptionCountsState(id))
}

/* -------------------------------------------------------------------------- */
/*                                  Favorites                                 */
/* -------------------------------------------------------------------------- */

export const useSetFavoritesState = () => {
  return useSetRecoilState(favoritesState)
}

export const useToggleRecoilFavorites = ({
  id
}: {
  id: number
}): [Favorite | undefined, (payload: Favorite) => void] => {
  const favorite = useRecoilValue(favoriteStateById(id))
  const mutation = useToggleFavorite()

  const toggleFavorite = useCallback(
    (payload: Favorite) => {
      mutation.mutate(payload)
    },
    [mutation]
  )

  return [favorite, toggleFavorite]
}

/* -------------------------------------------------------------------------- */
/*                                Runbook Edit                                */
/* -------------------------------------------------------------------------- */

export const useRunbookEditUpdatedRunbook = (): [RunbookEditRunbook, (newValue: RunbookEditRunbook) => void] => {
  const [updatedRunbook, setUpdatedRunbook] = useRecoilState<RunbookEditRunbook>(runbookEditUpdatedRunbook)

  return [updatedRunbook, setUpdatedRunbook]
}

/* -------------------------------------------------------------------------- */
/*                                   Runbook                                  */
/* -------------------------------------------------------------------------- */

export const useRunbookUrlParamState = () => {
  return useRecoilValue(runbookUrlParamState)
}

/* -------------------------------------------------------------------------- */
/*                                   Filters                                  */
/* -------------------------------------------------------------------------- */

export type UniversalFilterType = RunbookFilterType &
  RunbookTypeFilterType &
  RunbookUsersFilterType &
  AuditLogFilterType

export const useNowDate = () => {
  const setDate = useSetRecoilState(nowDateAtom)
  return {
    setNowDate: () => setDate(Math.floor(Date.now() / 1000)),
    resetNowDate: () => setDate(0)
  }
}

export const useAppliedFilters = () => {
  return useRecoilValue(appliedFilterState)
}

export function useAppliedFiltersCallback() {
  return useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const appliedFilters = await snapshot.getPromise(appliedFilterState)
        return appliedFilters
      },
    []
  )
}

export const useFilteredTasksState = () => {
  return useRecoilValue(filteredTasksState)
}

export const useFilterCount = () => {
  return useRecoilValue(filterCount)
}

export const useHasFiltersState = () => {
  return useRecoilValue(hasFiltersState)
}

export const useRunbookFilterCustomFieldsState = () => {
  return useRecoilValue(runbookFilterCustomFieldsState)
}

export const useFilterLabelsState = () => {
  return useRecoilValue(labelsState)
}

export function useClearFilterState<K extends keyof UniversalFilterType>(key: K) {
  return useResetRecoilState(filterSelector({ attribute: key })) as any
}

export const useSetFilterState = (param: keyof UniversalFilterType) => {
  return useSetRecoilState(filterSelector({ attribute: param })) as unknown as SetterOrUpdater<any>
}

// WARNING: this clears all query params -- even if we don't consider them a "filter". this needs to be updated
// https://cutover.atlassian.net/browse/CFE-1441
export const useClearAllFilterState = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const appliedFilters = await snapshot.getPromise(appliedFilterState)

        Object.keys(appliedFilters).forEach(key => {
          set(filterSelector({ attribute: key as keyof UniversalFilterType }), undefined)
        })

        set(searchAtom, '')
      },
    []
  )

export const useCustomFieldFilterState = (id: number) => {
  const setCustomFieldProperty = useSetRecoilState(customFieldFilterSelector({ attribute: id }))
  const value = useCustomMemoizedFieldFilterValue(id) as any

  return [value, setCustomFieldProperty] as const
}

export const useCustomFieldState = () => {
  return useRecoilState(filterSelector({ attribute: 'f' }))
}

export const useCustomFieldAppliedFiltersState = () => {
  const { f: cfFilters } = useAppliedFilters()
  return cfFilters
}

const useCustomMemoizedFieldFilterValue = (id: number) => {
  const cfFilters = useCustomFieldAppliedFiltersState()
  const cfFiter = cfFilters?.[id]
  const memoizedVal = useRef<any>(cfFiter)

  // NOTE: adds side effect to functions using this, but here to improve performance
  useEffect(() => {
    if (!isEqual(cfFiter, memoizedVal.current)) {
      memoizedVal.current = cfFiter
    }
  }, [cfFiter])

  return !isEqual(memoizedVal.current, cfFiter) ? cfFiter : memoizedVal.current
}

function createFilterHook<T>(key: keyof UniversalFilterType, selector: RecoilValueReadOnly<T>) {
  return () => {
    const value = useRecoilValue(selector)
    const setValue = useSetRecoilState(filterSelector({ attribute: key })) as unknown as SetterOrUpdater<T>

    return [value, setValue] as [typeof value, typeof setValue]
  }
}

export const useRunbookTypesGlobalFilter = createFilterHook('global', runbookTypeGlobalFilterState)
export const useRunbookTypesAccountFilter = createFilterHook('account_id', runbookTypeAccountFilterState)
export const useRunbookLevelFilter = createFilterHook('lv', runbookLevelFilterState)
export const useRunbookUserFilter = createFilterHook('user', runbookUserFilterState)
export const useRunbookTeamFilter = createFilterHook('team', runbookTeamFilterState)
export const useRunbookTeamIncludeUsersFilter = createFilterHook('includeUsers', runbookTeamIncludeUsersState)
export const useCriticalPathFilter = createFilterHook('critical', criticalPathFilterState)
export const useMilestoneFilter = createFilterHook('m', milestoneFilterState)
export const useDateWithinFilter = createFilterHook('dd', dateWithinFilterState)
export const useStartNotificationFilter = createFilterHook('sn', startNotificationFilterState)
export const useFixedStartFilter = createFilterHook('fs', fixedStartFilterState)
export const useFixedEndFilter = createFilterHook('fe', fixedEndFilterState)
export const useHasCommentsFilter = createFilterHook('c', hasCommentsFilterState)
export const useLateFilter = createFilterHook('l', lateFilterState)
export const useOverRunningFilter = createFilterHook('or', overRunningFilterState)
export const useHasPredecessorsFilter = createFilterHook('hp', hasPredecessorsFilterState)
export const useHasSuccessorsFilter = createFilterHook('hs', hasSuccessorsFilterState)
export const useHasErrorsFilter = createFilterHook('he', hasErrorsFilterState)
export const useMyTasksFilter = createFilterHook('mt', myTasksFilterState)
export const useActiveTasksFilter = createFilterHook('at', activeTasksFilterState)
export const useDateFromFilter = createFilterHook('df', dateFromFilterState)
export const useDateToFilter = createFilterHook('dt', dateToFilterState)
export const useCompletionTypeFilter = createFilterHook('ct', completionTypeFilterState)
export const useStartRequirementsFilter = createFilterHook('sr', startRequirementsFilterState)
export const useEndRequirementsFilter = createFilterHook('er', endRequirementsFilterState)
export const useAssignedFilter = createFilterHook('a', assignedFilterState)
export const useStageFilter = createFilterHook('stage', stageFilterState)
export const useStreamFilter = createFilterHook('stream', streamFilterState)
export const useSearchQueryFilter = createFilterHook('q', searchQueryFilterState)
export const useTaskTypeFilter = createFilterHook('type', taskTypeFilterState)
export const useCriticalPathToHereFilter = createFilterHook('critical_to_here', criticalPathToHereFilterState)
export const useAncestorsFilter = createFilterHook('predecessors_to_here', ancestorsFilterState)
export const useRunbookComponentFilter = createFilterHook('rbc', runbookComponentFilterState)

// Audit Log
export const useCreatedAfterFilter = createFilterHook('created_after', createdAfterFilterState)
export const useCreatedBeforeFilter = createFilterHook('created_before', createdBeforeFilterState)
export const useAuditLogUserFilter = createFilterHook('author_id', auditLogUserFilterState)
export const useObjectTypeFilter = createFilterHook('object_type', objectTypeFilterState)
export const useTaskInternalIdFilter = createFilterHook('task_id', taskInternalIdFilterState)

/* -------------------------------------------------------------------------- */
/*                                   Streams                                  */
/* -------------------------------------------------------------------------- */

// TODO: put on Filter Model
export const useStreamTaskCountsRecordState = () => {
  return useRecoilValue(streamTaskCountsRecordState)
}

/* -------------------------------------------------------------------------- */
/*                                  Task Edit                                 */
/* -------------------------------------------------------------------------- */

export const useTaskEditTaskTypes = () => {
  return useRecoilValue(taskEditTaskTypesState)
}

export const useTaskEditTaskTypesLoadable = () => {
  return useRecoilValueLoadable(taskEditTaskTypesState)
}

export const useTaskTypeRemovedName = (taskTypeId: number) => {
  const taskTypeLookup = useRecoilValue(accountTaskTypeLookup)
  const currentTaskType = taskTypeLookup[taskTypeId]
  const taskTypeName = currentTaskType?.integration_action_items[0]?.name || currentTaskType?.name

  return currentTaskType?.archived ? `${taskTypeName} [Archived]` : undefined
}

export const useTaskEditUpdatedState = () => {
  return useRecoilState(taskEditUpdatedState)
}

export const useNewTaskStreamId = ({ prevTaskStreamId }: { prevTaskStreamId?: number } = {}) => {
  return useRecoilValue(newTaskStreamState({ prevTaskStreamId }))
}

/* -------------------------------------------------------------------------- */
/*                                Teams / Users                               */
/* -------------------------------------------------------------------------- */

type DragUserTeamData = {
  type: 'runbook_team' | 'user'
  id: number
}

// TODO: add to filter model
export const useTeamIdToTaskRecord = () => useRecoilValue(teamIdToTaskRecord)

export const useDropAssignment = ({
  canDrop,
  teams,
  users
}: {
  canDrop: boolean
  teams: RunbookTeam[]
  users: RunbookVersionUser[]
}) => {
  const [isLoadingAvatar, setIsLoadingAvatar] = useState(false)

  const isDropPermitted = useCallback(
    (e: DragEvent) => {
      if (!e.dataTransfer || !e.dataTransfer.getData('text')) {
        return false
      }

      if (!canDrop) {
        return false
      }

      const transferData = JSON.parse(e.dataTransfer.getData('text'))

      // Check if transfer is redundant (users/teams already exist)
      if (
        (transferData.type === 'runbook_team' && !!teams.find(team => team.id === transferData.id)) ||
        (transferData.type === 'user' && !!users.find(user => user.id === transferData.id))
      ) {
        return false
      }

      return true
    },
    // we want the dependency array to be based on the values inside these arrays, will fix in followup such that
    // those arrays are stable based on their contents.
    [canDrop, users, teams]
  )

  // don't use models in this file directly
  const handleTaskDropAssignment = useProcessTaskQuickUpdateResponse()

  const handleDropAssign = useRecoilCallback(
    ({ snapshot }) =>
      async ({ taskId, data }: { taskId: number; data: DragUserTeamData }) => {
        const runbookId = await snapshot.getPromise(runbookIdState)
        const runbookVersionId = await snapshot.getPromise(runbookVersionIdState)
        const task = await snapshot.getPromise(taskListTaskState(taskId))

        setIsLoadingAvatar(true)

        const payload = {} as QuickUpdateTaskPayload
        if (data.type === 'runbook_team') {
          payload.runbook_teams = [Number(data.id), ...task.runbook_team_ids]
        } else if (data.type === 'user') {
          payload.users = [Number(data.id), ...task.user_ids]
        }

        const response = await quickUpdateTask({ runbookId, runbookVersionId, taskId, payload })

        handleTaskDropAssignment(response)

        setIsLoadingAvatar(false)
      },
    [handleTaskDropAssignment]
  )

  return {
    handleDropAssign,
    isLoadingAvatar,
    isDropPermitted
  }
}

export const useRunbookVersionUsers = () => {
  return useRecoilValue(usersState)
}

export const useUsersLookupState = () => {
  return useRecoilValue(usersLookupState)
}

export const useUsersLookupStateCallback = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(usersLookupState),
    []
  )

export const useUserTasksCountState = () => {
  return useRecoilValue(userTasksCountState)
}

export const useTaskListTaskUsers = (taskId: number) => {
  const lookup = useRecoilValue(usersLookupState)
  const userIds = useRecoilValue(taskListTaskState(taskId)).user_ids
  return userIds?.map(id => lookup[id])
}

/* -------------------------------------------------------------------------- */
/*                                Notifications                               */
/* -------------------------------------------------------------------------- */

export const useCommentNotifications = () => {
  const { t } = useLanguage('notification')
  const notify = useRunbookNotifications()
  const { id: currentUserId } = useCurrentUser()

  const commentCreateNotification = useCallback(
    (response: RunbookCommentCreateResponse) => {
      if (response.meta.headers.request_user_id === currentUserId) return

      let message
      const userName = response.meta.headers.request_user_name
      if (response.comment.task_id) {
        message = t('comment.created.userMessageTask', { userName, taskName: response.comment.task?.name })
      } else {
        message = t('comment.created.userMessageRunbook', { userName })
      }
      notify.success(message, { title: t('comment.created.title') })
    },
    [currentUserId, notify, t]
  )

  const notifyCommentAction = useCallback(
    (response: RunbookResponse) => {
      switch (response.meta.headers.request_method) {
        case 'create':
          return commentCreateNotification(response as RunbookCommentCreateResponse)
        default:
          return () => {}
      }
    },
    [commentCreateNotification]
  )

  return {
    commentCreateNotification,
    notifyCommentAction
  }
}

export const useTaskNotifications = () => {
  const notify = useRunbookNotifications()
  const { id: currentUserId } = useCurrentUser()
  const { getActiveRightPanel } = useGetActiveRightPanelValue()
  const { t } = useLanguage('notification')

  const taskStartOrFinishNotification = useCallback(
    (response: RunbookTaskStartResponse | RunbookTaskFinishResponse, messageKey: string) => {
      let message
      const taskName = response.task.name
      if (response.meta.headers.request_user_id === currentUserId) {
        message = t(`task.${messageKey}.currentUserMessage`, { taskName })
      } else {
        const userName = response.meta.headers.request_user_name
        message = t(`task.${messageKey}.userMessage`, { userName, taskName })
      }
      notify.success(message, { title: t(`task.${messageKey}.title`) })
    },
    [currentUserId, notify, t]
  )

  const taskStartNotification = useCallback(
    (response: RunbookTaskStartResponse) => {
      const messageKey = response.task.stage === 'startable' ? 'startable' : 'started'
      taskStartOrFinishNotification(response, messageKey)
    },
    [taskStartOrFinishNotification]
  )

  const taskFinishNotification = useCallback(
    (response: RunbookTaskFinishResponse) => {
      const messageKey = response.task.stage === 'in-progress' ? 'finishable' : 'finished'
      taskStartOrFinishNotification(response, messageKey)
    },
    [taskStartOrFinishNotification]
  )

  const taskSkippedNotification = useCallback(
    (response: RunbookTaskBulkSkipResponse) => {
      let message
      if (response.meta.headers.request_user_id === currentUserId) {
        message = t('task.skipped.currentUserMessage')
      } else {
        const userName = response.meta.headers.request_user_name
        message = t('task.skipped.userMessage', { userName })
      }
      notify.success(message, { title: t('task.skipped.title') })
    },
    [currentUserId, notify, t]
  )

  const taskUpdateNotification = useCallback(
    (response: RunbookTaskUpdateResponse) => {
      let message
      if (response.meta.headers.request_user_id === currentUserId) {
        message = t('task.updated.currentUserMessage', { taskName: response.task.name })
      } else {
        const userName = response.meta.headers.request_user_name
        message = t('task.updated.userMessage', { userName, taskName: response.task.name })
      }
      notify.success(message, { title: t('task.updated.title') })
    },
    [currentUserId, notify, t]
  )

  const taskBulkUpdateNotification = useCallback(
    (response: RunbookTaskBulkUpdateResponse) => {
      let message
      if (response.meta.headers.request_user_id === currentUserId) {
        message = t('task.bulkUpdated.currentUserMessage')
      } else {
        const userName = response.meta.headers.request_user_name
        message = t('task.bulkUpdated.userMessage', { userName })
      }
      notify.success(message, { title: t('task.bulkUpdated.title') })
    },
    [currentUserId, notify, t]
  )

  const taskBukDeleteNotification = useCallback(
    (response: RunbookTaskBulkDeleteResponse) => {
      const userName = response.meta.headers.request_user_name
      const activeRightPanel = getActiveRightPanel()

      if (activeRightPanel?.type === 'tasks-bulk-edit') {
        if (activeRightPanel.taskIds.every(id => response.meta.deleted_task_ids?.includes(id))) {
          notify.warning(t('task.closeBulkEdit.message', { userName }), {
            title: t('task.closeBulkEdit.title')
          })
        } else if (activeRightPanel.taskIds.some(id => response.meta.deleted_task_ids?.includes(id))) {
          notify.warning(t('task.warnBulkEdit.message', { userName }), {
            title: t('task.warnBulkEdit.title')
          })
        }
      } else if (
        activeRightPanel?.type === 'task-edit' &&
        response.meta.deleted_task_ids?.includes(activeRightPanel.taskId)
      ) {
        notify.warning(t('task.closeSingleEdit.message', { userName }), {
          title: t('task.closeSingleEdit.title')
        })
      }
    },
    [getActiveRightPanel, notify, t]
  )

  const notifyTaskAction = useCallback(
    (response: RunbookResponse) => {
      switch (response.meta.headers.request_method) {
        case 'start':
          return taskStartNotification(response as RunbookTaskStartResponse)
        case 'finish':
          return taskFinishNotification(response as RunbookTaskFinishResponse)
        case 'bulk_skip':
          return taskSkippedNotification(response as RunbookTaskBulkSkipResponse)
        case 'update':
          return taskUpdateNotification(response as RunbookTaskUpdateResponse)
        case 'bulk_update':
          return taskBulkUpdateNotification(response as RunbookTaskBulkUpdateResponse)
        case 'bulk_delete':
          return taskBukDeleteNotification(response as RunbookTaskBulkDeleteResponse)
        default:
          return () => {}
      }
    },
    [
      taskBulkUpdateNotification,
      taskFinishNotification,
      taskSkippedNotification,
      taskStartNotification,
      taskUpdateNotification,
      taskBukDeleteNotification
    ]
  )

  return {
    taskStartNotification,
    taskFinishNotification,
    taskSkippedNotification,
    taskUpdateNotification,
    taskBulkUpdateNotification,
    taskBukDeleteNotification,
    notifyTaskAction
  }
}

/* -------------------------------------------------------------------------- */
/*                                 Right Panel                                */
/* -------------------------------------------------------------------------- */

// TODO: make global view model

export const activeRightPanel_INTERNAL = runbookAtom<RightPanel | null>({
  key: 'right-panel',
  default: null
})

export const useActiveRightPanelValue = () => {
  return useRecoilValue(activeRightPanel_INTERNAL)
}

export const useRightPanelHookFunctions_INTERNAL = <T extends RightPanelType | undefined = undefined>(
  panelType?: T
) => {
  const openRightPanel = useRecoilCallback(
    ({ set }) =>
      args => {
        if (args.type && !panelType) {
          set(activeRightPanel_INTERNAL, args)
        } else if (panelType) {
          // @ts-ignore For some reason panelType throws a ts error even though RightPanel has all the correct panels defined.
          // Ignoring this error for now unless someone can figure out why the typing is incorrect, it seems as if we hit a
          // TS bug/limit with the amount of right hand panels we have defined.
          set(activeRightPanel_INTERNAL, { ...args, type: panelType })
        }

        toggleRightPanelLayout(true)
      },
    [panelType]
  ) as OpenRightPanelAction<T>

  const closeRightPanel = useRecoilCallback(
    ({ reset, snapshot }) =>
      () => {
        const activePanel = snapshot.getLoadable(activeRightPanel_INTERNAL).getValue()

        if (activePanel) {
          activePanel?.onClose?.()
          reset(activeRightPanel_INTERNAL)
          toggleRightPanelLayout(false)
        }
      },
    []
  ) as CloseRightPanelAction

  const getActiveRightPanelCallback = useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(activeRightPanel_INTERNAL).getValue(),
    []
  )

  const useToggleRightPanel = <T extends RightPanelType>(
    panelType: T,
    defaultMatcher?: (activePanel: OpenRightPanelTypeProps<T>, otherPanel: OpenRightPanelTypeProps<T>) => boolean
  ) => {
    const { openRightPanel, closeRightPanel } = useRightPanelHookFunctions_INTERNAL()

    return useRecoilCallback(
      ({ snapshot }) =>
        (openPanelTypeProps, opts = {}) => {
          if (openPanelTypeProps === false) {
            closeRightPanel()
            return
          }

          const activeRightPanel = snapshot.getLoadable(activeRightPanel_INTERNAL).getValue()
          const nextPanel = { type: panelType, ...openPanelTypeProps } as RightPanel

          if (!activeRightPanel) {
            openRightPanel(nextPanel)
            return
          }

          const { matcher = defaultMatcher || isEqual } = opts
          const { onClose: _onNewPanelClose, ...restOpenPanel } = nextPanel
          const { onClose: _onActivePanelClose, ...restActivePanel } = activeRightPanel
          const isMatchedPanelOpen =
            activeRightPanel.type === panelType && matcher(restActivePanel as any, restOpenPanel as any)

          isMatchedPanelOpen ? closeRightPanel() : openRightPanel(nextPanel)
        },
      [closeRightPanel, defaultMatcher, openRightPanel, panelType]
    ) as TogglePanelFunctionType<T>
  }

  return {
    useToggleRightPanel,
    openRightPanel,
    closeRightPanel,
    getActiveRightPanelCallback
  }
}

export type MenuType = 'taskTypes' | 'usersAndTeams' | 'streams'
