import { keyBy } from 'lodash'

import { GetAccountResponseAndComputedPropertiesState } from 'main/recoil/runbook'
import { GetAccountResponseType } from 'main/services/api/data-providers/account/use-get-account-data'
import { CustomField } from 'main/services/queries/types'

const extend = (
  draftAccountResponse: GetAccountResponseType | GetAccountResponseAndComputedPropertiesState,
  data?: {
    features: {
      conditionalLogic: boolean
    }
    previousCFLookups?: GetAccountResponseAndComputedPropertiesState['computed']['customFields']
  }
): GetAccountResponseAndComputedPropertiesState => {
  if (!(draftAccountResponse as GetAccountResponseAndComputedPropertiesState).computed) {
    ;(draftAccountResponse as GetAccountResponseAndComputedPropertiesState).computed = {}
  }

  updateParentChildCustomFieldLookup(draftAccountResponse as GetAccountResponseAndComputedPropertiesState, {
    features: data?.features,
    previous: data?.previousCFLookups
  })

  return draftAccountResponse as GetAccountResponseAndComputedPropertiesState
}

/* ------------------- Conditional Progression Integration ------------------ */

const CONDITIONAL_PROGRESSION = {
  CF_NAME: 'Conditional Logic: Conditional Logic Task Progression',
  CF_TYPE: 'SearchableCustomField',
  PARENT_KEY: 'Source Task',
  CHILD_KEY: 'Custom field'
}

const updateParentChildCustomFieldLookup = (
  draftAccountResponse: GetAccountResponseAndComputedPropertiesState,
  {
    features,
    previous
  }: {
    features?: {
      conditionalLogic: boolean
    }
    previous?: GetAccountResponseAndComputedPropertiesState['computed']['customFields']
  }
) => {
  if (!features?.conditionalLogic) return
  if (draftAccountResponse.computed.customFields) return
  if (previous) {
    draftAccountResponse.computed.customFields = previous
    return
  }

  let parentChildLookup: { [key: string]: CustomField } = {}
  let childParentLookup: { [key: string]: CustomField } = {}
  let cfLookup: Record<string, CustomField> | undefined

  for (const tt of draftAccountResponse.meta.task_types) {
    if (
      tt.integration_action_items.some(
        iai => iai.integration_action === 'Integrations::ConditionalLogic::CustomFieldTaskProgression'
      )
    ) {
      for (const conditionalLogicIAI of tt.integration_action_items) {
        if (!cfLookup)
          cfLookup = keyBy(
            draftAccountResponse.meta.custom_fields.filter(cf => /^.+: .+: .+$/.test(cf.name)),
            'name'
          )

        // The keys of conditional logic int action items look like `${integration name}: ${iai name}: ${valueKey}
        // but there's no way to determine what the integration name is from the integration action item (aka, at this point in
        // execution). To get around it in a fun and brittle way, we're going to check for the last part of the name, check it doesn't
        // already exist, and then use what's returned as the parent key.
        const parentKey = Object.keys(cfLookup).find(
          key =>
            key.includes(`${conditionalLogicIAI.name}: ${CONDITIONAL_PROGRESSION.PARENT_KEY}`) &&
            !parentChildLookup[cfLookup?.[key]?.id || 0] &&
            !childParentLookup[cfLookup?.[key]?.id || 0]
        )

        const keyPrefix = parentKey?.split(`: ${CONDITIONAL_PROGRESSION.PARENT_KEY}`)[0]
        if (!keyPrefix) continue

        const parent = cfLookup[parentKey]
        const child = cfLookup[`${keyPrefix}: ${CONDITIONAL_PROGRESSION.CHILD_KEY}`]

        if (parent) parentChildLookup[parent.id] = child
        if (child) childParentLookup[child.id] = parent
      }
    }
  }

  draftAccountResponse.computed.customFields = { parentChildLookup, childParentLookup }

  return draftAccountResponse
}

export const accountResponseDataHelpers = {
  extend
}
