import { useCallback, useMemo } from 'react'
import { format as formatDate, fromUnixTime } from 'date-fns'

import { Avatar, Box, ColumnConfig, NoResourceFound, Table, Text } from '@cutover/react-ui'
import { useCentralTeams } from 'main/services/api/data-providers/workspace'
import {
  useClearFilterParam,
  useClearFilterParams,
  useFilter,
  useSetFilterParams
} from 'main/components/shared/filter/filter-provider'
import { useToggleRightPanel } from 'main/components/layout/right-panel'
import { useCentralTeamsInitialTeam } from './use-central-teams-initial-team'

type TeamList = {
  name: string
  users_count: number
  updated_at: number
}

export const CentralTeamsPage = () => {
  const { centralTeams, fetchNextPage, isFetchingNextPage, isLoading } = useCentralTeams()
  const toggleRightPanel = useToggleRightPanel('central-team-edit')

  useCentralTeamsInitialTeam()

  const sortBy = useFilter<string>('order')
  const sortDir = useFilter<'asc' | 'desc'>('sort_direction')

  const setFilters = useSetFilterParams()
  const clearFilter = useClearFilterParam()
  const clearAll = useClearFilterParams()

  const handleSort = useCallback(
    (sort?: { property: string; direction: 'desc' | 'asc' }) => {
      if (!sort) {
        clearFilter('order')
        clearFilter('sort_direction')
      } else {
        setFilters({ order: sort.property, sort_direction: sort.direction })
      }
    },
    [setFilters, clearFilter]
  )

  const handleClickRow = useCallback(({ datum }: { datum: any }) => {
    toggleRightPanel({ teamId: datum.id })
  }, [])

  const columns: ColumnConfig<TeamList>[] = useMemo(
    () => [
      {
        property: 'avatar',
        header: '',
        render: (datum: TeamList) => <Avatar subject={datum} size="small" />,
        size: '41px',
        sortable: false
      },
      {
        property: 'name',
        header: 'Name',
        size: '300px',
        sortable: true
      },
      {
        property: 'users_count',
        header: 'Members',
        size: '70px',
        sortable: true
      },
      {
        property: 'updated_at',
        header: 'Updated at',
        render: (datum: TeamList) => <Text>{formatDate(fromUnixTime(datum.updated_at), 'dd MMM yyyy HH:mm')}</Text>,
        size: '140px',
        sortabal: true
      }
    ],
    []
  )

  const sort = useMemo(() => {
    return sortBy ? { property: sortBy, direction: sortDir ?? 'asc', external: true } : undefined
  }, [sortBy, sortDir])

  return (
    <Box
      pad={{ right: '16px' }}
      id="tabpanel-teams"
      aria-labelledby="tab-central-teams"
      data-testid="central-teams-page"
    >
      {!isLoading && centralTeams?.length === 0 ? (
        <NoResourceFound context="team" clearAllFilters={() => clearAll()} />
      ) : (
        <Table
          replace
          columns={columns}
          data={centralTeams ?? undefined}
          onClickRow={handleClickRow}
          onMore={fetchNextPage}
          onSort={handleSort}
          sort={sort}
          sortable="server"
          isLoadingMore={isFetchingNextPage}
          isLoading={isLoading}
        />
      )}
    </Box>
  )
}
