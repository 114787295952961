import * as yup from 'yup'

import { Box, Text } from '@cutover/react-ui'
import { CheckboxField, FileInputField, FormModal } from 'main/components/shared/form'
import { useLanguage } from 'main/services/hooks'
import { importUserTeam } from 'main/services/queries/use-users-and-teams-query'
import { ActiveRunbookVersionModel, RunbookViewModel } from 'main/data-access'
import { RunbookVersionCreateResponse } from 'main/services/api/data-providers/runbook-types'

const validationSchema = yup.object().shape({
  file: yup.mixed().required('You must choose a file'),
  overwrite: yup.bool()
})

export type CsvImportForm = yup.InferType<typeof validationSchema>

type ImportUsersModalProps = {
  isOpen: boolean
  onClose: () => void
  runbookId: number | string
  runbookVersionId: number | string
}

export const ImportUsersModal = ({ isOpen, onClose, runbookId, runbookVersionId }: ImportUsersModalProps) => {
  const { t } = useLanguage('common', { keyPrefix: 'importModal' })
  const { t: commonT } = useLanguage('common')
  const notify = RunbookViewModel.useAction('notify')
  const processRunbookVersionCreateResponse = ActiveRunbookVersionModel.useOnAction('create')

  const onSubmit = async (data: CsvImportForm) => {
    const formData = new FormData()

    formData.append('file', data.file as File)
    formData.append('overwrite', JSON.stringify(data.overwrite))

    return await importUserTeam({ formData, runbookId, runbookVersionId })
  }

  const onSuccess = (response: RunbookVersionCreateResponse) => {
    // New version is created on user/team import
    processRunbookVersionCreateResponse(response)
    notify.success(t('notificationMessage'), { title: t('notificationTitle') })
  }

  return (
    <FormModal<CsvImportForm>
      open={isOpen}
      onClose={onClose}
      title={t('title')}
      confirmText={commonT('uploadButton')}
      confirmIcon="upload"
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      schema={validationSchema}
      defaultValues={{
        file: undefined,
        overwrite: false
      }}
    >
      <Box gap="small">
        <Text>{t('description')}</Text>
        <Text>{t('advice')}</Text>
        <FileInputField<CsvImportForm> name="file" accept="text/csv" inlineError />
      </Box>
      <CheckboxField<CsvImportForm> name="overwrite" label={t('overwrite')} />
    </FormModal>
  )
}
