import { Heading } from 'grommet'
import { useMatch } from 'react-router-dom'
import styled from 'styled-components'

import { Box, SidebarIconButton } from '@cutover/react-ui'
import { useSidebarNavigate } from './hooks'
import { useLeftSidebarSize } from './nav-context'
import { NavItem } from './nav-item'
import { FloatingToggleButton } from './shared'
import { useLanguage } from 'main/services/hooks'
import { PermissionsRecordType } from 'main/services/queries/types'
import { ConfigModel, CurrentUserModel } from 'main/data-access'

export const NavSettings = ({
  toggleSettings,
  compact,
  permissions,
  ...rest
}: {
  toggleSettings: (showSettings?: boolean) => void
  compact: boolean
  permissions: PermissionsRecordType
  // for passing styled-components css prop
  className?: string
}) => {
  const { t } = useLanguage('sidebarNav', { keyPrefix: 'settings' })
  const sidebarSize = useLeftSidebarSize()

  const isConnectSettingsEnabled = ConfigModel.useIsFeatureEnabled('connect_settings')
  const isCutoverParameterStoreEnabled = ConfigModel.useIsFeatureEnabled('cutover_parameter_store')
  const { cutover_employee } = CurrentUserModel.useGet()

  return (
    <Box {...rest} fill responsive={false} css="position: relative;" data-testid="nav-settings">
      <Box
        responsive={false}
        direction="row"
        gap="12px"
        margin={{ top: '8px' }}
        align="center"
        css={`
          height: 48px;
          min-height: 56px;
        `}
      >
        <Box pad={{ left: '12px' }} width={{ min: 'min-content' }}>
          <SidebarIconButton label={t('tooltip.back')} icon="arrow-back" onClick={() => toggleSettings()} />
        </Box>
        {/* TODO: is this kind of heading a one off or elsewhere? */}
        {!compact && (
          <Heading css="font-size: 20px; font-weight: 600;" level={2} color="nav-heading-color">
            Settings
          </Heading>
        )}
      </Box>
      {sidebarSize !== 'full' && sidebarSize !== 'hidden' && <FloatingToggleButton />}
      <NavSettingsContainer pad={{ horizontal: '12px', bottom: '12px' }}>
        {permissions.accounts && (
          <SettingsNavItem icon="workspace" path="/app/settings/workspaces" compact={compact} label={t('workspaces')} />
        )}
        {permissions.custom_fields && (
          <SettingsNavItem
            icon="custom-field"
            path="/app/settings/customfields"
            compact={compact}
            label={t('customFields')}
          />
        )}
        {permissions.custom_fields && (
          <SettingsNavItem
            icon="field-group"
            path="/app/settings/customfieldgroups"
            compact={compact}
            label={t('customFieldGroups')}
          />
        )}
        {permissions.dashboards && (
          <SettingsNavItem icon="layout" path="/app/settings/dashboards" compact={compact} label="Dashboard Layouts" />
        )}
        {permissions.integrations && (
          <SettingsNavItem
            icon="integrations"
            path="/app/settings/integration_connections"
            compact={compact}
            label={t('integrations')}
          />
        )}
        {permissions.connect_settings && isConnectSettingsEnabled && (
          <SettingsNavItem
            icon="dependencies"
            path="/app/settings/cutover-connect"
            compact={compact}
            label="Cutover Connect"
          />
        )}
        {permissions.data_sources && (
          <SettingsNavItem icon="server" path="/app/settings/data-sources" compact={compact} label={t('dataSources')} />
        )}
        {permissions.role_types && (
          <SettingsNavItem
            icon="user-roles"
            path="/app/access/role_types/list"
            compact={compact}
            label={t('roleTypes')}
          />
        )}
        {permissions.runbook_types && (
          <SettingsNavItem
            icon="runbook"
            path="/app/settings/runbook-types"
            compact={compact}
            label={t('runbookTypes')}
          />
        )}
        {permissions.task_types && (
          <SettingsNavItem icon="task-types" path="/app/settings/tasktypes" compact={compact} label={t('taskTypes')} />
        )}
        {(permissions.cutover_root_user || permissions.saml_configurations) && (
          <SettingsNavItem icon="lock" path="/app/settings/saml" compact={compact} label={t('samlConfigurations')} />
        )}
        {permissions.cutover_root_user && isCutoverParameterStoreEnabled && (
          <SettingsNavItem
            icon="key"
            path="/app/settings/system-parameters"
            compact={compact}
            label={t('systemParameters')}
          />
        )}
        {(permissions.instance_settings || cutover_employee) && (
          <SettingsNavItem
            icon="globe"
            path="/app/settings/instancesettings"
            compact={compact}
            label={t('instanceSettings')}
          />
        )}
      </NavSettingsContainer>
    </Box>
  )
}

const SettingsNavItem = ({
  path,
  label,
  icon,
  compact
}: {
  path: string
  label: string
  icon: any
  compact: boolean
}) => {
  const navigate = useSidebarNavigate()
  const isActive = !!useMatch(path)

  return <NavItem icon={icon} compact={compact} isActive={isActive} label={label} onClick={() => navigate(path)} />
}

export const NavSettingsContainer = styled(Box)``
